import React from 'react';

import StackedBars from '../../../StackedBars/StackedBars';
import Draft from '../../../../Draft/Draft';

import { parseRichText } from '../../../../../../../../helpers/richTextHelpers';

import styles from './MatrixStackedBars.module.css';

const MatrixStackedBars = props => {
  const {
    block,
    data,
    isPercentage,
    isFiltered,
    concepts = [],
    selectableAnswers,
    totalResultsCount,
    selectedResultsCount,
    filteringOnSelection,
    isSingleMatrix,
    isMergedGraph,
    showStackedAverageScores,
    onBarClick
  } = props;

  let dataGroups =
    data && data.length
      ? Object.keys(data[0]).filter(
          singleColumn =>
            singleColumn !== 'group' && !singleColumn.includes('nofilter')
        )
      : [];

  if (isFiltered) {
    dataGroups = dataGroups.reduce(
      (all, subgroup) => [...all, ...[subgroup, `${subgroup}_nofilter`]],
      []
    );
  }

  const groupNames = [
    ...concepts,
    ...(!(isSingleMatrix || isMergedGraph) ? concepts : [])
  ];

  const stackedBars = dataGroups.reduce((nestedDataArray, subG) => {
    const numbers = data.map((d, dIndex) => {
      if (d[subG]) {
        if (typeof d[subG] === 'string') {
          return {
            value: Math.round(Number(d[subG])),
            questionIndex: subG,
            answerIndex: dIndex
          };
        }
        return {
          value: Math.round(d[subG]),
          questionIndex: subG,
          answerIndex: dIndex
        };
      }
      return {
        value: 0,
        questionIndex: subG,
        answerIndex: dIndex
      };
    });

    let filterTitle = '';
    let dataGroupIndex = subG;

    if (isFiltered && isMergedGraph) {
      if (subG.includes('_nofilter')) {
        dataGroupIndex = subG.split('_').shift();
        filterTitle = 'Total sample';
      } else {
        filterTitle = `Selected`;
      }
    } else if (isFiltered) {
      if (subG.includes('_nofilter')) {
        dataGroupIndex = subG.split('_').shift();
        filterTitle = filteringOnSelection
          ? `Not selected (N=${totalResultsCount - selectedResultsCount})`
          : `Total sample (N=${totalResultsCount})`;
      } else {
        filterTitle = `Selected N=(${selectedResultsCount})`;
      }
    }

    let groupName = '';
    if (groupNames && groupNames.length && groupNames[dataGroupIndex]) {
      if (isMergedGraph && groupNames[dataGroupIndex].question) {
        groupName = groupNames[dataGroupIndex].question;
      } else if (groupNames[dataGroupIndex].answer) {
        groupName = groupNames[dataGroupIndex].answer;
      }
    }

    return [
      ...nestedDataArray,
      {
        title: parseRichText(groupName),
        data: numbers,
        index: subG,
        ...(isFiltered ? { filterTitle } : {})
      }
    ];
  }, []);

  if (isSingleMatrix || isMergedGraph) {
    return (
      <div className={styles.wrapper}>
        <StackedBars
          stackedBars={stackedBars}
          isPercentage={isPercentage}
          isFiltered={isFiltered}
          selectableAnswers={selectableAnswers}
          showStackedAverageScores={showStackedAverageScores}
          onBarClick={onBarClick}
        />
      </div>
    );
  }

  const totalIndexes = groupNames.reduce((indexes, _gN, i) => {
    if (isFiltered) {
      return [...indexes, ...[`${i}`, `${i}_nofilter`]];
    }
    return [...indexes, `${i}`];
  }, []);

  const indexToSplit = totalIndexes.length / 2 - 1;
  const xIndexes = totalIndexes.slice(0, indexToSplit + 1);
  const yIndexes = totalIndexes.slice(indexToSplit + 1);

  const xStackedBars = [...stackedBars].filter(bar =>
    xIndexes.includes(bar.index)
  );
  const yStackedBars = [...stackedBars].filter(bar =>
    yIndexes.includes(bar.index)
  );

  return (
    <div className={styles.wrapper}>
      <StackedBars
        stackedBars={xStackedBars}
        isPercentage={isPercentage}
        isFiltered={isFiltered}
        selectableAnswers={selectableAnswers}
        showStackedAverageScores={showStackedAverageScores}
        onBarClick={onBarClick}
      />
      <div className={styles.border} />
      <div className="block-content">
        <div className="block-text">
          {!isSingleMatrix ? (
            <div className="block-text-matrix-axis">
              Y-axis:{' '}
              <span className="block-text-matrix-axis-bold">
                {block.matrix.yLabel ? block.matrix.yLabel : ''}
              </span>
            </div>
          ) : null}
          <Draft block={{ ...block, question: block.matrix.yQuestion }} />
        </div>
      </div>
      <StackedBars
        stackedBars={yStackedBars}
        isPercentage={isPercentage}
        isFiltered={isFiltered}
        selectableAnswers={selectableAnswers}
        showStackedAverageScores={showStackedAverageScores}
        onBarClick={onBarClick}
      />
    </div>
  );
};

export default MatrixStackedBars;
