import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/react-hooks';

import { SURVEY_LANGUAGES } from '../../../SurveyBuilder/helpers/constants';

import useLazyQuery from '../../../../../hooks/useLazyQuery';
import useDebounce from '../../../../../../campaigns/components/DebounceInput/debounceHook';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';
import close from '../../../../../../assets/img/close-white.svg';
import {
  GET_MEDIA_UPLOAD_SAS,
  GET_SURVEY_COMMUNITIES,
  UPDATE_SURVEY_COMMUNITIES
} from '../../../../../../graphql/Survey';

import CommunityDropdown from '../Conversion/components/Dropdown/Dropdown';
import Dropdown from '../Consultancy/components/Dropdown/Dropdown';
import NumberInput from './components/NumberInput';
import SampleSize from './components/SampleSize';

import styles from './Welcome.module.css';
import resizeImageFileForUpload from '../../../../../../helpers/resizeImageFileForUpload';

const DropZoneText = ({ isDragActive, uploading }) => {
  if (uploading) {
    return (
      <div className={styles.dropContainer}>
        Uploading image, please wait...
      </div>
    );
  }

  if (isDragActive) {
    return (
      <div className={styles.dropContainer}>Drop the image file here...</div>
    );
  }

  return (
    <div className={styles.dropContainer}>
      <div className={styles.button}>Select a file</div>
      <div className={styles.dropDescription}>or drag and drop file here</div>
    </div>
  );
};

const Welcome = ({
  survey,
  onUpdate,
  onUpdateMultiple,
  setConfirmationPopup,
  surveyRefetch,
  api,
  isAdmin,
  defaultConstants
}) => {
  const generalCommunityName = 'General BUFFL community';
  let initialCommunities = [];
  if (survey && survey.generalCommunity) {
    initialCommunities = [
      {
        label: generalCommunityName,
        value: generalCommunityName
      }
    ];
  }
  if (survey && survey.communities && survey.communities.length) {
    initialCommunities = [
      ...initialCommunities,
      ...survey.communities.map(c => ({
        label: c.name,
        value: c.id
      }))
    ];
  }
  const [selectedCommunities, setSelectedCommunities] = useState(
    initialCommunities
  );
  const [surveyProjectName, setSurveyProjectName] = useState(
    survey.projectName || ''
  );
  const [surveyName, setSurveyName] = useState(survey.name || '');
  const [surveyNotes, setSurveyNotes] = useState(survey.notes || '');
  const [expanded, setExpanded] = useState(true);
  const [uploading, setUploading] = useState(false);

  const [averageDuration, setAverageDuration] = useState(
    survey.averageDuration
  );
  const [baseReward, setBaseReward] = useState(survey.baseReward);
  const debouncedAverageDurationValue = useDebounce(averageDuration, 300);
  const debouncedBaseRewardValue = useDebounce(baseReward, 300);

  const getMediaUploadSAS = useLazyQuery(GET_MEDIA_UPLOAD_SAS);
  const loadCommunitiesQuery = useLazyQuery(GET_SURVEY_COMMUNITIES, {
    fetchPolicy: 'no-cache'
  });
  const [updateSurveyCommunities] = useMutation(UPDATE_SURVEY_COMMUNITIES);

  const [selectedLanguage, setSelectedLanguage] = useState(
    (survey && survey.language) || null
  );

  useEffect(
    () => {
      setSurveyName(survey.name || '');
      setSurveyNotes(survey.notes || '');
    },
    [survey]
  );

  useEffect(
    () => {
      onUpdate('averageDuration', averageDuration);
    },
    [debouncedAverageDurationValue]
  );

  useEffect(
    () => {
      onUpdate('baseReward', baseReward);
    },
    [debouncedBaseRewardValue]
  );

  const searchCommunities = async (page, search) => {
    const variables = {
      start: 0,
      end: (page + 1) * 10,
      search,
      salt: Math.random().toString()
    };

    const searchResults = await loadCommunitiesQuery({
      variables
    });

    return searchResults &&
      searchResults.data &&
      searchResults.data.getSurveyCommunities &&
      searchResults.data.getSurveyCommunities.records &&
      searchResults.data.getSurveyCommunities.records.length
      ? [
          { label: generalCommunityName, value: generalCommunityName },
          ...searchResults.data.getSurveyCommunities.records.map(r => ({
            label: r.name,
            value: r.id
          }))
        ]
      : [];
  };

  const textareaAutoExpand = (field, includePadding) => {
    if (field) {
      const textAreaField = field;

      // Reset field height
      textAreaField.style.height = 'inherit';

      // Get the computed styles for the element
      const computed = window.getComputedStyle(field);

      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        (includePadding
          ? parseInt(computed.getPropertyValue('padding-top'), 10)
          : 0) +
        field.scrollHeight +
        (includePadding
          ? parseInt(computed.getPropertyValue('padding-bottom'), 10)
          : 0) +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      textAreaField.style.height = `${height}px`;
    }
  };

  const [questionTextarea, setQuestionTextarea] = useState(null);
  const [notesTextarea, setNotesTextarea] = useState(null);
  const [projectNameTextarea, setProjectNameTextarea] = useState(null);

  const onWindowResize = ev => {
    ev.preventDefault();
    if (questionTextarea) {
      textareaAutoExpand(questionTextarea);
    }

    if (notesTextarea) {
      textareaAutoExpand(notesTextarea, true);
    }

    if (projectNameTextarea) {
      textareaAutoExpand(projectNameTextarea);
    }
  };

  const onUpdateSurveyImage = async (filename, origFilename) => {
    await api('updateSurvey', {
      variables: {
        survey: survey.id,
        image: filename,
        imageFilename: origFilename
      }
    });
    await surveyRefetch();
    setUploading(false);
  };

  const onImageSelect = async acceptedFiles => {
    setUploading(true);
    const origFilename = acceptedFiles[0].name;

    const {
      data: { mediaUploadSAS: { uri: sasURI, filename } = {} } = {}
    } = await getMediaUploadSAS({
      variables: {
        fileName: origFilename
      }
    });

    if (!sasURI) {
      // FIXME: error
      return;
    }

    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status === 201) {
        onUpdateSurveyImage(filename, origFilename);
      }
    };

    xhr.open('PUT', sasURI, true);
    xhr.setRequestHeader('Content-Type', 'image/jpeg');
    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

    try {
      const imageToUpload = await resizeImageFileForUpload(acceptedFiles[0]);
      xhr.send(imageToUpload);
    } catch (err) {
      console.log(err);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    multiple: false,
    onDrop: onImageSelect
  });

  useEffect(
    () => {
      if (questionTextarea) {
        textareaAutoExpand(questionTextarea);
      }

      if (notesTextarea) {
        textareaAutoExpand(notesTextarea, true);
      }

      if (projectNameTextarea) {
        textareaAutoExpand(projectNameTextarea);
      }

      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    },
    [questionTextarea]
  );

  const parseDraftQuestion = q => {
    try {
      return JSON.parse(q)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      return q;
    }
  };

  const getSurveyWordCount = () => {
    const allQuestionsString = survey.questions.reduce(
      (questionString, currentQuestion) =>
        `${questionString}${
          currentQuestion.question
            ? ` ${parseDraftQuestion(currentQuestion.question)}`
            : ''
        }${
          currentQuestion.choices && currentQuestion.choices.length
            ? ` ${currentQuestion.choices.map(choice =>
                parseDraftQuestion(choice.answer)
              )}`
            : ''
        }`,
      ''
    );
    return allQuestionsString.trim().length &&
      allQuestionsString.match(/[\wÀ-Üà-øoù-ÿŒœ]+/g)
      ? allQuestionsString.match(/[\wÀ-Üà-øoù-ÿŒœ]+/g).length
      : 0;
  };

  const [surveyWordCount, setSurveyWordCount] = useState(0);

  useEffect(
    () => {
      setSurveyWordCount(getSurveyWordCount());
    },
    [survey.questions]
  );

  const onChangeSurveyLanguage = language => {
    const setLanguage = () => {
      if (language !== selectedLanguage) {
        setSelectedLanguage(language);
        return { language };
      }
      return {};
    };

    const surveySelection = {
      // AM record assigned to this survey - not AM record with which client is "viewing as"
      amSurveyRecord:
        survey && survey.recordDetails && survey.recordDetails.id
          ? survey.recordDetails.id
          : null,
      initialSurveys: survey.relatedSurveys || [],
      surveyId: survey.id
    };

    const confirmationPopup = {
      onClick: async (dontShowAnymoreChecked, relatedSurveys) => {
        // If translationOfOtherSurvey is false also remove relatedSurveys
        const relatedSurveysIds =
          relatedSurveys && relatedSurveys.length && dontShowAnymoreChecked
            ? relatedSurveys.map(selectedSurvey => selectedSurvey.value)
            : [];

        const languageObject = setLanguage();
        const propertiesToUpdate = {
          ...languageObject,
          translationOfOtherSurvey: dontShowAnymoreChecked,
          relatedSurveys: relatedSurveysIds
        };
        onUpdateMultiple(propertiesToUpdate);
      },
      onClose: () => setConfirmationPopup(null),
      onDontShowAnymoreChecked: () => {},
      title: 'Survey language',
      description:
        'This setting determines the language of the survey, so it is important to select the native language of (at least some of) your respondents. Once your survey is ready, you can duplicate it in the survey list and launch it in other languages. \n\nThis setting also affects the language of the wizard that guides you through a survey template, to help you prepare the survey directly in the respondent’s language.',
      checkboxLabel: 'Mark as a translation of other survey',
      defaultValue: survey.translationOfOtherSurvey || false,
      surveySelection
    };

    setConfirmationPopup(confirmationPopup);
  };

  const getSelectedCommunities = () => {
    let selectedValue = 'No communities selected';
    if (selectedCommunities && selectedCommunities.length) {
      if (selectedCommunities.length === 1) {
        selectedValue = selectedCommunities[0].label;
      } else {
        selectedValue = `${selectedCommunities.length} communities selected`;
      }
    }
    return selectedValue;
  };

  const mapSurveyLanguages = () => {
    const languageOptions = [
      SURVEY_LANGUAGES.DUTCH,
      SURVEY_LANGUAGES.FRENCH,
      SURVEY_LANGUAGES.GERMAN,
      SURVEY_LANGUAGES.ENGLISH
    ];

    return languageOptions.map(languageOption => ({
      label: `${languageOption} ${
        survey &&
        survey.translationOfOtherSurvey &&
        selectedLanguage === languageOption
          ? '(translation of other survey)'
          : ''
      }`,
      value: languageOption
    }));
  };

  return (
    <div
      className={styles.container}
      role="presentation"
      /*
      ref={elem => {
        block.reference = elem;
      }}
      */
    >
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Basic Settings</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div className={styles.detailsContainer}>
          {isAdmin && (
            <div className={styles.detailContainer}>
              <div className={styles.rowText}>
                Community<span className={styles.requiredAsterisk}>*</span>
              </div>
              <CommunityDropdown
                selectedValue={getSelectedCommunities()}
                onSelectCompleted={async (label, value) => {
                  const newCommunities = [
                    ...selectedCommunities.map(c => ({ ...c }))
                  ];
                  // Remove from state if exists else add it
                  if (
                    selectedCommunities.some(
                      community => community.value === value
                    )
                  ) {
                    newCommunities.splice(
                      selectedCommunities.findIndex(
                        community => community.value === value
                      ),
                      1
                    );
                  } else {
                    newCommunities.push({ label, value });
                  }

                  setSelectedCommunities(newCommunities);

                  if (value === generalCommunityName) {
                    // Update generalCommunity property if it was clicked
                    onUpdate('generalCommunity', !survey.generalCommunity);
                  } else {
                    // Update survey communities and do not include generalCommunity
                    const communitiesWithoutGeneral = newCommunities.reduce(
                      (communityIds, c) => {
                        if (c.value !== generalCommunityName) {
                          return [...communityIds, c.value];
                        }
                        return communityIds;
                      },
                      []
                    );
                    await updateSurveyCommunities({
                      variables: {
                        survey: survey.id,
                        communities: communitiesWithoutGeneral
                      }
                    });
                  }
                }}
                searchEnabled
                fetchOptions={searchCommunities}
                loading={false}
                findList={selectedCommunities.map(c => c.value)}
                addNew={false}
              />
            </div>
          )}
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              Internal survey name
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <textarea
              className={styles.surveyName}
              rows="1"
              ref={element => {
                if (element) {
                  setProjectNameTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => onUpdate('projectName', surveyProjectName)}
              onChange={e => {
                textareaAutoExpand(projectNameTextarea);
                setSurveyProjectName(e.target.value);
              }}
              maxLength="1000"
              value={surveyProjectName}
            />
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              Survey name for respondents
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <textarea
              className={styles.surveyName}
              rows="1"
              ref={element => {
                if (element) {
                  setQuestionTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => onUpdate('name', surveyName)}
              onChange={e => {
                textareaAutoExpand(questionTextarea);
                setSurveyName(e.target.value);
              }}
              maxLength="1000"
              value={surveyName}
            />
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              Survey language<span className={styles.requiredAsterisk}>*</span>
            </div>
            <Dropdown
              selectedValue={
                selectedLanguage
                  ? `${selectedLanguage} ${
                      survey && survey.translationOfOtherSurvey
                        ? '(translation of other survey)'
                        : ''
                    }`
                  : null
              }
              onSelectCompleted={value => {
                onChangeSurveyLanguage(value);
              }}
              searchEnabled={false}
              options={mapSurveyLanguages()}
              fetchOptions={() => mapSurveyLanguages()}
              customPlaceholder="Select option"
            />
          </div>
          <SampleSize
            survey={survey}
            setConfirmationPopup={setConfirmationPopup}
            surveyRefetch={surveyRefetch}
            api={api}
            defaultConstants={defaultConstants}
          />
          <NumberInput
            label="Average duration (in seconds)"
            required
            value={averageDuration}
            setValue={setAverageDuration}
            placeholder="60"
          />
          <NumberInput
            label="Base reward (in tokens)"
            required
            value={baseReward}
            setValue={setBaseReward}
            placeholder="5"
          />
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              Project notes (not visible for respondents)
            </div>
            <textarea
              className={
                !surveyNotes || surveyNotes === ''
                  ? styles.description
                  : `${styles.description} ${styles.notesField}`
              }
              rows="1"
              ref={element => {
                if (element) {
                  setNotesTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => onUpdate('notes', surveyNotes)}
              onChange={e => {
                textareaAutoExpand(notesTextarea, true);
                setSurveyNotes(e.target.value);
              }}
              maxLength="2000"
              value={surveyNotes}
            />
          </div>
          <div className={styles.wordCount}>
            Total survey word count: {surveyWordCount}
          </div>

          <div className={styles.detailContainer}>
            <div className={styles.dropContainerTitle}>
              Upload a cover picture
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            {survey.image ? (
              <div className={styles.imageContainer}>
                <img
                  className={styles.coverImage}
                  alt="Cover"
                  src={survey.image}
                />
                <div
                  className={styles.removeImageContainer}
                  role="presentation"
                  onClick={() => onUpdateSurveyImage(null, null)}
                >
                  <img alt="Close" src={close} />
                </div>
              </div>
            ) : (
              <div className={styles.dropZone}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <DropZoneText
                    isDragActive={isDragActive}
                    pdfCoupons={[]}
                    uploading={uploading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Welcome;
