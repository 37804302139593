import { parseRichText } from '../../../../../../../helpers/richTextHelpers';

const getPercentageValue = (value, total) => {
  let percentage = 0;
  if (value && total) {
    percentage = (value / total) * 100;
  }
  return percentage.toFixed(2);
};

export default (question, results, resultStats) => {
  let totalAnswers = 0;

  const detailedAnswers = question.answers.map((answer, index, arr) => {
    const isOther = question.allowOther && index === arr.length - 1;
    const answerResponse = resultStats.values.filter(
      result => result.x === index
    );
    if (answerResponse.length) {
      totalAnswers += answerResponse[0].y;
      return {
        x: answerResponse[0].x,
        label: parseRichText(question.answers[answerResponse[0].x].answer),
        y: answerResponse[0].y,
        image_filename: question.answers[answerResponse[0].x].image_filename,
        isOther
      };
    }
    return {
      x: index,
      label: parseRichText(answer.answer),
      y: 0,
      image_filename: answer.image_filename,
      isOther
    };
  });

  return detailedAnswers.map(answer => {
    const answerWithPercentage = answer;
    if (answerWithPercentage.dropOut === true) {
      answerWithPercentage.percentage = getPercentageValue(
        answer.y,
        results.length + question.dropOutResults.length
      );
      return answerWithPercentage;
    }
    if (
      question.type === 'Checkboxes' ||
      (question.type === 'Multiple Choice Question' &&
        question.selectAtMost &&
        question.selectAtMost > 1)
    ) {
      answerWithPercentage.percentage = getPercentageValue(
        answer.y,
        results.length
      );
    }
    if (
      (question.type === 'Multiple Choice Question' &&
        (!question.selectAtMost || question.selectAtMost === 1)) ||
      question.type === 'Matrix'
    ) {
      answerWithPercentage.percentage = getPercentageValue(
        answer.y,
        totalAnswers
      );
    }
    return answerWithPercentage;
  });
};
