import React, { useRef, useState, useEffect } from 'react';

import StackedBar from './components/StackedBar/StackedBar';

import styles from './StackedBars.module.css';

const StackedBars = props => {
  const {
    stackedBars,
    isPercentage,
    isFiltered,
    selectableAnswers,
    showStackedAverageScores,
    onBarClick,
    sortingOrderArray
  } = props;

  let colorsPallete =
    selectableAnswers && selectableAnswers.length > 4
      ? ['#B44C03', '#D38214', '#E7BB58', '#ADBA7F', '#717B41']
      : ['#B44C03', '#E7BB58', '#ADBA7F', '#717B41'];

  if (sortingOrderArray && sortingOrderArray.length) {
    colorsPallete = sortingOrderArray.map(sortingIndex =>
      colorsPallete[sortingIndex] ? colorsPallete[sortingIndex] : '#000'
    );
  }

  const [clickedStackedBar, setClickedStackedBar] = useState(null);

  const barsParent = useRef();
  const elementRefs = useRef([]);

  useEffect(
    () => {
      if (isFiltered) {
        if (
          (clickedStackedBar || clickedStackedBar === 0) &&
          elementRefs &&
          elementRefs.current &&
          elementRefs.current[clickedStackedBar]
        ) {
          elementRefs.current[clickedStackedBar].scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          });
        }
      } else {
        setClickedStackedBar(null);
      }
    },
    [isFiltered]
  );

  const calculateAverageScore = dataArray => {
    const numOptions = dataArray.length;

    const totalResponses = dataArray.reduce((acc, d) => acc + d.value, 0);

    const totalScore = dataArray
      .sort(a => a.answerIndex)
      .reduce((acc, count, index) => {
        const score = (index / (numOptions - 1)) * 10; // Calculate score out of 10
        return acc + score * count.value;
      }, 0);
    if (totalScore && totalResponses) {
      return Math.round((totalScore / totalResponses) * 100) / 100;
    }
    return 0;
  };

  const textWidthPercentage = 25;
  const barWidthPercentage = 75;

  return (
    <div ref={barsParent}>
      {stackedBars && stackedBars.length ? (
        stackedBars.map((stackedBar, stackedBarIndex) => (
          <div
            key={`stacked-bar-${stackedBarIndex.toString()}`}
            className={`${styles.stackedBarContainer} ${
              isFiltered && stackedBarIndex % 2
                ? styles.additionalMarginBottom
                : ''
            } ${
              isFiltered && stackedBarIndex % 2 === 0
                ? styles.noMarginBottom
                : ''
            }`}
          >
            <div
              style={{ width: `${textWidthPercentage}%` }}
              className={`${styles.left} ${
                isFiltered ? styles.leftIsFiltered : ''
              }`}
            >
              {isFiltered ? (
                <div className={styles.bold}>{stackedBar.filterTitle}</div>
              ) : null}
              {stackedBar.title}
            </div>
            <div
              style={{ width: `${barWidthPercentage}%` }}
              className={`${styles.right} ${
                isFiltered ? styles.rightIsFiltered : ''
              } ${
                !showStackedAverageScores ? styles.rightNoAverageScores : ''
              }`}
            >
              {stackedBar.data &&
              stackedBar.data.length &&
              stackedBar.data.reduce((count, d) => count + d.value, 0) > 0 ? (
                <div
                  ref={el => {
                    elementRefs.current[stackedBar.index] = el;
                  }}
                >
                  <StackedBar
                    parent={barsParent}
                    widthPercentageValue={barWidthPercentage / 100}
                    data={stackedBar.data}
                    colorsPallete={colorsPallete}
                    isPercentage={isPercentage}
                    noMargin={isFiltered && stackedBarIndex % 2 === 0}
                    onClick={(qIndexn, aIndex) => {
                      setClickedStackedBar(stackedBar.index);
                      onBarClick(qIndexn, aIndex);
                    }}
                  />
                  {showStackedAverageScores ? (
                    <div className={styles.average}>
                      Average rating:{' '}
                      <span className={styles.bold}>
                        {calculateAverageScore(stackedBar.data)} out of 10
                      </span>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="no-chart-data">No data</div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div style={{ width: `${barWidthPercentage}%`, marginLeft: 'auto' }}>
          <div className="no-chart-data">No data</div>
        </div>
      )}

      <div
        className={styles.legend}
        style={{ width: `${barWidthPercentage}%` }}
      >
        {selectableAnswers.map((option, i) => (
          <div key={option} className={styles.row}>
            <div
              className={styles.rectangle}
              style={{
                border: `1px solid #e9e7e7`,
                ...(colorsPallete && colorsPallete[i]
                  ? { backgroundColor: colorsPallete[i] }
                  : {})
              }}
            />
            <div>{option}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StackedBars;
