import { LOVERS_HATERS_GRAPH_TYPES } from './constants';
import calculateChartData from './calculateChartData';
import calculateExtremeUsersData from './calculateExtremeUsersData';
import getAllowedQuestions from './getAllowedQuestions';

export default (
  features,
  unFilteredFeatures,
  questions,
  reducedQuestions,
  questionTypes,
  activeLoversHaters,
  selectedExtremeLoversHatersGraphTypes,
  filteringOnSelection,
  getViewAllGraphStats,
  useUnfilteredResultStats
) => {
  // Single point
  if (
    features &&
    features.length &&
    reducedQuestions &&
    reducedQuestions.length &&
    selectedExtremeLoversHatersGraphTypes &&
    selectedExtremeLoversHatersGraphTypes.id ===
      LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS
  ) {
    return calculateChartData(
      features,
      unFilteredFeatures,
      useUnfilteredResultStats,
      questionTypes,
      reducedQuestions,
      filteringOnSelection
    );
  }

  // View all
  if (
    selectedExtremeLoversHatersGraphTypes &&
    selectedExtremeLoversHatersGraphTypes.id ===
      LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
  ) {
    // Go for each visible data point
    const questionsMapping = questions.reduce(
      (acc, q) => ({
        ...acc,
        [q.id]: q
      }),
      {}
    );

    let featureIteration = 0;

    const featuresToIterate = !useUnfilteredResultStats
      ? features
      : unFilteredFeatures;

    const stats = featuresToIterate.reduce((acc, feature) => {
      if (!feature.active) {
        return acc;
      }

      const viewAllActiveLoversHaters = {
        ...feature,
        filter: activeLoversHaters && activeLoversHaters.filter
      };

      // Here calculate extreme users data for this feature
      const extremeUsersResultIds = calculateExtremeUsersData(
        viewAllActiveLoversHaters,
        reducedQuestions,
        questionTypes
      );

      // Get result stats for this feature
      const questionsWithPopulatedStats = getViewAllGraphStats(
        feature,
        extremeUsersResultIds,
        questionsMapping,
        useUnfilteredResultStats
      );

      const processedReducedQuestions = Object.values(
        questionsWithPopulatedStats
      );

      const reducedQuestionsAfterRecalculation = getAllowedQuestions(
        processedReducedQuestions
      );

      const singleFeatureData = calculateChartData(
        featuresToIterate,
        featuresToIterate,
        useUnfilteredResultStats,
        questionTypes,
        reducedQuestionsAfterRecalculation,
        filteringOnSelection,
        undefined,
        true
      );

      if (singleFeatureData[featureIteration]) {
        const statsToReturn = [...acc, singleFeatureData[featureIteration]];
        featureIteration += 1;
        return statsToReturn;
      }
      return acc;
    }, []);

    return stats;
  }

  return [];
};
