import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { REMOVE_SCOPING_NEXT_STEP } from '../../../../graphql/Scoping';

import NextStepsPopup from './components/NextStepsPopup/NextStepsPopup';
import Draft from '../Blocks/Question/Draft/Draft';
import Popup from '../../Popup';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';

import styles from './Scoping.module.css';

const Scoping = props => {
  const {
    surveyId,
    scoping,
    nextStepPopup,
    setNextStepPopup,
    surveyRefetch
  } = props;
  const [removeScopingNextStep] = useMutation(REMOVE_SCOPING_NEXT_STEP);

  const [confirmationPopup, setConfirmationPopup] = useState(null);
  const [internalData, setInternalData] = useState({});

  const onEditClick = nextStep => {
    setInternalData(nextStep);
    setNextStepPopup({
      nextStepId: nextStep._id
    });
  };

  const onDeleteClick = async nextStepId => {
    await removeScopingNextStep({
      variables: {
        id: nextStepId,
        survey: surveyId.toString()
      }
    });

    surveyRefetch();
  };

  const getRichTextString = blockQuestion => {
    let question;
    try {
      question = JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      question = blockQuestion;
    }
    return question;
  };

  return (
    <>
      {nextStepPopup ? (
        <NextStepsPopup
          internalData={internalData}
          setInternalData={setInternalData}
          surveyId={surveyId}
          nextStepPopup={nextStepPopup}
          setNextStepPopup={setNextStepPopup}
          getRichTextString={getRichTextString}
          surveyRefetch={surveyRefetch}
        />
      ) : null}

      <div className={styles.container}>
        <div className={styles.title}>Research Scoping</div>
        <div className={styles.scopingFields}>
          <div className={styles.scopingField}>
            <div className={styles.scopingFieldTitle}>
              What do you want to learn or decide based on this survey?{' '}
              <span role="img" aria-label="Compass">
                🧭
              </span>
            </div>
            <div>{scoping.assumptionToValidate}</div>
          </div>
          <div className={styles.scopingField}>
            <div className={styles.scopingFieldTitle}>
              Is there a focus on a specific target audience? Why?{' '}
              <span role="img" aria-label="Two people">
                👥
              </span>
            </div>
            <div>{scoping.targetAudienceDescription}</div>
          </div>
        </div>
      </div>

      {scoping && scoping.nextSteps && scoping.nextSteps.length ? (
        <div className={styles.container}>
          <div className={styles.title}>Next steps</div>
          {scoping.nextSteps.map(nextStep => (
            <div className={styles.scopingFields}>
              <Draft
                block={{ question: nextStep.description, type: 'Next step' }}
              />
              <div className={styles.showActionDropdownButton}>
                ...
                <div className={styles.actionDropdownContainer}>
                  <div
                    className={styles.actionDropdownContainerOption}
                    role="presentation"
                    onClick={e => {
                      e.stopPropagation();
                      onEditClick(nextStep);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className={styles.actionDropdownContainerOption}
                    role="presentation"
                    onClick={e => {
                      e.stopPropagation();
                      setConfirmationPopup({
                        actionLabel:
                          'Are you sure you want to delete this next step?',
                        confirmLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        onConfirm: async () => {
                          await onDeleteClick(nextStep._id);
                          setConfirmationPopup(null);
                        }
                      });
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {confirmationPopup ? (
        <Popup
          component={
            <ConfirmationDialog
              label={confirmationPopup.actionLabel}
              confirmLabel={confirmationPopup.confirmLabel}
              cancelLabel={confirmationPopup.cancelLabel}
              onConfirm={() => {
                confirmationPopup.onConfirm();
              }}
              onCancel={() => {
                setConfirmationPopup(null);
              }}
            />
          }
          onClose={() => {
            setConfirmationPopup(null);
          }}
        />
      ) : null}
    </>
  );
};

export default Scoping;
