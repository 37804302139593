import React, { useState, useEffect } from 'react';
import api from '../../../../../../api';
import styles from './AnswerProbablityBars.module.css';
import AnswerThumbnail from '../../../AnswerThumbnail/AnswerThumbnail';

import { parseRichText } from '../../../../../helpers/richTextHelpers';

const AnswerProbablityBars = props => {
  const { block, campaign, isAllowedToExecuteRequests, viewToken } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [analyticAnswers, setAnalyticAnswers] = useState([]);

  const getAnalyticsData = async () => {
    if (isAllowedToExecuteRequests) {
      const input = createAnalyticsServiceInputFromBlockResults(block);

      setIsFetching(true);
      const campaignsList = await api().getAnalyticsData(
        campaign,
        input,
        viewToken || null
      );

      if (campaignsList.status === 200) {
        setAnalyticAnswers(campaignsList.json.data);
      } else {
        setShowError(true);
      }

      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAnalyticsData();
  }, []);

  useEffect(
    () => {
      getAnalyticsData();
    },
    [block.resultStats]
  );

  let totalAnswers = 0;
  const detailedAnswers = block.answers.map((answer, index) => {
    const answerResponse = block.resultStats.values.filter(
      result => result.x === index
    );
    if (answerResponse.length) {
      totalAnswers += answerResponse[0].y;
      return {
        x: answerResponse[0].x,
        question: parseRichText(block.answers[answerResponse[0].x].answer),
        y: answerResponse[0].y,
        image_filename: block.answers[answerResponse[0].x].image_filename
      };
    }
    return {
      x: index,
      question: parseRichText(answer.answer),
      y: 0,
      image_filename: answer.image_filename
    };
  });

  const rawAnswers = detailedAnswers.map(answer => {
    const answerWithPercentage = answer;
    if (block.type === 'Checkboxes') {
      answerWithPercentage.percentage =
        block && block.results && block.results.length
          ? ((answer.y / block.results.length) * 100).toFixed(2)
          : null;
    }
    if (block.type === 'Multiple Choice Question') {
      answerWithPercentage.percentage = (
        (answer.y / totalAnswers) *
        100
      ).toFixed(2);
    }
    return answerWithPercentage;
  });

  const parseAnalyticsToAnswers = analyticsOutput => {
    if (block && block.answers && block.answers.length) {
      const lower = (singleAnalytic, percentage) =>
        parseFloat(
          ((percentage / singleAnalytic.fit) * singleAnalytic.lower).toFixed(2)
        );
      const upper = (singleAnalytic, percentage) =>
        parseFloat(
          ((percentage / singleAnalytic.fit) * singleAnalytic.upper).toFixed(2)
        );

      return block.answers.map((singleAnswer, index) => {
        const singleAnswerAnalytics = analyticsOutput.filter(
          analyticOutput => parseInt(analyticOutput.alternative, 10) === index
        );

        if (singleAnswerAnalytics && singleAnswerAnalytics.length) {
          return {
            question: parseRichText(singleAnswer.answer),
            image_filename: singleAnswer.image_filename,
            fit: rawAnswers[index].percentage,
            lower: lower(
              singleAnswerAnalytics[0],
              rawAnswers[index].percentage
            ),
            upper: upper(
              singleAnswerAnalytics[0],
              rawAnswers[index].percentage
            ),
            count:
              rawAnswers[index] && rawAnswers[index].y ? rawAnswers[index].y : 0
          };
        }

        return {
          question: parseRichText(singleAnswer.answer),
          image_filename: singleAnswer.image_filename,
          fit: 0,
          lower: 0,
          upper: 0,
          count:
            rawAnswers[index] && rawAnswers[index].y ? rawAnswers[index].y : 0
        };
      });
    }
    return null;
  };

  const createAnalyticsServiceInputFromBlockResults = inputBlock => {
    const answersToAnalyse = [];
    const values = inputBlock.results.map(result => {
      if (result.answer || result.answer === 0) {
        if (answersToAnalyse.indexOf(result.answer) === -1) {
          answersToAnalyse.push(result.answer);
        }

        return [`${result.answer}`];
      }
      if (result.answers || result.answers === 0) {
        result.answers.forEach(answer => {
          if (answersToAnalyse.indexOf(answer) === -1) {
            answersToAnalyse.push(parseInt(answer, 10));
          }
        });

        return [`${result.answers}`];
      }
      return null;
    });

    const levels = answersToAnalyse
      .sort((a, b) => a - b)
      .map(answerIndex => `${answerIndex}`);
    return {
      Q004: {
        class: ['checkbox'],
        values,
        levels,
        label: [inputBlock.question],
        label0: {}
      }
    };
  };

  const answers = parseAnalyticsToAnswers(analyticAnswers);

  if (showError) {
    return (
      <div className="no-chart-data">
        Error occurred. Please contact administrator for more details.
      </div>
    );
  }

  return (
    <div className="answers-container">
      {!isFetching ? (
        <div className="results-answers-distribution-container">
          {answers && answers.length ? (
            [
              answers.map((answer, index) => (
                <div
                  className="results-answers-distribution"
                  key={`results-answers-distribution-${index.toString()}`}
                >
                  <AnswerThumbnail
                    answer={answer}
                    answerIndex={index}
                    block={block}
                  />
                  <div
                    className={
                      answer && answer.image_filename
                        ? 'results-answers-answer cover-image-padding'
                        : 'results-answers-answer'
                    }
                  >
                    {answer.question}
                  </div>
                  <div className="results-answers-answer-details">
                    <div
                      role="presentation"
                      className={`${
                        styles.resultsAnswerBarContainer
                      } results-answers-bar-container`}
                    >
                      {answer.count ? (
                        <div
                          className={styles.resultsAnswerCentralValueBar}
                          style={{
                            width: '2%',
                            left: `${
                              answer.lower && answer.upper
                                ? answer.lower +
                                  ((answer.upper - answer.lower) / 2 - 1)
                                : 0
                            }%`
                          }}
                        />
                      ) : null}
                      <div
                        className={styles.resultsAnswerRangeBar}
                        style={{
                          width: `${answer.upper - answer.lower}%`,
                          left: `${answer.lower}%`
                        }}
                      />
                    </div>
                    <div className="results-answers-percentage">
                      {answer.fit}%
                      <div className={styles.resultsAnswerPercentageRange}>
                        {answer.lower}% - {answer.upper}%
                      </div>
                    </div>
                    <div className="results-answers-answer-count">
                      {answer.count}
                      &nbsp;answers
                    </div>
                  </div>
                </div>
              ))
            ]
          ) : (
            <div className="no-chart-data">No data</div>
          )}
        </div>
      ) : (
        <div className={styles.loadingAnimation}>
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
};

export default AnswerProbablityBars;
