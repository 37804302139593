const extremeUsersFilterLabels = [
  'xLovers',
  'yLovers',
  'xLoversyLovers',
  'xHaters',
  'yHaters',
  'xHatersyHaters'
];

const isExtremeFilter = (filter, filterName) =>
  filter.value &&
  extremeUsersFilterLabels.includes(filter.value) &&
  filter.filterName === filterName;

export const getDifferentFilterNames = filters =>
  filters.reduce(
    (filterCountsMapping, filter) => ({
      ...filterCountsMapping,
      [filter.filterName]: (filterCountsMapping[filter.filterName] || 0) + 1
    }),
    {}
  );

export const getNestedFiltersFromActiveFilters = (filters, fNames = null) => {
  let filterNames = fNames;
  if (!filterNames) {
    filterNames = getDifferentFilterNames(filters);
  }

  const nestedFilters = Object.entries(filterNames).reduce(
    (gFilters, [filterName, count]) => {
      if (count >= 2) {
        return [
          ...gFilters,
          {
            filterName,
            relation: 'or' // Relation inside nested filters is always OR
          }
        ];
      }
      return gFilters;
    },
    []
  );

  return nestedFilters;
};

export const handleFilterBehaviour = (state, newActiveFilters, action) => {
  let newGlobalRelation = null;

  let filters = newActiveFilters;

  const previousActiveFilters =
    state &&
    state.portal &&
    state.portal.campaignResults &&
    state.portal.campaignResults.filter &&
    state.portal.campaignResults.filter.activeFilters
      ? state.portal.campaignResults.filter.activeFilters
      : [];

  const { filterName: actionFilterName, value } = action || {};

  // Remove previous lovers/haters (extreme) filter when selecting different one for same graph
  if (
    actionFilterName &&
    value &&
    extremeUsersFilterLabels.includes(value) &&
    filters.some(f => isExtremeFilter(f, actionFilterName)) &&
    previousActiveFilters.some(pF => isExtremeFilter(pF, actionFilterName)) &&
    !action.updateExistingFilter
  ) {
    const oldValue = previousActiveFilters.find(
      f => f.filterName === actionFilterName
    );

    // If a filter is an extreme filter and its value matches the old value, exclude it
    filters = filters.reduce((acc, f) => {
      if (
        !(isExtremeFilter(f, actionFilterName) && f.value === oldValue.value)
      ) {
        return [...acc, f];
      }
      return acc;
    }, []);
  }

  const filterNames = getDifferentFilterNames(filters);

  const newNestedFilters = getNestedFiltersFromActiveFilters(
    filters,
    filterNames
  );

  // Change filter relation when necessary
  const filterNamesCount = Object.keys(filterNames).length;
  const previousFilterNames = getDifferentFilterNames(previousActiveFilters);
  const previousFilterNamesCount = Object.keys(previousFilterNames).length;
  const previousNestedFilters =
    state &&
    state.portal &&
    state.portal.campaignResults &&
    state.portal.campaignResults.filter &&
    state.portal.campaignResults.filter.nestedFilters
      ? state.portal.campaignResults.filter.nestedFilters
      : [];

  // Add two filters of same filterName (same question or both age/gender filters) -> OR relation
  if (
    newNestedFilters.length === 1 &&
    !previousNestedFilters.some(
      pF => pF.filterName === newNestedFilters[0].filterName
    ) &&
    filterNamesCount === 1
  ) {
    newGlobalRelation = 'or';
  }

  // Two+ filters of same filterName + another filter with different filterName that is removed -> OR relation (only nested filter remains)
  if (
    newNestedFilters.length === 1 &&
    filterNamesCount === 1 &&
    previousFilterNamesCount === 2
  ) {
    newGlobalRelation = 'or';
  }

  // Two filters of different filterName -> AND relation
  if (previousFilterNamesCount === 1 && filterNamesCount === 2) {
    newGlobalRelation = 'and';
  }

  // Two filters with same filterName have OR relation
  // One of them is removed -> AND relation
  if (
    previousNestedFilters.length === 1 &&
    newNestedFilters.length === 0 &&
    previousFilterNamesCount === 1 &&
    filterNamesCount === 1
  ) {
    newGlobalRelation = 'and';
  }

  return {
    activeFilters: filters,
    nestedFilters: newNestedFilters,
    newGlobalRelation
  };
};

export const removeByFilterName = (state, filterType, filterName) => {
  let newState = [];
  if (
    filterType &&
    filterName &&
    state &&
    state.portal &&
    state.portal.campaignResults &&
    state.portal.campaignResults.filter &&
    state.portal.campaignResults.filter[filterType]
  ) {
    newState = state.portal.campaignResults.filter[filterType].reduce(
      (acc, curr) => {
        if (curr.filterName !== filterName) {
          return [...acc, curr];
        }
        return acc;
      },
      []
    );
  }

  return newState;
};

export const handleRemoveNestedFilter = (state, filterName) => {
  let newGlobalRelation = null;

  const newActiveFilters = removeByFilterName(
    state,
    'activeFilters',
    filterName
  );
  const newNestedFilters = removeByFilterName(
    state,
    'nestedFilters',
    filterName
  );

  const previousNestedFilters =
    state &&
    state.portal &&
    state.portal.campaignResults &&
    state.portal.campaignResults.filter &&
    state.portal.campaignResults.filter.nestedFilters
      ? state.portal.campaignResults.filter.nestedFilters
      : [];

  const filterNames = getDifferentFilterNames(newActiveFilters);
  const filterNamesCount = Object.keys(filterNames).length;

  // One nested filter remains and no other filters of different filterName -> OR relation
  if (
    newNestedFilters.length === 1 &&
    previousNestedFilters.length === 2 &&
    filterNamesCount === 1
  ) {
    newGlobalRelation = 'or';
  }

  return {
    activeFilters: newActiveFilters,
    nestedFilters: newNestedFilters,
    newGlobalRelation
  };
};
