import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import {
  UPDATE_OPPORTUNITY_GRAPH,
  REMOVE_OPPORTUNITY_GRAPH
} from '../../../../../../../graphql/Survey';

import Bar from './components/Bar/Bar';
import FeaturesList from '../FeaturesList/FeaturesList';
import Legend from './components/Legend/Legend';
import GraphTitle from '../GraphTitle/GraphTitle';
import GraphInfoTabs from '../GraphInfoTabs/GraphInfoTabs';
import Disclaimer from '../Disclaimer/Disclaimer';
import GraphActionsToolbar from '../Toolbars/GraphActionsToolbar';
import LoversHaters from '../LoversHaters/LoversHaters';
import Branding from '../Branding/Branding';
import Popup from '../../../../../Popup';
import EditBackgroundPopup from '../EditBackgroundPopup/EditBackgroundPopup';

import calculateExtremeUsersData from '../../helpers/calculateExtremeUsersData';
import generateRandomColor from '../../helpers/generateRandomColor';
import setLoversHatersFilterEvent from '../../helpers/setLoversHatersFilterEvent';
import updateGraphFeatures, {
  updateGraphFilteredAndUnfilteredFeatures
} from '../../helpers/updateGraphFeatures';
import getAllowedQuestions from '../../helpers/getAllowedQuestions';
import onTooltipAdd from '../../helpers/charts/onTooltipAdd';
import onTooltipRemove from '../../helpers/charts/onTooltipRemove';
import onDataPointClick from '../../helpers/charts/onDataPointClick';
import {
  getDistributorFeatures,
  transformDistributorUnfilteredData
} from '../../helpers/handleDistributorData';

import {
  LOVERS_HATERS_FILTER_LABELS,
  LOVERS_HATERS_GRAPH_TYPES,
  LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION,
  templateConfigurations,
  templateTypes
} from '../../helpers/constants';
import createUnfilteredFeatures from '../../helpers/createUnfilteredFeatures';

import styles from '../MergedGraph/MergedGraph.module.css';
import getChartData from '../../helpers/getChartData';

const graphInfoTabs = {
  LEGEND: 'Select data points',
  LOVERS_HATERS: 'Early adopters',
  DECISION: 'Decision',
  DISCLAIMER: 'Help'
};

const questionTypes = {
  xQuestion:
    templateConfigurations[templateTypes.OPPORTUNITY_GRAPH].xQuestionName,
  yQuestion:
    templateConfigurations[templateTypes.OPPORTUNITY_GRAPH].yQuestionName
};

const OpportunityGraph = props => {
  const {
    graph,
    questions,
    distributorGroups,
    surveyId,
    setEditGraphsPopup,
    opportunityGraphIndex,
    isFiltered,
    activeFilters,
    setDisplayContactInnovationExpertPopup,
    onToggleFilter,
    isAllowedToExecuteRequests,
    defaultDataPointColors,
    viewToken,
    setShowAddInsightsPopup,
    triggerUpdate,
    isHidden,
    filteringOnSelection,
    setFilteringOnSelection,
    presentationModeEnabled,
    setPresentationModeEnabled,
    getViewAllGraphStats,
    setActiveInsightId
  } = props;

  const [updateOpportunityGraph] = useMutation(UPDATE_OPPORTUNITY_GRAPH);

  const [activegraphInfoTab, setActiveGraphInfoTab] = useState(
    graphInfoTabs.LEGEND
  );
  const [activeLoversHaters, setActiveLoversHaters] = useState();
  const [
    selectedExtremeUsersFeature,
    setSelectedExtremeUsersFeature
  ] = useState();
  const [
    selectedExtremeUsersFilter,
    setSelectedExtremeUsersFilter
  ] = useState();
  const [
    selectedExtremeLoversHatersGraphTypes,
    setSelectedExtremeLoversHatersGraphTypes
  ] = useState({
    id: LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS,
    description:
      LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION[
        LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS
      ]
  });
  const [dataPointColors, setDataPointColors] = useState(
    defaultDataPointColors
  );
  const [themeColors, setThemeColors] = useState([
    {
      name: 'COLOR_1',
      colorCode: '#FEB3B5'
    },
    {
      name: 'COLOR_2',
      colorCode: '#BCDBBD'
    },
    {
      name: 'COLOR_3',
      colorCode: '#FFEBC8'
    }
  ]);
  const [editBackgroundPopup, setEditBackgroundPopup] = useState(false);
  const [features, setFeatures] = useState([]);
  const [unFilteredFeatures, setUnFilteredFeatures] = useState([]);
  const [dataTooltips, setDataTooltips] = useState([]);
  const [prevInsightState, setPrevInsightState] = useState(null);

  const barParent = useRef();

  const [
    removeOpportunityGraph,
    { loading: removeOpportunityGraphLoading }
  ] = useMutation(REMOVE_OPPORTUNITY_GRAPH);

  const removeGraphActiveFilter = () => {
    if (activeFilters && activeFilters.length) {
      if (graph && graph.name) {
        const newFilters = activeFilters.find(f => f.filterName === graph.name);
        if (newFilters) {
          onToggleFilter(
            newFilters.filterName,
            newFilters.valuePath,
            newFilters.value,
            newFilters.resultsPath
          );
        }
        setActiveLoversHaters({
          ...activeLoversHaters,
          filter: null
        });
        // setUnFilteredFeatures([]);
      }
    }
  };

  const onRemoveOpportunityGraphClick = async () => {
    if (isAllowedToExecuteRequests && !removeOpportunityGraphLoading) {
      await removeOpportunityGraph({
        variables: {
          survey: surveyId,
          id: graph.id,
          ...(viewToken ? { viewToken } : {})
        }
      });

      removeGraphActiveFilter();
    }
  };

  const getExtremeUsersData = (
    activeLoversHatersState,
    updateExistingFilter
  ) => {
    const internalActiveLoversHaters =
      activeLoversHatersState || activeLoversHaters;

    if (internalActiveLoversHaters) {
      const extremeUsersResultIds = calculateExtremeUsersData(
        internalActiveLoversHaters,
        reducedQuestions,
        questionTypes
      );

      onToggleFilter(
        graph.name,
        null,
        internalActiveLoversHaters.filter,
        null,
        result => {
          if (
            result &&
            result.id &&
            extremeUsersResultIds.indexOf(result.id) > -1
          ) {
            return true;
          }
          return false;
        },
        `${internalActiveLoversHaters.id}`,
        updateExistingFilter
      );

      setLoversHatersFilterEvent(
        internalActiveLoversHaters.filter,
        'PROBLEM_VALIDATION'
      );
    }
  };

  const reducedQuestions = useMemo(() => getAllowedQuestions(questions), [
    questions
  ]);

  const data = useMemo(
    () =>
      getChartData(
        features,
        unFilteredFeatures,
        questions,
        reducedQuestions,
        questionTypes,
        activeLoversHaters,
        selectedExtremeLoversHatersGraphTypes,
        filteringOnSelection,
        getViewAllGraphStats,
        false
      ),
    [
      questions,
      JSON.stringify(features),
      filteringOnSelection,
      activeFilters.length,
      activeLoversHaters,
      selectedExtremeUsersFeature,
      selectedExtremeUsersFilter
    ]
  );
  const unFilteredData = useMemo(
    () =>
      getChartData(
        features,
        unFilteredFeatures,
        questions,
        reducedQuestions,
        questionTypes,
        activeLoversHaters,
        selectedExtremeLoversHatersGraphTypes,
        filteringOnSelection,
        getViewAllGraphStats,
        true
      ),
    [
      JSON.stringify(unFilteredFeatures),
      filteringOnSelection,
      activeFilters.length,
      activeLoversHaters,
      selectedExtremeUsersFeature,
      selectedExtremeUsersFilter
    ]
  );

  const activeLoversHatersFeatures = useMemo(
    () =>
      getDistributorFeatures(
        activeLoversHaters,
        distributorGroups,
        questionTypes,
        features
      ),
    [activeLoversHaters]
  );

  useEffect(
    () => {
      if (activeLoversHaters && activeLoversHaters.filter) {
        getExtremeUsersData();
      }
    },
    [graph.features.length]
  );

  useEffect(
    () => {
      if (
        activeFilters &&
        activeFilters.length &&
        activeFilters.some(aF => aF.filterName === graph.name)
      ) {
        // Check if selected feature / data point is part of this graph
        const activeGraphFilter = activeFilters.find(
          aF => aF.filterName === graph.name
        );
        if (
          activeGraphFilter &&
          activeGraphFilter.additionalAnswerLabel &&
          graph &&
          graph.features &&
          graph.features.length
        ) {
          const filteredGraphFeature = graph.features.find(
            f => f.id === activeGraphFilter.additionalAnswerLabel
          );

          if (filteredGraphFeature) {
            setSelectedExtremeLoversHatersGraphTypes({
              id: LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS,
              description:
                LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION[
                  LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS
                ]
            });

            const filteredGraphFeatureIndex = graph.features.findIndex(
              f => f.id === activeGraphFilter.additionalAnswerLabel
            );
            const fullFilterData = {
              ...filteredGraphFeature,
              color: dataPointColors[filteredGraphFeatureIndex],
              featureIndex: filteredGraphFeatureIndex
            };
            setSelectedExtremeUsersFeature(fullFilterData);

            setSelectedExtremeUsersFilter({
              id: activeGraphFilter.value,
              description: LOVERS_HATERS_FILTER_LABELS[activeGraphFilter.value]
            });

            setActiveLoversHaters({
              ...fullFilterData,
              filter: activeGraphFilter.value
            });
          }
        }
      }

      // If filters changed, and not view all but there is no selected data point
      if (
        selectedExtremeLoversHatersGraphTypes &&
        selectedExtremeLoversHatersGraphTypes.id ===
          LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS &&
        (!activeFilters ||
          !activeFilters.length ||
          !activeFilters.some(aF => aF.filterName === graph.name))
      ) {
        setSelectedExtremeUsersFeature(null);
        setSelectedExtremeUsersFilter(null);
        setActiveLoversHaters(null);
      }
    },
    [activeFilters]
  );

  useEffect(
    () => {
      // Replace default data point color with custom defined
      if (features) {
        let dirty = false;
        const newDataPointColors = [...dataPointColors];
        features.forEach((fG, i) => {
          if (fG.colorCode) {
            dirty = true;
            newDataPointColors[i] = fG.colorCode;
          }
        });

        if (dirty) {
          setDataPointColors(newDataPointColors);
        }
      }

      if (data && data.length && data.length > dataPointColors.length) {
        const replenishedColors = [...dataPointColors];
        const dataLength = data.length;
        const defaultDataPointColorsLength = dataPointColors.length;
        for (let i = 0; i < dataLength - defaultDataPointColorsLength; i += 1) {
          replenishedColors.push(generateRandomColor());
        }
        setDataPointColors(replenishedColors);
      }
    },
    [data]
  );

  useEffect(
    () => {
      if (graph && graph.customThemeColors && graph.customThemeColors.length) {
        setThemeColors(graph.customThemeColors);
      }
      if (
        graph &&
        graph.features &&
        graph.features.length &&
        !features.length
      ) {
        setFeatures(graph.features);
      }

      if (
        graph &&
        graph.activeLoversHaters &&
        graph.activeLoversHaters.selectedExtremeLoversHatersGraphTypes ===
          LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS &&
        graph.activeLoversHaters.selectedExtremeUsersFilter
      ) {
        setSelectedExtremeLoversHatersGraphTypes({
          id: LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS,
          description:
            LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION[
              LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
            ]
        });
        setSelectedExtremeUsersFilter({
          id: graph.activeLoversHaters.selectedExtremeUsersFilter,
          description:
            LOVERS_HATERS_FILTER_LABELS[
              graph.activeLoversHaters.selectedExtremeUsersFilter
            ]
        });
        setActiveLoversHaters({
          filter: graph.activeLoversHaters.selectedExtremeUsersFilter
        });
        configureViewAll(true);
      }

      if (
        selectedExtremeLoversHatersGraphTypes &&
        selectedExtremeLoversHatersGraphTypes.id ===
          LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS &&
        graph &&
        !graph.activeLoversHaters
      ) {
        setSelectedExtremeUsersFeature(null);
        setSelectedExtremeUsersFilter(null);
        setActiveLoversHaters(null);
        setSelectedExtremeLoversHatersGraphTypes({
          id: LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS,
          description:
            LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION[
              LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS
            ]
        });
      }
    },
    [graph]
  );

  useEffect(
    () => {
      if (triggerUpdate && graph) {
        if (triggerUpdate.deriveFrom === 'prevState') {
          setFeatures(prevInsightState.features.map(f => ({ ...f })));
          setUnFilteredFeatures(
            prevInsightState.unFilteredFeatures.map(uF => ({ ...uF }))
          );
        } else {
          // Set prevState to display when unselecting insight
          setPrevInsightState({
            features: features.map(f => ({ ...f })),
            unFilteredFeatures: unFilteredFeatures.map(uF => ({ ...uF }))
          });

          if (graph.features && graph.features.length) {
            setFeatures(graph.features);
          }
          if (
            isFiltered &&
            graph.unFilteredFeatures &&
            graph.unFilteredFeatures.length
          ) {
            // Derive other info from features but use unfiltered Ids
            setUnFilteredFeatures(
              graph.unFilteredFeatures.map((uF, i) => ({
                ...(graph.features && graph.features.length && graph.features[i]
                  ? graph.features[i]
                  : {}),
                ...uF
              }))
            );
          }
        }
      }
    },
    [triggerUpdate]
  );

  const activeStateHasChanged = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return true;

    return arr1.reduce((mismatchFound, item1, index) => {
      if (mismatchFound) return true;

      const item2 = arr2[index];
      return item1.active !== item2.active;
    }, false);
  };

  useEffect(
    () => {
      if (features.length && isFiltered && !unFilteredFeatures.length) {
        // Set unfiltered features when activating filter
        let unFilteredFeaturesToCreate = createUnfilteredFeatures(
          graph,
          graph.unFilteredFeatures
        );

        if (
          unFilteredFeaturesToCreate &&
          unFilteredFeaturesToCreate.length &&
          (!unFilteredFeaturesToCreate[0].xQuestion &&
            !unFilteredFeaturesToCreate[0].importanceQuestion)
        ) {
          unFilteredFeaturesToCreate = unFilteredFeaturesToCreate.map(
            (uF, i) => ({
              ...(graph.features && graph.features.length && graph.features[i]
                ? graph.features[i]
                : {}),
              ...uF
            })
          );
        }

        setUnFilteredFeatures(unFilteredFeaturesToCreate);
      } else if (
        unFilteredFeatures.length &&
        !isFiltered &&
        !(
          selectedExtremeLoversHatersGraphTypes &&
          selectedExtremeLoversHatersGraphTypes.id ===
            LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
        )
      ) {
        // setUnFilteredFeatures([]);
      }
    },
    [isFiltered, features]
  );

  const applySurveyFilter = (activeLoversHatersState, updateExistingFilter) => {
    if (activeLoversHatersState && activeLoversHatersState.filter) {
      getExtremeUsersData(activeLoversHatersState, updateExistingFilter);
    }
  };

  const configureViewAll = isInsightApplied => {
    if (isInsightApplied) {
      setUnFilteredFeatures(
        graph.unFilteredFeatures.map((uF, i) => ({
          ...(graph.features && graph.features.length && graph.features[i]
            ? graph.features[i]
            : {}),
          ...uF
        }))
      );
    }

    if (!isInsightApplied) {
      let unfilteredFeaturesToUpdate;
      if (
        !unFilteredFeatures ||
        (unFilteredFeatures && !unFilteredFeatures.length)
      ) {
        unfilteredFeaturesToUpdate = graph.unFilteredFeatures.map((uF, i) => ({
          ...(graph.features && graph.features.length && graph.features[i]
            ? graph.features[i]
            : {}),
          ...uF
        }));
      } else {
        unfilteredFeaturesToUpdate = unFilteredFeatures.map((uF, i) => ({
          ...(graph.unFilteredFeatures &&
          graph.unFilteredFeatures.length &&
          graph.unFilteredFeatures[i]
            ? graph.unFilteredFeatures[i]
            : {}),
          ...uF
        }));
      }

      setUnFilteredFeatures(unfilteredFeaturesToUpdate);

      if (
        isAllowedToExecuteRequests &&
        (!(
          graph &&
          graph.unFilteredFeatures &&
          graph.unFilteredFeatures.length
        ) ||
          (graph &&
            graph.unFilteredFeatures &&
            graph.unFilteredFeatures.length &&
            activeStateHasChanged(
              graph.unFilteredFeatures,
              unfilteredFeaturesToUpdate
            )))
      ) {
        updateGraphFeatures(
          graph.id,
          surveyId,
          unfilteredFeaturesToUpdate,
          updateOpportunityGraph,
          viewToken || null,
          true
        );
      }
    }
  };

  if (isHidden) return null;

  const isFilteredInternalCheck =
    !!activeFilters.length ||
    (selectedExtremeLoversHatersGraphTypes &&
      selectedExtremeLoversHatersGraphTypes.id ===
        LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS &&
      selectedExtremeUsersFilter &&
      selectedExtremeUsersFilter.id);

  return (
    <div className={`${styles.container} opportunity-graph-container`}>
      <GraphActionsToolbar
        onEdit={() => {
          setEditGraphsPopup({
            ...graph,
            features: [...features],
            index: opportunityGraphIndex,
            type: templateTypes.OPPORTUNITY_GRAPH
          });
        }}
        onRemove={onRemoveOpportunityGraphClick}
        filteringOnSelection={filteringOnSelection}
        setFilteringOnSelection={setFilteringOnSelection}
        isFiltered={isFiltered}
        setEditBackgroundPopup={setEditBackgroundPopup}
        presentationModeEnabled={presentationModeEnabled}
        setPresentationModeEnabled={setPresentationModeEnabled}
        graph={graph}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        viewToken={viewToken}
        additionalInsightProperties={
          selectedExtremeLoversHatersGraphTypes &&
          selectedExtremeLoversHatersGraphTypes.id ===
            LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
            ? {
                activeLoversHaters: {
                  selectedExtremeUsersFeature:
                    selectedExtremeUsersFeature &&
                    selectedExtremeUsersFeature.id,
                  selectedExtremeUsersFilter:
                    selectedExtremeUsersFilter && selectedExtremeUsersFilter.id,
                  selectedExtremeLoversHatersGraphTypes:
                    selectedExtremeLoversHatersGraphTypes &&
                    selectedExtremeLoversHatersGraphTypes.id
                }
              }
            : {}
        }
      />
      <GraphTitle
        graphName={(graph && graph.name) || ''}
        data={data}
        questionsHaveResults={
          reducedQuestions &&
          reducedQuestions.length &&
          reducedQuestions.some(
            rQ => rQ.unfilteredResults && rQ.unfilteredResults.length
          )
        }
        isViewAllMode={
          selectedExtremeLoversHatersGraphTypes &&
          selectedExtremeLoversHatersGraphTypes.id ===
            LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
        }
      />
      <GraphInfoTabs
        graphInfoTabs={graphInfoTabs}
        activegraphInfoTab={activegraphInfoTab}
        setActiveGraphInfoTab={setActiveGraphInfoTab}
      />
      <div
        className={`${styles.blockContent} ${styles.opportunityGraphContent}`}
      >
        {features && features.length ? (
          <>
            <div className={styles.opportunityChartContainer} ref={barParent}>
              <Bar
                parent={barParent}
                width="460"
                height="430"
                data={data}
                unFilteredData={
                  activeLoversHatersFeatures &&
                  activeLoversHatersFeatures.length
                    ? transformDistributorUnfilteredData(
                        data,
                        unFilteredData,
                        activeLoversHatersFeatures
                      )
                    : unFilteredData
                }
                isFiltered={isFilteredInternalCheck}
                dataPointColors={dataPointColors.filter(
                  (_dataPoint, index) =>
                    features[index] && features[index].active
                )}
                unFilteredDataPointColors={dataPointColors.filter(
                  (_dataPoint, index) =>
                    unFilteredFeatures[index] &&
                    unFilteredFeatures[index].active
                )}
                questions={questions}
                activeLoversHaters={activeLoversHaters}
                themeColors={themeColors}
                xLabel={
                  graph.xLabel && graph.xLabel.trim().length
                    ? graph.xLabel
                    : null
                }
                yLabel={
                  graph.yLabel && graph.yLabel.trim().length
                    ? graph.yLabel
                    : null
                }
                dataTooltips={dataTooltips}
                setDataTooltips={setDataTooltips}
                onTooltipAdd={onTooltipAdd}
                onTooltipRemove={onTooltipRemove}
                onDataPointClick={onDataPointClick}
              />
            </div>
            <div className={styles.graphInfoContainer}>
              {activegraphInfoTab === graphInfoTabs.LEGEND && (
                <FeaturesList
                  features={features}
                  setFeatures={setFeatures}
                  dataPointColors={dataPointColors}
                  setDataPointColors={setDataPointColors}
                  saveUpdatedFeatures={(updatedFeatures, unfiltered) => {
                    if (isAllowedToExecuteRequests) {
                      updateGraphFeatures(
                        graph.id,
                        surveyId,
                        updatedFeatures,
                        updateOpportunityGraph,
                        viewToken || null,
                        unfiltered
                      );
                    }
                  }}
                  questions={reducedQuestions}
                  questionTypes={questionTypes}
                  distributorGroups={distributorGroups}
                  isFiltered={isFilteredInternalCheck}
                  unFilteredFeatures={unFilteredFeatures}
                  setUnFilteredFeatures={setUnFilteredFeatures}
                  filteringOnSelection={filteringOnSelection}
                  activeFiltersLength={activeFilters && activeFilters.length}
                  templateType={templateTypes.OPPORTUNITY_GRAPH}
                  presentationModeEnabled={presentationModeEnabled}
                  onTooltipRemove={i => {
                    if (dataTooltips.some(t => t.index === i)) {
                      onTooltipRemove(setDataTooltips, i);
                    }
                  }}
                  activeLoversHatersFeatures={activeLoversHatersFeatures}
                  triggerUpdate={triggerUpdate}
                  activeLoversHaters={activeLoversHaters}
                  selectedExtremeLoversHatersGraphTypes={
                    selectedExtremeLoversHatersGraphTypes
                  }
                  graph={graph}
                  saveUpdatedFilteredAndUnfilteredFeatures={(
                    featuresToUpdate,
                    unfilteredFeaturesToUpdate
                  ) => {
                    if (isAllowedToExecuteRequests) {
                      updateGraphFilteredAndUnfilteredFeatures(
                        graph.id,
                        surveyId,
                        featuresToUpdate,
                        unfilteredFeaturesToUpdate,
                        updateOpportunityGraph,
                        viewToken || null
                      );
                    }
                  }}
                />
              )}
              {activegraphInfoTab === graphInfoTabs.DECISION && (
                <Legend themeColors={themeColors} />
              )}
              {activegraphInfoTab === graphInfoTabs.LOVERS_HATERS && (
                <LoversHaters
                  activeLoversHaters={activeLoversHaters}
                  setActiveLoversHaters={setActiveLoversHaters}
                  applySurveyFilter={applySurveyFilter}
                  features={features}
                  dataPointColors={dataPointColors}
                  selectedFeature={selectedExtremeUsersFeature}
                  setSelectedFeature={setSelectedExtremeUsersFeature}
                  selectedFilter={selectedExtremeUsersFilter}
                  setSelectedFilter={setSelectedExtremeUsersFilter}
                  explanation="When trying to figure out which problems to address, it can be helpful to focus on
                  respondents who experience the greatest pain and are therefore the most active in seeking a solution.
                  What other problems do they find important and what characteristics does this group have?"
                  selectedExtremeLoversHatersGraphTypes={
                    selectedExtremeLoversHatersGraphTypes
                  }
                  setSelectedExtremeLoversHatersGraphTypes={
                    setSelectedExtremeLoversHatersGraphTypes
                  }
                  removeGraphActiveFilter={removeGraphActiveFilter}
                  configureViewAll={configureViewAll}
                  setActiveInsightId={setActiveInsightId}
                  unFilteredFeatures={unFilteredFeatures}
                />
              )}
              {activegraphInfoTab === graphInfoTabs.BRANDING && (
                <Branding
                  updateGraphMutation={updateOpportunityGraph}
                  graphId={graph.id}
                  features={features}
                  setFeatures={setFeatures}
                  surveyId={surveyId}
                  defaultDataPointColors={defaultDataPointColors}
                  themeColors={themeColors}
                  setThemeColors={setThemeColors}
                  dataPointColors={dataPointColors}
                  setDataPointColors={setDataPointColors}
                  selectedExtremeUsersFeature={selectedExtremeUsersFeature}
                  setSelectedExtremeUsersFeature={
                    setSelectedExtremeUsersFeature
                  }
                  questionTypes={questionTypes}
                  isAllowedToExecuteRequests={isAllowedToExecuteRequests}
                  viewToken={viewToken}
                />
              )}
              {activegraphInfoTab === graphInfoTabs.DISCLAIMER && (
                <Disclaimer
                  setDisplayContactInnovationExpertPopup={
                    setDisplayContactInnovationExpertPopup
                  }
                  isAllowedToExecuteRequests={isAllowedToExecuteRequests}
                />
              )}
            </div>
          </>
        ) : (
          <div className={`${styles.noData} no-chart-data`}>No data</div>
        )}
      </div>
      {editBackgroundPopup && (
        <Popup
          component={
            <EditBackgroundPopup
              onClose={() => setEditBackgroundPopup(false)}
              templateType={templateTypes.OPPORTUNITY_GRAPH}
              templateTypes={templateTypes}
              themeColors={themeColors}
              onSaveGraph={newThemeColors => {
                setThemeColors(newThemeColors);
                if (isAllowedToExecuteRequests) {
                  updateOpportunityGraph({
                    variables: {
                      id: graph.id,
                      survey: surveyId,
                      customThemeColors: newThemeColors,
                      ...(viewToken ? { viewToken } : {})
                    }
                  });
                }
              }}
            />
          }
          customStyles={{ padding: 'none', overflow: 'visible' }}
          onClose={() => setEditBackgroundPopup(false)}
        />
      )}
    </div>
  );
};

export default OpportunityGraph;
