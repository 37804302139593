import React from 'react';

import {
  COMMUNITY_ACTION_STEP_NAMES,
  COMMUNITY_ACTION_STEP_NAMES_LABELS,
  COMMUNITY_AUTOMATION_TYPES,
  COMMUNITY_EVENT_STEP_NAMES,
  COMMUNITY_EVENT_STEP_NAMES_LABELS
} from '../../../../../../../../helpers/constants';

import purpleBin from '../../../../../../../../../../assets/img/bin-purple.svg';
import greyBin from '../../../../../../../../../../assets/img/bin-grey.svg';
import automationsAdd from '../../../../../../../../../../assets/img/automations_plus.svg';

import styles from './CommunityAutomationStep.module.css';

export default ({
  step,
  stepIndex,
  communityAutomation,
  setCommunityAutomation,
  selectedCommunityAutomationStepIndex,
  setSelectedCommunityAutomationStepIndex,
  isNextStepFirstAction
}) => {
  const selectedActionSteps =
    communityAutomation &&
    communityAutomation.steps &&
    communityAutomation.steps.length &&
    communityAutomation.steps.filter(
      s => s.type === COMMUNITY_AUTOMATION_TYPES.ACTION && s.stepName
    );
  const isLastActionStep =
    selectedActionSteps && stepIndex === communityAutomation.steps.length - 1;
  const isLastActionStepDefied =
    selectedActionSteps &&
    selectedActionSteps.length &&
    isLastActionStep &&
    step &&
    step.stepName;

  const getPlaceholder = () => {
    if (step.type === COMMUNITY_AUTOMATION_TYPES.EVENT) {
      if (
        step.stepName === COMMUNITY_EVENT_STEP_NAMES.NOT_OPEN_APP_IN_X_DAYS &&
        step.amount1
      ) {
        return COMMUNITY_EVENT_STEP_NAMES_LABELS.NOT_OPEN_APP_IN_X_DAYS.replace(
          'x days',
          `${step.amount1} days`
        );
      }

      if (
        step.stepName ===
          COMMUNITY_EVENT_STEP_NAMES.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS &&
        step.amount1 &&
        step.amount2
      ) {
        const text1 = COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS.replace(
          'x survey',
          `${step.amount1} survey`
        );

        return text1.replace('y week', `${step.amount2} week`);
      }

      if (
        step.stepName === COMMUNITY_EVENT_STEP_NAMES.USER_RESULT_REWARDED &&
        step.amount1
      ) {
        return COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_RESULT_REWARDED.replace(
          'x good open answers',
          `${step.amount1} good open answers`
        );
      }

      if (step.stepName) {
        return COMMUNITY_EVENT_STEP_NAMES_LABELS[step.stepName];
      }

      return '+ Add event';
    }

    if (step.type === COMMUNITY_AUTOMATION_TYPES.ACTION) {
      if (
        step.stepName === COMMUNITY_ACTION_STEP_NAMES.GIVE_TOKENS &&
        step.amount3
      ) {
        return COMMUNITY_ACTION_STEP_NAMES_LABELS.GIVE_TOKENS.replace(
          'x tokens',
          `${step.amount3} tokens`
        );
      }

      if (step.stepName) {
        return COMMUNITY_ACTION_STEP_NAMES_LABELS[step.stepName];
      }

      return '+ Add action';
    }

    return '';
  };

  const getContainerStyle = () => {
    let containerStyles = styles.container;

    if (stepIndex === selectedCommunityAutomationStepIndex) {
      containerStyles = `${containerStyles} ${styles.active}`;
    } else if (step.stepName) {
      containerStyles = `${containerStyles} ${styles.stepNameAssigned}`;
    }

    if (isNextStepFirstAction) {
      containerStyles = `${containerStyles} ${styles.lastEventStep}`;
    }

    return containerStyles;
  };

  const onRemoveStepClick = () => {
    const newCommunityAutomation = { ...communityAutomation };

    const numberOfStepsWithTheSameType =
      communityAutomation &&
      communityAutomation.steps &&
      communityAutomation.steps.filter(s => s.type === step.type);

    if (
      numberOfStepsWithTheSameType &&
      numberOfStepsWithTheSameType.length >= 2
    ) {
      if (stepIndex === newCommunityAutomation.steps.length - 1) {
        setTimeout(() => {
          setSelectedCommunityAutomationStepIndex(
            newCommunityAutomation.steps.length - 2
          );
        }, 1);
      }

      newCommunityAutomation.steps.splice(stepIndex, 1);
    } else {
      newCommunityAutomation.steps = [...newCommunityAutomation.steps];
      newCommunityAutomation.steps[stepIndex] = {
        type: newCommunityAutomation.steps[stepIndex].type
      };
    }
    setCommunityAutomation(newCommunityAutomation);
  };

  const onAddNewActionStep = () => {
    const newCommunityAutomation = { ...communityAutomation };

    const newActionStep = {
      type: COMMUNITY_AUTOMATION_TYPES.ACTION
    };

    newCommunityAutomation.steps = [
      ...newCommunityAutomation.steps,
      newActionStep
    ];

    setCommunityAutomation(newCommunityAutomation);
  };

  return (
    <div
      className={getContainerStyle()}
      role="presentation"
      onClick={() => setSelectedCommunityAutomationStepIndex(stepIndex)}
    >
      {getPlaceholder()}
      {step.stepName && stepIndex === selectedCommunityAutomationStepIndex ? (
        <img
          role="presentation"
          alt="Delete icon"
          src={purpleBin}
          className={styles.binIcon}
          onClick={onRemoveStepClick}
        />
      ) : null}
      {step.stepName && stepIndex !== selectedCommunityAutomationStepIndex ? (
        <img
          role="presentation"
          alt="Delete icon"
          src={greyBin}
          className={styles.binIcon}
          onClick={onRemoveStepClick}
        />
      ) : null}
      {isLastActionStep && isLastActionStepDefied ? (
        <img
          role="presentation"
          alt="Add automations"
          src={automationsAdd}
          className={styles.addNewStepIcon}
          onClick={onAddNewActionStep}
        />
      ) : null}
    </div>
  );
};
