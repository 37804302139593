import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import AnswerThumbnail from '../../../../AnswerThumbnail/AnswerThumbnail';
import RankingQuestion from './components/RankingQuestion/RankingQuestion';
import ResultActionPopup from '../../../Questions/OpenQuestion/ResultActionPopup/ResultActionPopup';
import McStackedBars from './components/McStackedBars/McStackedBars';
import Draft from '../../Draft/Draft';

import {
  parseRichText,
  isRichText
} from '../../../../../../helpers/richTextHelpers';

import tickIconWhite from '../../../../../../../assets/img/tick_white.svg';
import flagIcon from '../../../../../../../assets/img/flag.svg';
import trashIcon from '../../../../../../../assets/img/trash.svg';

import styles from './AnswersDistribution.module.css';
import { REMOVE_RESULTS } from '../../../../../../../graphql/Survey';

export default ({
  block,
  onToggleFilter,
  headerStats,
  activeFilters,
  activeSorting,
  campaignId,
  probabilityAnswerSelected,
  filteringOnSelectionEnabled,
  extremeUsersFilterLabels,
  isAdmin,
  isAllowedToExecuteRequests,
  viewToken,
  chartView,
  chartViewNames,
  isPercentage,
  showStackedAverageScores
}) => {
  const isRankingQuestion = block && block.show_selected_answers_order;

  let totalAnswers;
  const processAnswers = (
    answers,
    results,
    resultStats,
    filteredResults,
    unfilteredCount
  ) => {
    totalAnswers = 0;

    const detailedAnswers = answers.map((answer, index, arr) => {
      const allowOtherIndex =
        block && block.allowOther && answers && answers.length
          ? answers.length - (block.allowNoneOfTheAbove ? 2 : 1)
          : null;

      const allowNoneOfTheAboveIndex =
        block && block.allowNoneOfTheAbove && arr && arr.length
          ? arr.length - 1
          : null;

      const isOther = index === allowOtherIndex;
      const isNoneOfTheAbove = index === allowNoneOfTheAboveIndex;

      const answerResponse = resultStats.values.filter(
        result => result.x === index
      );
      if (answerResponse.length) {
        totalAnswers += answerResponse[0].y;
        return {
          x: answerResponse[0].x,
          question: answers[answerResponse[0].x].answer,
          y: answerResponse[0].y,
          image_filename: answers[answerResponse[0].x].image_filename,
          dropOut: false,
          isOther,
          isNoneOfTheAbove
        };
      }
      if (block.dropOutResults) {
        if (block.dropOutResults.length) {
          return processDropOutAnswers(
            answer,
            index,
            isOther,
            isNoneOfTheAbove
          );
        }
      }
      return {
        x: index,
        question: answer.answer,
        y: 0,
        image_filename: answer.image_filename,
        dropOut: false,
        isOther,
        isNoneOfTheAbove
      };
    });

    // Dropout results count without including quota dropouts
    const dropOutCount = detailedAnswers.reduce((count, answer) => {
      if (answer.dropOut) {
        // eslint-disable-next-line no-param-reassign
        count += answer.y;
      }
      return count;
    }, 0);

    const processedAnswers = detailedAnswers.map(answer => {
      const answerWithPercentage = answer;
      if (answerWithPercentage.dropOut === true) {
        answerWithPercentage.percentage =
          answer.y > 0 && results.length + dropOutCount > 0
            ? ((answer.y / (results.length + dropOutCount)) * 100).toFixed(2)
            : 0;
        return answerWithPercentage;
      }
      if (
        block.type === 'Checkboxes' ||
        (block.type === 'Multiple Choice Question' &&
          block.selectAtMost &&
          block.selectAtMost > 1)
      ) {
        let number = results.length;
        if (
          activeFilters &&
          activeFilters.length &&
          block.unfilteredResults &&
          block.unfilteredResults.length &&
          block.results &&
          block.results.length &&
          !unfilteredCount
        ) {
          if (filteringOnSelectionEnabled) {
            // new way of filtering: total sample - selection
            number = block.unfilteredResults.length - block.results.length;
          } else {
            // old way of filtering: total sample
            number = block.unfilteredResults.length;
          }
        }

        answerWithPercentage.percentage =
          block &&
          results &&
          results.length &&
          answer.y > 0 &&
          number + dropOutCount > 0
            ? ((answer.y / (number + dropOutCount)) * 100).toFixed(2)
            : 0;
      }
      if (
        block.type === 'Multiple Choice Question' &&
        (!block.selectAtMost || block.selectAtMost === 1)
      ) {
        answerWithPercentage.percentage =
          answer.y > 0 && totalAnswers + dropOutCount > 0
            ? ((answer.y / (totalAnswers + dropOutCount)) * 100).toFixed(2)
            : 0;
      }
      return answerWithPercentage;
    });

    if (activeSorting.length > 0) {
      const sorting = activeSorting.find(s => s.sortingName === block.id);
      if (sorting) {
        switch (sorting.sortingOrder) {
          case 'ASC':
            return filteredResults && filteredResults.length
              ? filteredResults.reduce((acc, curr) => {
                  acc.push(
                    processedAnswers.find(e => e.question === curr.question)
                  );
                  return acc;
                }, [])
              : processedAnswers.sort((a, b) => a.y - b.y);
          case 'DESC':
            return filteredResults && filteredResults.length
              ? filteredResults.reduce((acc, curr) => {
                  acc.push(
                    processedAnswers.find(e => e.question === curr.question)
                  );
                  return acc;
                }, [])
              : processedAnswers.sort((a, b) => a.y - b.y).reverse();
          default:
            return processedAnswers;
        }
      }
    }

    return processedAnswers;
  };

  const processDropOutAnswers = (answer, index, isOther, isNoneOfTheAbove) => {
    let dropOutCount = 0;
    let dropOutState = false;
    if (
      block.type === 'Multiple Choice Question' &&
      (!block.selectAtMost || block.selectAtMost === 1) &&
      (answer.end === 'Failure' ||
        (block.end === 'Failure' &&
          !(
            answer.end === 'Success' ||
            (answer.nextFlow && answer.nextFlow.length)
          )))
    ) {
      dropOutState = true;
      block.dropOutResults.forEach(result => {
        if (result.answer === index) {
          dropOutCount += 1;
        }
      });
    }
    if (activeFilters && activeFilters.length) {
      dropOutCount = 0;
    }
    return {
      x: index,
      question: answer.answer,
      y: dropOutCount,
      image_filename: answer.image_filename,
      dropOut: dropOutState,
      isOther,
      isNoneOfTheAbove
    };
  };

  const answers = processAnswers(
    block.answers,
    block.results,
    block.resultStats,
    null,
    true
  );

  let unfiltered;

  if (
    activeFilters &&
    activeFilters.length &&
    !activeFilters.some(activeFilter => activeFilter.filterName === block.id)
  ) {
    let unfilteredResultStatsValues = [
      ...block.unfilteredResultStats.values
    ].map(value => ({
      ...value
    }));

    if (filteringOnSelectionEnabled) {
      const filteredData = block.resultStats.values;
      /* eslint-disable no-param-reassign */
      unfilteredResultStatsValues = unfilteredResultStatsValues.reduce(
        (unfilteredValuesWithoutSelection, currentDataPoint) => {
          if (filteredData && filteredData.length) {
            const matchingValue = filteredData.find(
              datapoint => datapoint.x === currentDataPoint.x
            );
            if (matchingValue) {
              currentDataPoint.y -= matchingValue.y;
            }
            if (currentDataPoint.y > 0) {
              unfilteredValuesWithoutSelection.push(currentDataPoint);
            }
          }
          return unfilteredValuesWithoutSelection;
        },
        []
      );
      /* eslint-enable no-param-reassign */
    }

    const unfilteredResultStats = {
      ...block.unfilteredResults,
      values: unfilteredResultStatsValues
    };

    unfiltered = processAnswers(
      block.answers,
      block.unfilteredResults,
      unfilteredResultStats,
      answers
    );
  }

  const checkUnfilteredResult = index =>
    unfiltered && unfiltered[index] && activeFilters && activeFilters.length;

  const onAnswerBarClick = answer => {
    if (
      block.type === 'Checkboxes' ||
      (block.type === 'Multiple Choice Question' &&
        block.selectAtMost &&
        block.selectAtMost > 1)
    ) {
      onToggleFilter(
        block.id,
        'answer',
        answer.x,
        'attributes.block_results',
        result => {
          if (result && result.attributes.block_results) {
            const questionBlock = result.attributes.block_results.filter(
              question => question.block === block.id
            );
            if (questionBlock.length) {
              if (
                questionBlock[0].answers &&
                questionBlock[0].answers.indexOf(answer.x) > -1
              ) {
                return true;
              }
            }
          }
          return false;
        }
      );
    }
    if (
      block.type === 'Multiple Choice Question' &&
      (!block.selectAtMost || block.selectAtMost === 1)
    ) {
      onToggleFilter(block.id, 'answer', answer.x, 'attributes.block_results');
    }
  };

  const [displayOtherAnswers, setDisplayOtherAnswers] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [answerSearch, setAnswerSearch] = useState('');
  const [displayFlagPopup, setDisplayFlagPopup] = useState(false);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const inputRef = useRef(null);

  const [removeResults, { loading: removeResultsLoading }] = useMutation(
    REMOVE_RESULTS,
    {
      onCompleted: () => window.location.reload()
    }
  );

  const searchAnswer = answerString => {
    const searchInputArr = answerSearch.toLowerCase().split(';');
    return searchInputArr.some(searchValue =>
      answerString.includes(searchValue.trim())
    );
  };

  const showOtherAnswers = customColor => {
    let customStyle = {};
    if (customColor) {
      customStyle = {
        borderColor: customColor
      };
    }
    const otherAnswers = block.results
      .filter(
        a =>
          a.answer_string &&
          a.answer_string !== 'undefined' &&
          a.answer_string !== undefined &&
          a.answer_string !== null &&
          searchAnswer(a.answer_string.toLowerCase())
      )
      .map(answer => (
        <span className="other-answer">
          <div
            style={
              selectedAnswers.some(a => a.resultId === answer.resultId)
                ? {
                    ...customStyle,
                    backgroundColor: customColor
                  }
                : customStyle
            }
            className={`${styles.visibleBox} ${
              selectedAnswers.some(a => a.resultId === answer.resultId)
                ? styles.visibleBoxActive
                : null
            }`}
            onClick={() => {
              if (selectedAnswers.some(a => a.resultId === answer.resultId)) {
                setSelectedAnswers(
                  [...selectedAnswers].filter(
                    selectedAnswer =>
                      selectedAnswer.resultId !== answer.resultId
                  )
                );
              } else {
                setSelectedAnswers([...selectedAnswers, answer]);
              }
            }}
            role="presentation"
          >
            <img
              src={tickIconWhite}
              className={styles.visibleTick}
              alt="Tick"
            />
          </div>
          {answer.answer_string}
        </span>
      ));

    return (
      <div className="other-answers-list">
        <div className={styles.actionContainer}>
          <div className={styles.searchAnswerInputContainer}>
            <input
              className={styles.searchAnswerInput}
              type="text"
              placeholder="Search"
              value={answerSearch}
              onChange={e => setAnswerSearch(e.target.value)}
              ref={inputRef}
            />
            {answerSearch && answerSearch.length && (
              <span
                className={styles.closeIcon}
                role="presentation"
                onClick={() => {
                  setAnswerSearch('');
                  if (inputRef && inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
              >
                t
              </span>
            )}
          </div>
          {isAdmin && (
            <div
              className={`${styles.actionButton} ${
                selectedAnswers && selectedAnswers.length > 0
                  ? ''
                  : styles.disabledButton
              }`}
              role="presentation"
              onClick={() => {
                if (selectedAnswers && selectedAnswers.length) {
                  setDisplayDeletePopup(true);
                }
              }}
            >
              <img src={trashIcon} className={styles.trashIcon} alt="Delete" />
              <span className={styles.buttonText}>Delete answer</span>
            </div>
          )}
          {isAdmin && (
            <div
              className={`${styles.actionButton} ${
                selectedAnswers && selectedAnswers.length > 0
                  ? ''
                  : styles.disabledButton
              }`}
              role="presentation"
              onClick={() => {
                if (selectedAnswers && selectedAnswers.length) {
                  setDisplayFlagPopup(true);
                }
              }}
            >
              <img src={flagIcon} className={styles.flagIcon} alt="Flag" />
              <span className={styles.buttonText}>Flag answer</span>
            </div>
          )}
        </div>
        {otherAnswers}
      </div>
    );
  };

  const onRemoveResultsConfirmClick = (
    blockId,
    selectedAnswersResultsIdentifiers,
    flagUser
  ) => {
    removeResults({
      variables: {
        survey: campaignId,
        results: selectedAnswersResultsIdentifiers,
        flagUser: typeof flagUser === 'boolean' ? flagUser : false,
        ...(blockId ? { questionId: blockId } : {})
      }
    });
  };

  const result =
    answers && answers.length && headerStats.responses ? (
      [
        answers.map((answer, index) => (
          <div
            className={`results-answers-distribution ${
              answer.dropOut === true ? 'drop-out' : ''
            }`}
            key={`answer-results-distribution-${answer.x.toString()}-${index.toString()}`}
          >
            <AnswerThumbnail
              answer={answer}
              answerIndex={index}
              block={block}
            />
            <div
              className={
                answer && answer.image_filename
                  ? 'results-answers-answer cover-image-padding'
                  : 'results-answers-answer'
              }
            >
              {answer && answer.question && isRichText(answer.question) ? (
                <div style={{ display: 'inline-block' }}>
                  <Draft
                    block={{ question: answer.question, type: 'Answer' }}
                  />
                </div>
              ) : (
                answer.question
              )}

              {(block.name === 'checkboxes' ||
                block.name === 'multiple-choice') &&
                answer.isOther && (
                  <span className="allow-other-label">Other answer</span>
                )}
              {(block.name === 'checkboxes' ||
                block.name === 'multiple-choice') &&
                answer.isNoneOfTheAbove && (
                  <span className="allow-other-label">None of the above</span>
                )}
            </div>
            <div className="results-answers-answer-details">
              <div
                role="presentation"
                className={
                  checkUnfilteredResult(index)
                    ? 'results-answers-bar-container unfiltered'
                    : 'results-answers-bar-container'
                }
                onClick={() => {
                  onAnswerBarClick(answer);
                }}
              >
                <div
                  className="results-answers-bar"
                  style={{ width: `${answer.percentage}%` }}
                />
                {checkUnfilteredResult(index) ? (
                  <div
                    role="presentation"
                    className="results-answers-bar unfiltered"
                    style={{
                      width: `${unfiltered[index].percentage}%`
                    }}
                  />
                ) : null}
              </div>
              <div className="results-answers-percentage">
                {answer.percentage}%
                {checkUnfilteredResult(index) ? (
                  <div className="unfiltered">
                    {unfiltered[index].percentage}%
                  </div>
                ) : null}
              </div>
              <div className="results-answers-answer-count">
                {answer.y}
                &nbsp;{answer.y === 1 ? 'answer' : 'answers'}{' '}
                {answer.dropOut === true
                  ? '(respondents not eligible for this survey)'
                  : ''}
                {checkUnfilteredResult(index) ? (
                  <div className="unfiltered">
                    {unfiltered[index].y}
                    &nbsp;{unfiltered[index].y === 1
                      ? 'answer'
                      : 'answers'}{' '}
                  </div>
                ) : null}
              </div>
            </div>
            {(block.name === 'checkboxes' ||
              block.name === 'multiple-choice') &&
              answer.isOther && (
                <div className="results-answers-other-answer">
                  <div
                    className="results-answers-other-answer-button"
                    role="presentation"
                    onClick={() => setDisplayOtherAnswers(!displayOtherAnswers)}
                  >
                    {displayOtherAnswers === true ? 'Hide' : 'Show'} answers
                  </div>
                  {displayOtherAnswers && showOtherAnswers()}
                </div>
              )}
          </div>
        )),
        headerStats.responses < 75 &&
        !(
          activeFilters &&
          activeFilters.length &&
          activeFilters.some(
            activeFilter =>
              activeFilter.value &&
              extremeUsersFilterLabels.includes(activeFilter.value)
          )
        ) ? (
          <div className={styles.disclaimer}>
            <div>
              Disclaimer: The{' '}
              {activeFilters && activeFilters.length ? 'selected' : 'total'}{' '}
              sample is rather small
              {` (< 75 respondents) `} which means that results are indicative
              but might not be statistically relevant.
            </div>
            <div>
              Uncollapse the settings bar in the right corner and activate
              statistical relevance to perform the check.
            </div>
          </div>
        ) : null
      ]
    ) : (
      <div className="no-chart-data">No data</div>
    );

  const stackedAllowed =
    (!block.selectAtMost || block.selectAtMost === 1) &&
    block &&
    block.answers &&
    block.answers.length &&
    (block.answers.length === 4 || block.answers.length === 5);

  return chartView === chartViewNames.STACKED && stackedAllowed ? (
    <McStackedBars
      answers={answers}
      unfiltered={unfiltered}
      isPercentage={isPercentage}
      isFiltered={!!(activeFilters && activeFilters.length)}
      filteringOnSelectionEnabled={filteringOnSelectionEnabled}
      selectedResultsCount={block.results.length}
      totalResultsCount={block.unfilteredResults.length}
      showStackedAverageScores={showStackedAverageScores}
      onAnswerBarClick={onAnswerBarClick}
      activeSorting={activeSorting.find(
        s =>
          s && s.sortingName && block && block.id && s.sortingName === block.id
      )}
    />
  ) : (
    <div
      className={
        isRankingQuestion
          ? `${
              styles.resultAnswersDistributionContainer
            } results-answers-distribution-container`
          : 'results-answers-distribution-container'
      }
    >
      {isRankingQuestion ? (
        <>
          <RankingQuestion
            question={block}
            activeFilters={activeFilters}
            activeSorting={activeSorting}
            allowOtherIndex={
              block.allowOther && answers && answers.length
                ? answers.length - (block.allowNoneOfTheAbove ? 2 : 1)
                : null
            }
            allowNoneOfTheAboveIndex={
              block.allowNoneOfTheAbove && answers && answers.length
                ? answers && answers.length - 1
                : null
            }
            results={block.results}
            campaignId={campaignId}
            probabilityAnswerSelected={probabilityAnswerSelected}
            isAllowedToExecuteRequests={isAllowedToExecuteRequests}
            viewToken={viewToken}
            showOtherAnswers={showOtherAnswers}
            onToggleFilter={onToggleFilter}
            filteringOnSelectionEnabled={filteringOnSelectionEnabled}
            parseRichText={parseRichText}
          />
          {headerStats.responses < 75 &&
          !(
            activeFilters &&
            activeFilters.length &&
            activeFilters.some(
              activeFilter =>
                activeFilter.value &&
                extremeUsersFilterLabels.includes(activeFilter.value)
            )
          ) ? (
            <div className={styles.disclaimer}>
              <div>
                Disclaimer: The{' '}
                {activeFilters && activeFilters.length ? 'selected' : 'total'}{' '}
                sample is rather small
                {` (< 75 respondents) `} which means that results are indicative
                but might not be statistically relevant.
              </div>
              <div>
                Uncollapse the settings bar in the right corner and activate
                statistical relevance to perform the check.
              </div>
            </div>
          ) : null}
        </>
      ) : (
        result
      )}
      {isAdmin && displayFlagPopup && (
        <ResultActionPopup
          answers={selectedAnswers}
          onClose={() => setDisplayFlagPopup(false)}
          campaignId={campaignId}
          title="Flag"
          description="Flagged answers will disappear from the overview. Respondents will not
          get notified."
          flagUser
          blockId={block && block.id ? block.id : null}
          loading={removeResultsLoading}
          onClickConfirm={(blockId, selectedAnswersResultsIdentifiers) =>
            onRemoveResultsConfirmClick(
              blockId,
              selectedAnswersResultsIdentifiers,
              true
            )
          }
          isAllowedToConfirmClick={sA => sA && sA.length}
        />
      )}
      {isAdmin && displayDeletePopup && (
        <ResultActionPopup
          answers={selectedAnswers}
          onClose={() => setDisplayDeletePopup(false)}
          campaignId={campaignId}
          title="Delete"
          description="Deleted answers will disappear from the overview. Respondents will not be punished."
          flagUser={false}
          blockId={block && block.id ? block.id : null}
          loading={removeResultsLoading}
          onClickConfirm={(blockId, selectedAnswersResultsIdentifiers) =>
            onRemoveResultsConfirmClick(
              blockId,
              selectedAnswersResultsIdentifiers
            )
          }
          isAllowedToConfirmClick={sA => sA && sA.length}
        />
      )}
    </div>
  );
};
