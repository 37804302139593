import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

const StackedBar = props => {
  const {
    parent,
    widthPercentageValue,
    data,
    colorsPallete,
    isPercentage,
    onClick
  } = props;

  const [svg, setSvg] = useState(null);

  useEffect(() => {
    drawChart(svg);
  }, []);

  useEffect(
    () => {
      drawChart(svg);
    },
    [svg, data]
  );

  const tooltipDiv = d3.select('.tooltip-container').style('display', 'none');

  const onBarClickEvent = d => {
    onClick(`${d.questionIndex}`, d.answerIndex);
  };

  const drawChart = svgRef => {
    if (parent && parent.current && parent.current.offsetWidth) {
      const margin = { left: 0, right: 0, top: 0 };
      const width =
        parent.current.offsetWidth * widthPercentageValue -
        (margin.left ? parseInt(margin.left, 10) : 0) -
        (margin.right ? parseInt(margin.right, 10) : 0);

      const height = 34;

      if (!data || (data && data.length === 0)) return null;

      const chartSvg = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      chartSvg.selectAll('*').remove();

      const chart = chartSvg
        .append('g')
        .attr('width', width)
        .attr('height', height)
        .attr('class', 'results-bar-container')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      const total = d3.sum(data, d => d.value);

      const dataMapping = data.map((d, i) => {
        const cumulative = data.reduce((acc, curr, index) => {
          if (index < i) {
            return acc + curr.value;
          }
          return acc;
        }, 0);
        const showTooltip =
          d.value && d.value / total < (isPercentage ? 0.05 : 0.03);
        return {
          ...d,
          cumulative,
          showTooltip
        };
      });

      const xScale = d3
        .scaleLinear()
        .domain([0, total])
        .range([0, width]);

      chart
        .selectAll('rect')
        .data(dataMapping)
        .enter()
        .append('rect')
        .attr('class', 'rect-stacked')
        .attr('x', d => xScale(d.cumulative))
        .attr('y', 0)
        .attr('height', height)
        .attr('width', d => xScale(d.value))
        .style('fill', (d, i) => colorsPallete[i])
        .style('cursor', 'pointer')
        .on('click', onBarClickEvent)
        .on('mousemove', (d, i) => {
          if (d.showTooltip) {
            tooltipDiv
              .style('color', colorsPallete[i])
              .style('display', 'block');

            tooltipDiv
              .html(
                `<strong>${isPercentage ? `${d.value}%` : d.value}</strong>`
              )
              .style('left', `${d3.event.pageX + 5}px`)
              .style('top', `${d3.event.pageY - 28}px`)
              .style('min-width', 'auto')
              .style('font-size', '12px');
          }
        })
        .on('mouseout', d => {
          if (d.showTooltip) {
            tooltipDiv.style('display', 'none');
          }
        });

      chart
        .selectAll('.text-value')
        .data(dataMapping)
        .enter()
        .append('text')
        .attr('class', 'text-value')
        .attr('text-anchor', 'middle')
        .attr('x', d => xScale(d.cumulative) + xScale(d.value) / 2)
        .attr('y', height / 2 + 3)
        .text(d => (d.value ? `${d.value}${isPercentage ? '%' : ''}` : ''))
        .attr('fill', '#fff')
        .attr('font-size', '12px')
        .style('text-anchor', 'middle')
        .style('cursor', 'pointer')
        .style('display', d => (d.showTooltip ? 'none' : ''))
        .on('click', onBarClickEvent);
    }

    return null;
  };

  if (data && data.length) {
    return (
      <svg
        ref={elem => {
          if (elem) {
            setSvg(elem);
          }
        }}
      />
    );
  }

  return <div className="no-chart-data">No data</div>;
};

export default StackedBar;
