import React, { useState, useEffect, useRef } from 'react';

import blackArrowDown from '../../../../../../assets/img/black-arrow-down.svg';
import tickIconWhite from '../../../../../../assets/img/tick_white.svg';
import dropDownBoldGreyIcon from '../../../../../../assets/img/dropdown-arrow-bold-grey.svg';

import styles from './Toolbar.module.css';
import AddInsight from '../AddInsight/AddInsight';

const MultipleChoiceToolbar = props => {
  const {
    block,
    mcAnswerSortingOrders,
    onToggleSorting,
    activeSorting,
    views,
    view,
    setView,
    initialView,
    probabilityAnswerSelected,
    setProbabilityAnswerSelected,
    filteringOnSelection,
    setFilteringOnSelection,
    showCompareToTotalSample,
    setShowAddInsightsPopup,
    viewToken,
    chartView,
    setChartView,
    chartViewLabels,
    chartViewNames,
    isPercentage,
    setIsPercentage,
    showStackedAverageScores,
    setShowStackedAverageScores
  } = props;

  const [toolbarActive, setToolbarActive] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [displayOrientationDropdown, setDisplayOrientationDropdown] = useState(
    false
  );
  const [displayNumbersDropdown, setDisplayNumbersDropdown] = useState(false);

  const numberLabels = {
    PERCENTAGE: 'Relative numbers (%)',
    ABSOLUTE: 'Absolute numbers (#)'
  };

  const dropdownRef = useRef(null);
  const orientationRef = useRef(null);
  const numbersRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        dropdownRef &&
        dropdownRef.current &&
        dropdownRef.current.children &&
        dropdownRef.current.children.length &&
        Array.from(dropdownRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setDisplayDropdown(false);
    }
    if (
      !(
        orientationRef &&
        orientationRef.current &&
        orientationRef.current.children &&
        orientationRef.current.children.length &&
        Array.from(orientationRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setDisplayOrientationDropdown(false);
    }
    if (
      !(
        numbersRef &&
        numbersRef.current &&
        numbersRef.current.children &&
        numbersRef.current.children.length &&
        Array.from(numbersRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setDisplayNumbersDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let sortingOrder = mcAnswerSortingOrders.DEFAULT;

  if (activeSorting && activeSorting.length > 0 && block && block.id) {
    const sorting = activeSorting.find(s => s.sortingName === block.id);

    if (
      sorting &&
      sorting.sortingOrder &&
      mcAnswerSortingOrders[sorting.sortingOrder]
    ) {
      sortingOrder = mcAnswerSortingOrders[sorting.sortingOrder];
    }
  }

  const stackedAllowed =
    (!block.selectAtMost || block.selectAtMost === 1) &&
    block &&
    block.answers &&
    block.answers.length &&
    (block.answers.length === 4 || block.answers.length === 5);

  return (
    <>
      <div
        className={`${styles.container} ${
          toolbarActive ? styles.containerActive : ''
        }`}
        style={{ display: 'block' }}
      >
        <div className={styles.subContainer}>
          {!(chartView && chartView === chartViewNames.STACKED) ? (
            <div
              className={`${styles.toolbarItem} ${styles.clickable} ${
                styles.verticalAlign
              }`}
              role="presentation"
              onClick={() => {
                setProbabilityAnswerSelected(!probabilityAnswerSelected);
                if (!block.show_selected_answers_order) {
                  if (view === views.PROBABILITY_ANSWER_SELECTED) {
                    setView(initialView);
                  } else {
                    setView(views.PROBABILITY_ANSWER_SELECTED);
                  }
                }
              }}
            >
              <div
                className={`${styles.visibleBox} ${
                  probabilityAnswerSelected ? styles.visibleBoxActive : null
                }`}
              >
                <img
                  src={tickIconWhite}
                  className={styles.visibleTick}
                  alt="Tick"
                />
              </div>
              Show statistical relevance
            </div>
          ) : null}
          {showCompareToTotalSample ? (
            <div
              className={`${styles.toolbarItem} ${styles.verticalAlign} ${
                styles.clickable
              }`}
              role="presentation"
              onClick={() => {
                setFilteringOnSelection(!filteringOnSelection);
              }}
            >
              <div
                className={`${styles.visibleBox} ${
                  !filteringOnSelection ? styles.visibleBoxActive : null
                }`}
              >
                <img
                  src={tickIconWhite}
                  className={styles.visibleTick}
                  alt="Tick"
                />
              </div>
              Compare to total sample
            </div>
          ) : null}
          {chartView === chartViewNames.STACKED ? (
            <div
              className={`${styles.toolbarItem} ${styles.verticalAlign} ${
                styles.clickable
              }`}
              role="presentation"
              onClick={() => {
                setShowStackedAverageScores(!showStackedAverageScores);
              }}
            >
              <div
                className={`${styles.visibleBox} ${
                  showStackedAverageScores ? styles.visibleBoxActive : null
                }`}
              >
                <img
                  src={tickIconWhite}
                  className={styles.visibleTick}
                  alt="Tick"
                />
              </div>
              Show average score
            </div>
          ) : null}
          {stackedAllowed ? (
            <div className={styles.toolbarItem}>
              <div
                ref={orientationRef}
                role="presentation"
                className={styles.dropdownContainer}
                onClick={() => setDisplayOrientationDropdown(prev => !prev)}
                style={{ minWidth: '160px' }}
              >
                <img
                  className={`${styles.dropdownArrow} ${
                    displayOrientationDropdown ? styles.dropdownArrowActive : ''
                  }`}
                  src={blackArrowDown}
                  alt="Drop down arrow"
                />
                {chartViewLabels[chartView]}
                {displayOrientationDropdown && (
                  <div className={styles.dropdownList}>
                    {Object.entries(chartViewLabels).map(([key, value]) => (
                      <span
                        role="presentation"
                        className={styles.dropdownOption}
                        key={value}
                        onClick={() => {
                          setChartView(key);
                        }}
                      >
                        {value}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {chartView === chartViewNames.STACKED && stackedAllowed ? (
            <div className={styles.toolbarItem}>
              <div
                ref={numbersRef}
                role="presentation"
                className={styles.dropdownContainer}
                onClick={() => setDisplayNumbersDropdown(prev => !prev)}
                style={{ minWidth: '160px' }}
              >
                <img
                  className={`${styles.dropdownArrow} ${
                    displayNumbersDropdown ? styles.dropdownArrowActive : ''
                  }`}
                  src={blackArrowDown}
                  alt="Drop down arrow"
                />
                {isPercentage ? numberLabels.PERCENTAGE : numberLabels.ABSOLUTE}
                {displayNumbersDropdown && (
                  <div className={styles.dropdownList}>
                    {Object.values(numberLabels).map(value => (
                      <span
                        role="presentation"
                        className={styles.dropdownOption}
                        key={value}
                        onClick={() => {
                          if (value === numberLabels.PERCENTAGE) {
                            setIsPercentage(true);
                          } else {
                            setIsPercentage(false);
                          }
                        }}
                      >
                        {value}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className={styles.toolbarItem}>
            <div
              ref={dropdownRef}
              role="presentation"
              className={styles.dropdownContainer}
              onClick={() => setDisplayDropdown(prev => !prev)}
              style={{ minWidth: '165px' }}
            >
              <img
                className={`${styles.dropdownArrow} ${
                  displayDropdown ? styles.dropdownArrowActive : ''
                }`}
                src={blackArrowDown}
                alt="Drop down arrow"
              />
              {sortingOrder}
              {displayDropdown && (
                <div className={styles.dropdownList}>
                  {Object.entries(mcAnswerSortingOrders)
                    .filter(([key]) =>
                      !block.show_selected_answers_order
                        ? key !== 'ANSWER'
                        : true
                    )
                    .map(([key, value]) => (
                      <span
                        role="presentation"
                        className={styles.dropdownOption}
                        key={key}
                        onClick={() => {
                          if (sortingOrder !== value) {
                            onToggleSorting(block.id, key);
                          }
                        }}
                      >
                        {value}
                      </span>
                    ))}
                </div>
              )}
            </div>
          </div>
          {!viewToken ? (
            <AddInsight
              setShowAddInsightsPopup={setShowAddInsightsPopup}
              questionId={block.id}
            />
          ) : null}
        </div>
        <div className={styles.subContainer}>
          <div className={styles.greyTextToolbarItem}>
            {block.selectAtLeast > 1 || block.selectAtMost > 1
              ? `${block.selectAtLeast} - ${block.selectAtMost}`
              : block.selectAtLeast}{' '}
            answer {block.selectAtMost > 1 ? 'options' : 'option'}
          </div>
          <div
            className={`${styles.greyTextToolbarItem} ${styles.verticalAlign}`}
          >
            {block.random ? 'randomiser' : 'no randomiser'}
          </div>
        </div>
      </div>
      <img
        src={dropDownBoldGreyIcon}
        alt="Display toolbar"
        className={`${styles.dropDownToolbarIcon} ${
          !toolbarActive ? styles.dropDownToolbarIconInactive : ''
        }`}
        role="presentation"
        onClick={() => {
          setToolbarActive(!toolbarActive);
        }}
      />
    </>
  );
};

export default MultipleChoiceToolbar;
