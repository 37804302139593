import React, { useMemo, useState } from 'react';

import Toggle from './Toggle/Toggle';
import Popup from '../../../../../Popup';
import EditFeaturePopup from '../EditFeaturePopup/EditFeaturePopup';

import editIcon from '../../../../../../../assets/img/edit-black.svg';
import questionMark from '../../../../../../../assets/img/accountmanagement/am-questionmark.svg';

import styles from './FeaturesList.module.css';
import {
  LOVERS_HATERS_FILTER_DATAPOINT_LABELS,
  LOVERS_HATERS_GRAPH_TYPES
} from '../../helpers/constants';
import calculateExtremeUsersData from '../../helpers/calculateExtremeUsersData';
import createUnfilteredFeatures from '../../helpers/createUnfilteredFeatures';

const FeaturesList = props => {
  const {
    features,
    setFeatures,
    dataPointColors,
    setDataPointColors,
    saveUpdatedFeatures,
    questions,
    questionTypes,
    distributorGroups,
    isFiltered,
    filteredGraphFeatures,
    unFilteredFeatures,
    setUnFilteredFeatures,
    filteringOnSelection,
    activeFiltersLength,
    templateType,
    presentationModeEnabled,
    onTooltipRemove,
    activeLoversHatersFeatures,
    triggerUpdate,
    activeLoversHaters,
    selectedExtremeLoversHatersGraphTypes,
    graph,
    saveUpdatedFilteredAndUnfilteredFeatures
  } = props;

  const [editFeaturePopup, setEditFeaturePopup] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const handleMouseOver = (e, featureIndex) => {
    setTooltipPosition({
      x: e.clientX,
      y: e.clientY,
      featureQuestions: featuresQuestions.find(
        qF => qF.featureIndex === featureIndex
      )
    });
  };

  const handleMouseOut = () => {
    setTooltipPosition(null);
  };

  const getFeatureResultsCount = (xQuestionId, yQuestionId, feature) => {
    let xQuestion = questions.find(q => q.id === xQuestionId);
    let yQuestion = questions.find(q => q.id === yQuestionId);

    const featureCounts = {
      results: 0,
      unfilteredResults: 0
    };
    if (xQuestion && yQuestion) {
      if (
        selectedExtremeLoversHatersGraphTypes &&
        selectedExtremeLoversHatersGraphTypes.id ===
          LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
      ) {
        const viewAllActiveLoversHaters = {
          ...feature,
          filter: activeLoversHaters && activeLoversHaters.filter
        };

        const extremeUsersResultIds = calculateExtremeUsersData(
          viewAllActiveLoversHaters,
          questions,
          questionTypes
        );

        // Filter results that should match
        xQuestion = JSON.parse(JSON.stringify(xQuestion));
        xQuestion.results = xQuestion.results.filter(
          result => extremeUsersResultIds.indexOf(result.resultId) > -1
        );

        yQuestion = JSON.parse(JSON.stringify(yQuestion));
        yQuestion.results = yQuestion.results.filter(
          result => extremeUsersResultIds.indexOf(result.resultId) > -1
        );
      }

      // Take smallest number in case two questions have different amount of results
      featureCounts.results =
        xQuestion.results.length < yQuestion.results.length
          ? xQuestion.results.length
          : yQuestion.results.length;

      if (isFiltered) {
        const xCount = filteringOnSelection
          ? xQuestion.unfilteredResults.length - xQuestion.results.length
          : xQuestion.unfilteredResults.length;

        const yCount = filteringOnSelection
          ? yQuestion.unfilteredResults.length - yQuestion.results.length
          : yQuestion.unfilteredResults.length;

        featureCounts.unfilteredResults = xCount < yCount ? xCount : yCount;
      }
    }
    return featureCounts;
  };

  const featureResultStats = useMemo(
    () => {
      if (features && features.length && questions && questions.length) {
        return features.map(feature =>
          getFeatureResultsCount(
            feature[questionTypes.xQuestion],
            feature[questionTypes.yQuestion],
            feature
          )
        );
      }
      return [];
    },
    [
      features,
      filteredGraphFeatures,
      filteringOnSelection,
      activeFiltersLength,
      questions
    ]
  );

  const onUpdateDataPointActive = (i, active, unFilteredFeature) => {
    if (unFilteredFeature) {
      const newUnFilteredGraphFeatures = [
        ...unFilteredFeatures.map(uFF => ({ ...uFF }))
      ];
      newUnFilteredGraphFeatures[i] = {
        ...newUnFilteredGraphFeatures[i],
        active
      };
      setUnFilteredFeatures(newUnFilteredGraphFeatures);
      saveUpdatedFeatures(newUnFilteredGraphFeatures, true);

      // Remove visible tooltips
      if (!active) {
        onTooltipRemove(`${i}_unfiltered`);
      }
    } else {
      const newFeatures = [...features.map(feature => ({ ...feature }))];
      newFeatures[i] = {
        ...newFeatures[i],
        active
      };
      setFeatures(newFeatures);
      saveUpdatedFeatures(newFeatures);

      // Remove visible tooltips
      if (!active) {
        onTooltipRemove(i);
      }
    }
  };

  const onUpdateGroupActive = group => {
    const newActiveState = !isGroupActive(group.features);

    const newFeatures = [...features.map(feature => ({ ...feature }))];
    group.features.forEach(groupFeature => {
      newFeatures[groupFeature.featureIndex] = {
        ...newFeatures[groupFeature.featureIndex],
        active: newActiveState
      };
    });
    setFeatures(newFeatures);

    if (!newActiveState) {
      group.features.forEach(gF => {
        onTooltipRemove(gF.featureIndex);
      });
    }
    let unfilteredFeaturesToUpdate =
      unFilteredFeatures && unFilteredFeatures.length
        ? unFilteredFeatures
        : graph.unFilteredFeatures;

    if (!unfilteredFeaturesToUpdate || !unfilteredFeaturesToUpdate.length) {
      unfilteredFeaturesToUpdate = createUnfilteredFeatures(
        graph,
        graph.features
      );
    }

    const newUnFilteredGraphFeatures = [
      ...unfilteredFeaturesToUpdate.map(uFF => ({ ...uFF }))
    ];
    group.features.forEach(groupFeature => {
      newUnFilteredGraphFeatures[groupFeature.featureIndex] = {
        ...newUnFilteredGraphFeatures[groupFeature.featureIndex],
        active: newActiveState
      };
    });

    if (isFiltered) {
      if (!newActiveState) {
        group.features.forEach(gF => {
          onTooltipRemove(`${gF.featureIndex}_unfiltered`);
        });
      }
    }

    setUnFilteredFeatures(newUnFilteredGraphFeatures);

    saveUpdatedFilteredAndUnfilteredFeatures(
      newFeatures,
      newUnFilteredGraphFeatures
    );
  };

  const isGroupActive = groupFeatures => {
    if (isFiltered) {
      return [
        ...groupFeatures,
        ...unFilteredFeatures.filter((_unfilteredFeature, unfilteredIndex) =>
          groupFeatures.some(f => f.featureIndex === unfilteredIndex)
        )
      ].every(feature => feature.active);
    }
    return groupFeatures.every(feature => feature.active);
  };

  const featureResultsCountMessage = (featureCounts, unFilteredToggle) => {
    let countText = 'Total sample';

    if (
      selectedExtremeLoversHatersGraphTypes &&
      selectedExtremeLoversHatersGraphTypes.id ===
        LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
    ) {
      if (unFilteredToggle && filteringOnSelection) {
        countText = 'Total sample';
      } else if (!unFilteredToggle && isFiltered) {
        if (activeLoversHaters && activeLoversHaters.filter) {
          countText =
            LOVERS_HATERS_FILTER_DATAPOINT_LABELS[activeLoversHaters.filter];
        } else {
          countText = 'Selected';
        }
      }
    } else {
      // eslint-disable-next-line
      if (unFilteredToggle && filteringOnSelection) {
        countText = 'Not selected';
      } else if (!unFilteredToggle && isFiltered) {
        countText = 'Selected';
      }
    }

    return `${countText} (N = ${
      unFilteredToggle ? featureCounts.unfilteredResults : featureCounts.results
    })`;
  };

  const onSaveFeature = feature => {
    const newFeatures = [...features.map(f => ({ ...f }))];
    newFeatures[feature.featureIndex] = feature;
    setFeatures(newFeatures);
    saveUpdatedFeatures(newFeatures);

    if (feature.colorCode !== dataPointColors[feature.featureIndex]) {
      const newDataPointColors = [...dataPointColors];
      newDataPointColors[feature.featureIndex] = feature.colorCode;
      setDataPointColors([...newDataPointColors]);
    }
  };

  const displayBlockQuestion = blockQuestion => {
    let question;
    try {
      question = JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      question = blockQuestion;
    }
    return question;
  };

  const featuresQuestions = useMemo(() => {
    if (features && features.length && questions && questions.length) {
      return features.reduce((questionFeatures, feature, featureIndex) => {
        const xQuestion = questions.find(
          q => q.id === feature[questionTypes.xQuestion]
        );
        const yQuestion = questions.find(
          q => q.id === feature[questionTypes.yQuestion]
        );
        if (xQuestion && yQuestion) {
          questionFeatures.push({
            featureIndex,
            xQuestion: {
              title: displayBlockQuestion(xQuestion.question),
              formattedGlobalIndex: xQuestion.formattedGlobalIndex
            },
            yQuestion: {
              title: displayBlockQuestion(yQuestion.question),
              formattedGlobalIndex: yQuestion.formattedGlobalIndex
            }
          });
        }
        return questionFeatures;
      }, []);
    }
    return [];
  }, []);

  const groupedFeatures = useMemo(
    () =>
      features.reduce(
        (ordered, feature, featureIndex) => {
          let match = false;
          const newF = {
            ...feature,
            featureIndex
          };
          if (distributorGroups && distributorGroups.length) {
            distributorGroups.forEach(group => {
              if (
                group.flows &&
                group.flows.some(
                  flow => flow === feature[questionTypes.xQuestion]
                ) &&
                group.flows.some(
                  flow => flow === feature[questionTypes.yQuestion]
                )
              ) {
                match = true;
                if (
                  ordered.groups.length &&
                  ordered.groups.some(
                    g => g.id && g.features && g.id === group.id
                  )
                ) {
                  ordered.groups
                    .find(g => g.id === group.id)
                    .features.push(newF);
                } else {
                  ordered.groups.push({
                    id: group.id,
                    branchLabel: group.branchLabel,
                    features: [newF]
                  });
                }
              }
            });
          }
          if (!match) {
            ordered.features.push(newF);
          }
          return ordered;
        },
        { groups: [], features: [] }
      ),
    [features, triggerUpdate]
  );

  const returnFeature = (feature, index) => (
    <React.Fragment key={`feature-${index.toString()}`}>
      {((presentationModeEnabled && feature.active) ||
        !presentationModeEnabled) && (
        <div className={styles.feature}>
          <span
            className={styles.circle}
            style={{ backgroundColor: dataPointColors[index] }}
          />
          <div>
            <div
              className={styles.featureDescription}
              onMouseEnter={e => {
                if (!presentationModeEnabled) {
                  handleMouseOver(e, feature.featureIndex);
                }
              }}
              onMouseLeave={() => {
                if (!presentationModeEnabled) {
                  handleMouseOut();
                }
              }}
            >
              {feature.description}
            </div>
            {!presentationModeEnabled && (
              <div className={styles.sampleSize}>
                {featureResultStats && featureResultStats[index]
                  ? featureResultsCountMessage(featureResultStats[index])
                  : 'Total sample (N = 0)'}
              </div>
            )}
          </div>
          {!presentationModeEnabled && (
            <div className={styles.right}>
              <img
                src={editIcon}
                alt="Edit"
                className={styles.editIcon}
                role="presentation"
                onClick={() => setEditFeaturePopup(feature)}
              />
              <Toggle
                checked={feature.active}
                onToggle={() => {
                  onUpdateDataPointActive(index, !feature.active, false);
                }}
              />
            </div>
          )}
        </div>
      )}
      {isFiltered &&
        unFilteredFeatures.length &&
        unFilteredFeatures[index] &&
        ((presentationModeEnabled && unFilteredFeatures[index].active) ||
          !presentationModeEnabled) && (
          <div className={styles.feature}>
            <span
              className={styles.circle}
              style={{
                backgroundColor: dataPointColors[index],
                opacity: '0.4'
              }}
            />
            <div>
              <div>{feature.description}</div>
              {!presentationModeEnabled && (
                <div className={styles.sampleSize}>
                  <span>
                    {featureResultStats && featureResultStats[index]
                      ? featureResultsCountMessage(
                          featureResultStats[index],
                          true
                        )
                      : 'Total sample (N = 0)'}
                  </span>
                  {activeLoversHatersFeatures &&
                  activeLoversHatersFeatures.length &&
                  !activeLoversHatersFeatures.includes(feature.featureIndex) &&
                  (featureResultStats &&
                    featureResultStats[index] &&
                    featureResultStats[index].results === 0) ? (
                    <div className={styles.loversHatersTooltipContainer}>
                      <img
                        className={styles.loversHatersTooltipIcon}
                        alt="info"
                        src={questionMark}
                      />
                      <div className={styles.loversHatersTooltipPopup}>
                        This data point isn’t displayed because the respondents
                        you’re currently filtering upon with a lovers/haters
                        filter haven’t encountered this problem/concept/idea.
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            {!presentationModeEnabled && (
              <Toggle
                checked={unFilteredFeatures[index].active}
                onToggle={() => {
                  onUpdateDataPointActive(
                    index,
                    !unFilteredFeatures[index].active,
                    true
                  );
                }}
              />
            )}
          </div>
        )}
    </React.Fragment>
  );

  const getGroupResultStats = groupFeatures =>
    featureResultStats[
      groupFeatures.reduce((prev, current) =>
        featureResultStats[prev.featureIndex] >
        featureResultStats[current.featureIndex]
          ? prev
          : current
      ).featureIndex
    ];

  return (
    <>
      {tooltipPosition && tooltipPosition.featureQuestions && (
        <div
          className={styles.tooltipContainer}
          style={
            tooltipPosition
              ? {
                  left: `${tooltipPosition.x + 15}px`,
                  top: `${tooltipPosition.y - 5}px`,
                  display: 'block'
                }
              : {}
          }
        >
          <div className={styles.questionTitle}>
            X-axis (Q
            {tooltipPosition.featureQuestions.xQuestion.formattedGlobalIndex})
          </div>
          <div className={styles.questionText}>
            {tooltipPosition.featureQuestions.xQuestion.title}
          </div>
          <div className={styles.questionTitle}>
            Y-axis (Q
            {tooltipPosition.featureQuestions.yQuestion.formattedGlobalIndex})
          </div>
          <div>{tooltipPosition.featureQuestions.yQuestion.title}</div>
        </div>
      )}
      {presentationModeEnabled ? (
        <div className={`${styles.featuresContainer} ${styles.noScrolling}`}>
          {features && features.length
            ? features.map((feature, index) => returnFeature(feature, index))
            : null}
        </div>
      ) : (
        <div className={styles.featuresContainer}>
          {groupedFeatures && groupedFeatures.groups.length
            ? groupedFeatures.groups.map(group => (
                <div className={styles.group}>
                  <div className={styles.branchLabelContainer}>
                    <span className={styles.branchLabel}>
                      {group.branchLabel}
                    </span>
                    <span className={styles.sampleSize}>
                      {featureResultStats &&
                      featureResultStats[group.features[0].featureIndex]
                        ? featureResultsCountMessage(
                            getGroupResultStats(group.features)
                          )
                        : 'Total sample (N = 0)'}
                    </span>
                    <Toggle
                      checked={isGroupActive(group.features)}
                      onToggle={() => {
                        onUpdateGroupActive(group);
                      }}
                    />
                  </div>
                  {group.features.map(feature =>
                    returnFeature(feature, feature.featureIndex)
                  )}
                </div>
              ))
            : null}
          {groupedFeatures && groupedFeatures.features.length ? (
            <div className={styles.group}>
              {groupedFeatures.groups.length ? (
                <div className={styles.branchLabelContainer}>
                  <span className={styles.branchLabel}>Other</span>
                </div>
              ) : null}
              {groupedFeatures.features.map(feature =>
                returnFeature(feature, feature.featureIndex)
              )}
            </div>
          ) : null}
        </div>
      )}
      {editFeaturePopup ? (
        <Popup
          component={
            <EditFeaturePopup
              onClose={() => setEditFeaturePopup(false)}
              questions={questions}
              templateType={templateType}
              feature={editFeaturePopup}
              features={features}
              onSaveFeature={feature => onSaveFeature(feature)}
              dataPointColors={dataPointColors}
            />
          }
          customStyles={{ padding: 'none', overflow: 'visible' }}
          onClose={() => setEditFeaturePopup(false)}
        />
      ) : null}
    </>
  );
};

export default FeaturesList;
