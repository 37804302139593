import React from 'react';

import StackedBars from '../../../StackedBars/StackedBars';

import styles from './McStackedBars.module.css';

const McStackedBars = props => {
  const {
    answers,
    unfiltered,
    isPercentage,
    isFiltered,
    filteringOnSelectionEnabled,
    selectedResultsCount,
    totalResultsCount,
    showStackedAverageScores,
    onAnswerBarClick,
    activeSorting
  } = props;

  let stackedBars = [];

  const createStackedBar = (answerData, filterTitle = null) =>
    answerData.reduce(
      (stackedBar, a) => {
        let value = 0;
        if (isPercentage) {
          if (a.percentage) {
            value = Math.round(parseFloat(a.percentage));
          }
        } else {
          value = a.y;
        }
        return {
          ...stackedBar,
          data: [
            ...stackedBar.data,
            {
              value,
              questionIndex: 0,
              answerIndex: a.x
            }
          ]
        };
      },
      { title: '', data: [], ...(filterTitle ? { filterTitle } : {}) }
    );

  if (isFiltered && unfiltered) {
    stackedBars = [
      createStackedBar(answers, `Selected N=(${selectedResultsCount})`),
      createStackedBar(
        unfiltered,
        filteringOnSelectionEnabled
          ? `Not selected (N=${totalResultsCount - selectedResultsCount})`
          : `Total sample (N=${totalResultsCount})`
      )
    ];
  } else {
    stackedBars = [createStackedBar(answers)];
  }

  const selectableAnswers = answers.map(a => a.question);

  const onBarClick = (_questionIndex, answerStringIndex) => {
    onAnswerBarClick({ x: answerStringIndex });
  };

  let sortingOrderArray = [];
  if (activeSorting) {
    sortingOrderArray = answers.map(a => a.x);
  }

  return (
    <div className={styles.wrapper}>
      <StackedBars
        stackedBars={stackedBars}
        isPercentage={isPercentage}
        isFiltered={isFiltered}
        selectableAnswers={selectableAnswers}
        showStackedAverageScores={showStackedAverageScores}
        onBarClick={onBarClick}
        sortingOrderArray={sortingOrderArray}
      />
    </div>
  );
};

export default McStackedBars;
