export const parseRichText = string => {
  let questionValue;
  try {
    questionValue = JSON.parse(string)
      .blocks.map(draftBlock => draftBlock.text)
      .join('\n');
  } catch (error) {
    questionValue = string;
  }
  return questionValue;
};

export const isRichText = a => {
  try {
    JSON.parse(a)
      .blocks.map(draftBlock => draftBlock.text)
      .join('\n');
    return true;
  } catch (error) {
    return false;
  }
};
