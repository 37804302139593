import React, { useState, useRef, useMemo } from 'react';
import Icon from '../../../../../../../Icon/Icon';
import Answer from './components/Answer/Answer';
import AddNewAnswer from './components/AddNewAnswer/AddNewAnswer';
import MultipleAnswersProperties from './components/MultipleAnswersProperties/MultipleAnswersProperties';

import {
  QUESTION_TYPES,
  DECISION_FRAMEWORK_TYPES,
  SURVEY_BUILDER_POPUP_TYPES
} from '../../../../../../../../helpers/constants';

import styles from './MCAnswers.module.css';
import IncidenceRateSelectors from './components/IncidenceRateSelectors/IncidenceRateSelectors';

const MCAnswers = props => {
  const {
    choices,
    isActiveQuestion,
    selectAtLeast,
    selectAtMost,
    randomOrder,
    allowOther,
    allowNoneOfTheAbove,
    onScreen,
    isAdmin,
    actions,
    question,
    surveyListQuestion,
    surveyLists,
    surveyStructure,
    notAllowedTextContentEdit,
    surveyPageRef,
    maxSurveyResponses,

    addDraftEmoji,
    setAddDraftEmoji,
    toggleDraftButton,
    setToggleDraftButton
  } = props;

  const isMatrixQuestion = question.type === QUESTION_TYPES.MATRIX;

  const ifQuotaIsActive =
    choices &&
    choices.length &&
    choices.filter(c => c.quota).length &&
    (selectAtLeast !== 1 || selectAtMost !== 1);

  const [choiceIndexToSelect, setChoiceIndexToSelect] = useState(null);

  const answersContainer = useRef();

  const otherAnswerIndex =
    (allowOther &&
      (!allowNoneOfTheAbove ? choices.length - 1 : choices.length - 2)) ||
    -1;
  const noneOfTheAboveAnswerIndex = allowNoneOfTheAbove
    ? choices.length - 1
    : -1;

  const lockedItems =
    randomOrder &&
    choices &&
    choices.length &&
    choices.map(
      (c, i) =>
        c.lockedPosition ||
        i === otherAnswerIndex ||
        i === noneOfTheAboveAnswerIndex ||
        false
    );
  const firstUnlockedIndex =
    randomOrder && lockedItems && lockedItems.indexOf(false);
  const lastUnlockedIndex =
    randomOrder && lockedItems && lockedItems.lastIndexOf(false);
  const firstLockedAnswerOffset =
    randomOrder &&
    answersContainer.current &&
    answersContainer.current.children &&
    answersContainer.current.children[firstUnlockedIndex] &&
    answersContainer.current.children[firstUnlockedIndex].offsetTop;
  let lastLockedAnswerOffset =
    randomOrder &&
    answersContainer.current &&
    answersContainer.current.children &&
    answersContainer.current.children[lastUnlockedIndex] &&
    answersContainer.current.children[lastUnlockedIndex].offsetTop;
  if (!lastLockedAnswerOffset && lastLockedAnswerOffset !== 0) {
    const refItemsLength =
      answersContainer &&
      answersContainer.current &&
      answersContainer.current.children &&
      answersContainer.current.children.length;
    const answerHeight =
      randomOrder &&
      answersContainer &&
      answersContainer.current &&
      answersContainer.current.children &&
      answersContainer.current.children[0] &&
      answersContainer.current.children[0].offsetHeight;
    if (refItemsLength && answerHeight) {
      lastLockedAnswerOffset = answerHeight * refItemsLength;
    }
  }

  // Randomized icon position
  const { shuffleHeight, showShuffle } = useMemo(
    () => {
      if (lockedItems && lockedItems.length >= 2) {
        const center =
          firstLockedAnswerOffset +
          (lastLockedAnswerOffset - firstLockedAnswerOffset) / 2;

        return {
          shuffleHeight: center + 8, // 8 is manual correction
          showShuffle: true
        };
      }
      return { shuffleHeight: 0, showShuffle: false };
    },
    [firstLockedAnswerOffset, lastLockedAnswerOffset, randomOrder]
  );

  let matrixLabel = 'Add concepts';
  let matrixAnswerLabel = 'Add new concept';
  if (question.matrix && question.matrix.decisionFrameworkType) {
    const { decisionFrameworkType } = question.matrix;

    if (decisionFrameworkType === DECISION_FRAMEWORK_TYPES.OPPORTUNITY_GRAPH) {
      matrixLabel = 'Add problems';
      matrixAnswerLabel = 'Add new problem';
    } else if (
      decisionFrameworkType === DECISION_FRAMEWORK_TYPES.IDEA_SELECTION_GRAPH
    ) {
      matrixLabel = 'Add ideas';
      matrixAnswerLabel = 'Add new idea';
    } else if (decisionFrameworkType === DECISION_FRAMEWORK_TYPES.SINGLE) {
      matrixLabel = 'Add rows';
      matrixAnswerLabel = 'Add new row';
    }
  }

  return (
    <div className={styles.blockAdditionalOptions} key="multiple-answers">
      {!question.isCommunityAssignQuestion && !isMatrixQuestion && (
        <MultipleAnswersProperties
          isRanking={question && question.rankAnswers}
          choices={choices}
          ifQuotaIsActive={ifQuotaIsActive}
          selectAtLeast={selectAtLeast}
          selectAtMost={selectAtMost}
          actions={actions}
          isActiveQuestion={isActiveQuestion}
          setActiveQuestion={() => actions.setActiveFlow(question)}
        />
      )}
      <span
        className={
          !isMatrixQuestion
            ? styles.descriptionTitle
            : `${styles.descriptionTitle} ${styles.matrixDescriptionTitle}`
        }
      >
        {!isMatrixQuestion ? 'Answers' : matrixLabel}
      </span>
      <div className="mc-question-answers-container">
        {randomOrder &&
          showShuffle &&
          firstUnlockedIndex !== lastUnlockedIndex && (
            <Icon
              type="shuffle-white"
              className={`${styles.randomisedIcon} fade-in`}
              style={{
                top: `calc(${shuffleHeight}px - ${1}px)`
              }}
            />
          )}
        <div ref={answersContainer}>
          {choices &&
            choices.map((choice, i) => (
              <Answer
                key={choice.id}
                choiceIndex={i}
                choices={choices}
                choice={choices[i]}
                isOtherAnswer={
                  allowOther &&
                  (!allowNoneOfTheAbove
                    ? choices.length - 1 === i
                    : choices.length - 2 === i)
                }
                isNoneOfTheAboveAnswer={
                  allowNoneOfTheAbove && choices.length - 1 === i
                }
                randomOrder={randomOrder}
                isActiveQuestion={isActiveQuestion}
                isAdmin={isAdmin}
                onScreen={onScreen}
                actions={actions}
                questionId={question.id}
                rankAnswers={question.rankAnswers}
                selectAtMost={question.selectAtMost}
                allowOther={question.allowOther}
                allowNoneOfTheAbove={question.allowNoneOfTheAbove}
                isCommunityAssignQuestion={question.isCommunityAssignQuestion}
                surveyListQuestion={surveyListQuestion}
                surveyLists={surveyLists}
                surveyStructure={surveyStructure}
                choiceIndexToSelect={choiceIndexToSelect}
                setChoiceIndexToSelect={setChoiceIndexToSelect}
                isFocused={choiceIndexToSelect === i}
                isLastAnswer={i === choices.length - 1}
                otherAnswerIndex={otherAnswerIndex}
                noneOfTheAboveAnswerIndex={noneOfTheAboveAnswerIndex}
                notAllowedTextContentEdit={notAllowedTextContentEdit}
                surveyPageRef={surveyPageRef}
                isMatrixQuestion={isMatrixQuestion}
                decisionFrameworkType={
                  (question.matrix && question.matrix.decisionFrameworkType) ||
                  null
                }
                minQuotaExceedsTotalSample={
                  maxSurveyResponses &&
                  choice.quotaMinAmount &&
                  choices.reduce((total, c) => {
                    if (c.quotaType && c.quotaMinAmount) {
                      return total + c.quotaMinAmount;
                    }
                    return total;
                  }, 0) > maxSurveyResponses
                }
                addDraftEmoji={addDraftEmoji}
                setAddDraftEmoji={setAddDraftEmoji}
                toggleDraftButton={toggleDraftButton}
                setToggleDraftButton={setToggleDraftButton}
              />
            ))}
        </div>
      </div>
      {!question.isCommunityAssignQuestion && (
        <AddNewAnswer
          allowOther={allowOther}
          allowNoneOfTheAbove={allowNoneOfTheAbove}
          questionId={question.id}
          actions={actions}
          setChoiceIndexToSelect={setChoiceIndexToSelect}
          choicesLength={choices ? choices.length : 0}
          otherAnswerIndex={otherAnswerIndex}
          noneOfTheAboveAnswerIndex={noneOfTheAboveAnswerIndex}
          notAllowedTextContentEdit={notAllowedTextContentEdit}
          isMatrixQuestion={isMatrixQuestion}
          matrixAnswerLabel={matrixAnswerLabel}
          decisionFrameworkType={
            (question.matrix && question.matrix.decisionFrameworkType) || null
          }
          matrixLimitWarningPopupType={
            SURVEY_BUILDER_POPUP_TYPES.MATRIX_OPTIONS_LIMIT_WARNING
          }
        />
      )}
      {isActiveQuestion ? (
        <IncidenceRateSelectors actions={actions} question={question} />
      ) : null}
    </div>
  );
};

export default MCAnswers;
