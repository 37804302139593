import React from 'react';

import MCAnswers from './components/MCAnswers/MCAnswers';
import Slider from './components/Slider/Slider';
import OpenQuestion from './components/OpenQuestion/OpenQuestion';
import Iframe from './components/Iframe/Iframe';
import Distributor from './components/Distributor/Distributor';
import Matrix from './components/Matrix/Matrix';

import { QUESTION_TYPES } from '../../../../../../helpers/constants';

export default ({
  question,
  isActiveQuestion,
  isAdmin,
  onScreen,
  actions,
  surveyListQuestion,
  surveyLists,
  surveyStructure,
  maxSurveyResponses,
  notAllowedTextContentEdit,
  surveyPageRef,
  surveyLanguage,

  addDraftEmoji,
  setAddDraftEmoji,
  toggleDraftButton,
  setToggleDraftButton
}) => {
  if (question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
    return (
      <MCAnswers
        choices={question.choices}
        isActiveQuestion={isActiveQuestion}
        selectAtLeast={question.selectAtLeast}
        selectAtMost={question.selectAtMost}
        randomOrder={question.randomOrder}
        allowOther={question.allowOther}
        allowNoneOfTheAbove={question.allowNoneOfTheAbove}
        isAdmin={isAdmin}
        onScreen={onScreen}
        actions={actions}
        question={question}
        surveyListQuestion={surveyListQuestion}
        surveyLists={surveyLists}
        surveyStructure={surveyStructure}
        notAllowedTextContentEdit={notAllowedTextContentEdit}
        surveyPageRef={surveyPageRef}
        addDraftEmoji={addDraftEmoji}
        setAddDraftEmoji={setAddDraftEmoji}
        toggleDraftButton={toggleDraftButton}
        setToggleDraftButton={setToggleDraftButton}
        maxSurveyResponses={maxSurveyResponses}
      />
    );
  }

  if (question.type === QUESTION_TYPES.RATING_SCALE) {
    return (
      <Slider
        isActiveQuestion={isActiveQuestion}
        minValue={question.minValue}
        maxValue={question.maxValue}
        branches={question.branches}
        actions={actions}
        question={question}
        surveyListQuestion={surveyListQuestion}
        surveyLists={surveyLists}
        surveyStructure={surveyStructure}
      />
    );
  }

  if (question.type === QUESTION_TYPES.OPEN_QUESTION) {
    return (
      <OpenQuestion
        minCharacters={question.minCharacters}
        maxCharacters={question.maxCharacters}
        actions={actions}
      />
    );
  }

  if (question.type === QUESTION_TYPES.IFRAME) {
    return (
      <Iframe question={question} actions={actions} value={question.source} />
    );
  }

  if (question.type === QUESTION_TYPES.DISTRIBUTOR) {
    return (
      <Distributor
        branches={question.branches}
        isActiveQuestion={isActiveQuestion}
        actions={actions}
        question={question}
        surveyListQuestion={surveyListQuestion}
        surveyLists={surveyLists}
        surveyStructure={surveyStructure}
        maxSurveyResponses={maxSurveyResponses}
      />
    );
  }

  if (question.type === QUESTION_TYPES.MATRIX) {
    return (
      <>
        <Matrix
          question={question}
          actions={actions}
          isActiveQuestion={isActiveQuestion}
          addDraftEmoji={addDraftEmoji}
          setAddDraftEmoji={setAddDraftEmoji}
          toggleDraftButton={toggleDraftButton}
          setToggleDraftButton={setToggleDraftButton}
          surveyLanguage={surveyLanguage}
        />
        <MCAnswers
          choices={
            question.choices && question.choices.length
              ? question.choices.filter(
                  choice =>
                    question.matrix &&
                    choice.decisionFrameworkType &&
                    question.matrix.decisionFrameworkType &&
                    choice.decisionFrameworkType ===
                      question.matrix.decisionFrameworkType
                )
              : []
          }
          isActiveQuestion={isActiveQuestion}
          selectAtLeast={question.selectAtLeast}
          selectAtMost={question.selectAtMost}
          randomOrder={question.randomOrder}
          allowOther={question.allowOther}
          allowNoneOfTheAbove={question.allowNoneOfTheAbove}
          isAdmin={isAdmin}
          onScreen={onScreen}
          actions={actions}
          question={question}
          surveyListQuestion={surveyListQuestion}
          surveyLists={surveyLists}
          surveyStructure={surveyStructure}
          notAllowedTextContentEdit={notAllowedTextContentEdit}
          surveyPageRef={surveyPageRef}
          addDraftEmoji={addDraftEmoji}
          setAddDraftEmoji={setAddDraftEmoji}
          toggleDraftButton={toggleDraftButton}
          setToggleDraftButton={setToggleDraftButton}
        />
      </>
    );
  }
  return null;
};
