import React, { useState, useRef } from 'react';

import QuestionChoiceTagging from './components/QuestionChoiceTagging/QuestionChoiceTagging';

import styles from './QuestionChoicesTagging.module.css';
import isActionAllowed from '../../../../helpers/isActionAllowed/isActionAllowed';

import tickIcon from '../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../assets/img/delete.svg';

import { QUESTION_DESIGNER_ACTION } from '../../../../helpers/constants';

export default ({ api, actions, surveyBuilderPopup }) => {
  const questionChoices =
    surveyBuilderPopup.question && surveyBuilderPopup.question.choices
      ? JSON.parse(JSON.stringify(surveyBuilderPopup.question.choices))
      : [];
  const [choices, setChoices] = useState(questionChoices);
  const [newSessionValues, setNewSessionValues] = useState({
    category: [],
    tagName: [],
    tagValue: []
  });

  const {
    question,
    surveyLists,
    surveyLanguage,
    surveyStructure
  } = surveyBuilderPopup;
  const questionInSurveyList = surveyLists.find(sL => sL.id === question.id);

  const optionsContainerRef = useRef();

  const displayBlockQuestion = blockQuestion => {
    try {
      return JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n\r');
    } catch (error) {
      return blockQuestion;
    }
  };

  const onConfirm = () => {
    choices.forEach(c => {
      actions.updateQuestionChoice(question.id, c, false);
    });

    const profiling = {
      ...(question.profiling || {}),
      isProfilingQuestion: true,
      isReusedProfilingQuestion: false,
      answerCollected: false,
      visibleForSearch: true,
      language: surveyLanguage || 'Dutch',
      id: question.id
    };

    actions.updateQuestionProperty('profiling', profiling);

    const questionToSetAsActiveFlow = {
      ...question,
      choices,
      profiling
    };

    actions.setActiveFlow(questionToSetAsActiveFlow);

    actions.toggleSurveyBuilderPopup(null);
  };

  const isAllowedToEditTagsForThisQuestion = isActionAllowed(
    QUESTION_DESIGNER_ACTION.PROFILE_QUESTION_TAGGING,
    questionInSurveyList,
    null,
    surveyLists,
    surveyStructure
  );

  const isConfirmVisible = () => {
    const choicesWithMissingProperties = choices.filter(
      c =>
        c.profilingTag &&
        (c.profilingTag.category ||
          c.profilingTag.tagName ||
          c.profilingTag.tagValue) &&
        (!c.profilingTag.category ||
          !c.profilingTag.tagName ||
          !c.profilingTag.tagValue)
    );

    const choicesWithCompleteSelectedProperties = choices.filter(
      c =>
        c.profilingTag &&
        c.profilingTag.category &&
        c.profilingTag.tagName &&
        c.profilingTag.tagValue
    );

    return (
      choicesWithCompleteSelectedProperties.length &&
      (!choicesWithMissingProperties ||
        choicesWithMissingProperties.length === 0) &&
      (isAllowedToEditTagsForThisQuestion &&
        isAllowedToEditTagsForThisQuestion.allowed)
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.top}>
          <div className={styles.question}>
            {questionInSurveyList.formattedGlobalIndex}.{' '}
            {displayBlockQuestion(question.question)}
          </div>
          <div className={styles.questionDescription}>
            Please set desired profiling tag for the following answer options
          </div>
        </div>

        <div ref={optionsContainerRef}>
          {choices.map((c, i) => (
            <QuestionChoiceTagging
              key={`question-choice-tagging-${i.toString()}`}
              api={api}
              formattedGlobalIndex={questionInSurveyList.formattedGlobalIndex}
              choice={c}
              choiceIndex={i}
              choices={choices}
              setChoices={setChoices}
              language={surveyLanguage}
              newSessionValues={newSessionValues}
              setNewSessionValues={setNewSessionValues}
              isAllowedToEditTagsForThisQuestion={
                isAllowedToEditTagsForThisQuestion
              }
              displayBlockQuestion={displayBlockQuestion}
            />
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {isConfirmVisible() ? (
            <img
              className={`${
                styles.confirm
              } workspaces-manage-workspace-confirm-button`}
              src={tickIcon}
              alt="Confirm icon"
              onClick={() => onConfirm()}
              role="presentation"
            />
          ) : null}
          <img
            className={styles.close}
            src={closeIcon}
            alt="Close icon"
            onClick={() => actions.toggleSurveyBuilderPopup(null)}
            role="presentation"
          />
        </div>
      </div>
    </div>
  );
};
