import React from 'react';

import Dropdown from './components/Dropdown/Dropdown';

import styles from './LoversHaters.module.css';
import {
  LOVERS_HATERS_FILTER_LABELS,
  LOVERS_HATERS_GRAPH_TYPES,
  LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION
} from '../../helpers/constants';
import questionMark from '../../../../../../../assets/img/accountmanagement/am-questionmark.svg';

const LoversHaters = props => {
  const {
    activeLoversHaters,
    setActiveLoversHaters,
    features,
    dataPointColors,
    selectedFeature,
    setSelectedFeature,
    selectedFilter,
    setSelectedFilter,
    selectedExtremeLoversHatersGraphTypes,
    setSelectedExtremeLoversHatersGraphTypes,
    applySurveyFilter,
    configureViewAll,
    removeGraphActiveFilter,
    setActiveInsightId
  } = props;

  /*
  useEffect(
    () => {
      // TODO: WHY THIS?
      if (!activeLoversHaters && selectedFeature && selectedFilter) {
        setSelectedFeature();
        setSelectedFilter();
      }
    },
    [activeLoversHaters]
  );
  */

  const getDropdown = (
    subtitle,
    placeholder,
    options,
    selectedOption,
    onSelectCompleted,
    tooltip
  ) => (
    <div className={styles.dropdownWrapper}>
      <div className={styles.subtitle}>
        {subtitle}
        {tooltip ? (
          <div className={styles.touchpointsTooltipContainer}>
            <img
              className={styles.touchpointsTooltipIcon}
              alt="Touchpoints tooltip"
              src={questionMark}
            />
            <div className={styles.touchpointsTooltipPopup}>{tooltip}</div>
          </div>
        ) : null}
      </div>
      <Dropdown
        options={options}
        selectedOption={selectedOption}
        onSelectCompleted={onSelectCompleted}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      {getDropdown(
        'What do you want to do?',
        'Please select a value',
        [
          {
            id: LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS,
            description:
              LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION[
                LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS
              ]
          },
          {
            id: LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS,
            description:
              LOVERS_HATERS_GRAPH_TYPES_DESCRIPTION[
                LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
              ]
          }
        ],
        selectedExtremeLoversHatersGraphTypes,
        value => {
          if (
            value &&
            value.id &&
            value.id !== selectedExtremeLoversHatersGraphTypes.id
          ) {
            setSelectedExtremeLoversHatersGraphTypes(value);
            setSelectedFeature(null);
            setSelectedFilter(null);
            setActiveInsightId(null);

            if (
              value &&
              value.id === LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS
            ) {
              removeGraphActiveFilter();
            }
          }
        }
      )}
      {selectedExtremeLoversHatersGraphTypes &&
      selectedExtremeLoversHatersGraphTypes.id ===
        LOVERS_HATERS_GRAPH_TYPES.FILTER_ON_SPECIFIC_POINTS ? (
        <>
          {getDropdown(
            'Data point',
            'Select the value you would like to filter on',
            features.map((feature, index) => ({
              ...feature,
              color: dataPointColors[index],
              featureIndex: index
            })),
            selectedFeature,
            value => {
              setSelectedFeature(value);
              setActiveLoversHaters({ ...activeLoversHaters, ...value });
              applySurveyFilter({ ...activeLoversHaters, ...value }, true);
              setActiveInsightId(null);
            }
          )}
          {getDropdown(
            'Filter',
            'Select the filter you would like to use',
            Object.entries(LOVERS_HATERS_FILTER_LABELS).map(([key, value]) => ({
              id: key,
              description: value
            })),
            selectedFilter,
            value => {
              setSelectedFilter(value);
              setActiveLoversHaters({
                ...activeLoversHaters,
                filter: value.id
              });
              applySurveyFilter({ ...activeLoversHaters, filter: value.id });
              setActiveInsightId(null);
            }
          )}
        </>
      ) : null}
      {selectedExtremeLoversHatersGraphTypes &&
      selectedExtremeLoversHatersGraphTypes.id ===
        LOVERS_HATERS_GRAPH_TYPES.VIEW_ALL_DATA_POINTS ? (
        <>
          {getDropdown(
            'Which lovers/haters do you want to view for all datapoints?',
            'Select the view you would like to see',
            Object.entries(LOVERS_HATERS_FILTER_LABELS).map(([key, value]) => ({
              id: key,
              description: value
            })),
            selectedFilter,
            value => {
              setSelectedFilter(value);
              setActiveLoversHaters({
                ...activeLoversHaters,
                filter: value.id
              });
              configureViewAll(false);
              setActiveInsightId(null);
            },
            'This will give you the view as if you would filter on each data point separately, combined in one framework'
          )}
        </>
      ) : null}
    </div>
  );
};

export default LoversHaters;
