import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import styles from './FlowNavigation.module.css';

import { SURVEY_END_TYPES, QUESTION_TYPES } from '../../helpers/constants';
import getBlockQuestion from '../../helpers/getBlockQuestion';

import randomiseIcon from '../../../../../../assets/img/randomize-active.svg';
import {
  getGroupFirstFlow,
  getGroupLastFlow
} from '../../helpers/getGroupEdgeFlows/getGroupEdgeFlows';

const MAX_ZOOM_LEVEL = 1.5;
const MIN_ZOOM_LEVEL = 0.5;

const ngramInsert = input => {
  const chuncks = input.match(/.{1,50}/g);
  return chuncks ? chuncks.join('\\\n') : '';
};

const truncateString = (label, maxLength) => {
  if (!label) return '';
  if (label.length > maxLength) {
    const truncated = label.substring(0, maxLength);
    return `${truncated}...`;
  }
  return label;
};

export default ({
  width,
  height,
  onQuestionClick,
  surveyLists,
  content,
  questions,
  activeFlowId,
  showQuestionIds
}) => {
  const [svg, setSvg] = useState(null);
  const [g, setG] = useState(null);
  const [chartTransform, setChartTransform] = useState({});

  // Did mount
  useEffect(() => {
    const data = prepareDataForChart();
    drawChart(svg, g, data);
  }, []);

  // Did update
  useEffect(
    () => {
      const data = prepareDataForChart();
      drawChart(svg, g, data, true);
    },
    [svg, surveyLists, width, activeFlowId]
  );

  const prepareDataForChart = () => {
    const endSurveyBlock = {
      label: 'End survey',
      type: SURVEY_END_TYPES.SUCCESSFUL,
      id: SURVEY_END_TYPES.SUCCESSFUL
    };
    const endFailedSurveyBlock = {
      label: 'Survey failed',
      type: SURVEY_END_TYPES.NOT_ELIGIBLE,
      id: SURVEY_END_TYPES.NOT_ELIGIBLE
    };
    let failedBlockAdded = false;

    const outputData = {
      states: [],
      edges: [],
      groups: [],
      parents: [],
      parentGroups: []
    };

    const groups = [];

    const addEdge = (start, end, label, mergeLabel) => {
      const edge = { start: null, end: null, label: '' };
      if (start) {
        edge.start = start;
      }
      if (end) {
        edge.end = end;
      }
      if (label) edge.label = label;

      const existingEdge = outputData.edges.find(
        eEdge =>
          end && start && eEdge && eEdge.start === start && eEdge.end === end
      );
      if (!existingEdge && edge && edge.start && edge.end) {
        outputData.edges.push(edge);
      }

      if (existingEdge && mergeLabel) {
        existingEdge.label = `${existingEdge.label}\n${label}`;
      }
    };

    const getQuestionString = questionBlock => {
      if (!questionBlock.globalIndex) {
        return '';
      }

      let questionText = questionBlock.question
        ? getBlockQuestion(questionBlock.question)
        : null;
      if (
        questionBlock.type === QUESTION_TYPES.MATRIX &&
        questionBlock.matrix &&
        questionBlock.matrix.xQuestion &&
        questionBlock.matrix.yQuestion
      ) {
        questionText = `${getBlockQuestion(
          questionBlock.matrix.xQuestion
        )}\n${getBlockQuestion(questionBlock.matrix.yQuestion)}`;
      }

      if (showQuestionIds) {
        return questionText
          ? `${questionBlock.id &&
              questionBlock.id.toString()} - ${questionBlock.formattedGlobalIndex.toString()}. ${questionText}`
          : `${questionBlock.id &&
              questionBlock.id.toString()} - ${questionBlock.formattedGlobalIndex.toString()}.`;
      }

      return questionText
        ? `${questionBlock.formattedGlobalIndex.toString()}. ${questionText}`
        : `${questionBlock.formattedGlobalIndex.toString()}.`;
    };

    const getJumps = (element, jumpFromFlow, label, mergeLabel) => {
      // CHECK IF THIS NEXT FLOW IS GROUP
      if (element.nextFlow) {
        let { nextFlow } = element;
        if (groups.indexOf(element.nextFlow) > -1) {
          nextFlow = getGroupFirstFlow(
            content,
            content.groups.find(gr => gr.id === element.nextFlow)
          );
        }
        addEdge(jumpFromFlow, nextFlow, label, mergeLabel);
        return true;
      }
      if (element.end) {
        if (element.end === SURVEY_END_TYPES.SUCCESSFUL) {
          addEdge(jumpFromFlow, endSurveyBlock.id, label);
          return true;
        }
        if (element.end === SURVEY_END_TYPES.NOT_ELIGIBLE) {
          addEdge(jumpFromFlow, endFailedSurveyBlock.id, label);
          failedBlockAdded = true;
          return true;
        }
      }
      return false;
    };

    // States (blocks) - Survey questions (flows)
    const newStates = surveyLists.map(flowBlock => {
      if (flowBlock) {
        if (flowBlock.type === QUESTION_TYPES.DISTRIBUTOR) {
          return {
            label: 'Distributor',
            type: 'distributor',
            id: flowBlock.id
          };
        }

        return {
          label: getQuestionString(flowBlock),
          type: 'question',
          id: flowBlock.id
        };
      }
      return null;
    });
    outputData.states = [...newStates, endSurveyBlock];

    // Groups
    let groupsTemp = [];
    content.groups.forEach(group => {
      // If it is parent group it should go to the top of this array
      const parentGroups = content.groups.filter(
        gr => gr.flows.indexOf(group.id) > -1
      );

      groups.push(group.id);

      if (parentGroups && parentGroups.length) {
        // Child group
        groupsTemp.push({
          label: group.label || '',
          id: group.id
        });
      }

      if (!parentGroups || parentGroups.length === 0) {
        // Parent group
        groupsTemp = [
          {
            label: group.label || '',
            id: group.id
          },
          ...groupsTemp
        ];
      }
    });

    outputData.groups = groupsTemp;

    // Group nesting
    content.groups.forEach(group => {
      group.flows.forEach(groupFlow => {
        if (
          groups.indexOf(groupFlow) > -1 &&
          outputData.parentGroups.indexOf(group.id) === -1
        ) {
          outputData.parentGroups.push(group.id);
        }

        outputData.parents.push({
          question: groupFlow,
          group: group.id
        });
      });

      // Group jumps
      const groupLastFlow = getGroupLastFlow(content, group);
      // It should not be possible to add this jumps to last question, but better to have this check here
      if (groupLastFlow && (!groupLastFlow.nextFlow || !groupLastFlow.end)) {
        getJumps(group, groupLastFlow);
      }
    });

    // Question / flow edges
    surveyLists.forEach((flowBlock, index) => {
      const questionBlock = questions[flowBlock.id];

      if (flowBlock.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
        if (questionBlock.choices && questionBlock.choices.length) {
          questionBlock.choices.forEach((choice, choiceIndex) => {
            getJumps(
              choice,
              questionBlock.id,
              `${choiceIndex + 1}. ${getBlockQuestion(choice.answer)}`,
              true
            );
          });
          if (
            !questionBlock.choices.some(
              choice => !choice.nextFlow && !choice.end
            )
          ) {
            return;
          }
        }
      }

      if (flowBlock.type === QUESTION_TYPES.RATING_SCALE) {
        if (questionBlock.branches && questionBlock.branches.length) {
          questionBlock.branches.forEach(choice => {
            getJumps(choice, questionBlock.id, choice.answer);
          });
          // Check if there is a part of a range not covered with jump
          let rangeNotUsed = false;
          if (
            questionBlock.minValue ||
            questionBlock.minValue === 0 ||
            (questionBlock.maxValue || questionBlock.maxValue === 0)
          ) {
            const availableRange =
              questionBlock.maxValue - questionBlock.minValue;

            const totalRangeValues = questionBlock.branches.reduce((acc, r) => {
              if (
                r &&
                r.range &&
                ((r.range && (r.range.min || r.range.min === 0)) ||
                  (r.range.max || r.range.max === 0))
              ) {
                return acc + (r.range.max - r.range.min + 1);
              }
              return acc;
            }, 0);

            if (availableRange - (totalRangeValues - 1) > 0) {
              rangeNotUsed = true;
            }
          }

          if (
            !questionBlock.branches.some(
              choice => !choice.nextFlow && !choice.end
            ) &&
            !rangeNotUsed
          ) {
            return;
          }
        }
      }

      if (flowBlock.type === QUESTION_TYPES.DISTRIBUTOR) {
        if (questionBlock.branches && questionBlock.branches.length) {
          const anyBranchWithJump = questionBlock.branches.some(
            b => b.nextFlow || b.end
          );
          if (anyBranchWithJump) {
            questionBlock.branches.forEach(branch => {
              getJumps(branch, questionBlock.id);
            });
            return;
          }
        }
      }

      if (flowBlock.nextFlow || flowBlock.end) {
        const isEdgeAdded = getJumps(flowBlock, flowBlock.id);
        if (isEdgeAdded) {
          return;
        }
      }

      // If question is the last position in group with group jump
      if (
        surveyLists[index] &&
        surveyLists[index].additionalInformation &&
        (surveyLists[index].additionalInformation.lastGroupQuestion &&
          surveyLists[index].group &&
          ((surveyLists[index].group.nestingLevel === 1 &&
            (surveyLists[index].group.nextFlow ||
              surveyLists[index].group.end)) ||
            (surveyLists[index].group.nestingLevel === 2 &&
              surveyLists[index].group.additionalInformation &&
              surveyLists[index].group.additionalInformation
                .nestedBottomEdgeGroup &&
              (surveyLists[index].group.parentGroup.nextFlow ||
                surveyLists[index].group.parentGroup.end))))
      ) {
        return;
      }

      if (surveyLists[index + 1]) {
        addEdge(flowBlock.id, surveyLists[index + 1].id);
      }
    });

    let hideEndSurveyBlock = false;

    // The end survey block should not be hidden if a jump exists to end survey
    let endSurveyFlowExists = false;
    const surveyListsLength = surveyLists.length;

    for (let i = 0; i < surveyListsLength && !endSurveyFlowExists; i += 1) {
      const flowBlock = surveyLists[i];
      if (
        flowBlock &&
        flowBlock.end &&
        flowBlock.end === SURVEY_END_TYPES.SUCCESSFUL
      ) {
        endSurveyFlowExists = true;
      }
      if (
        !endSurveyFlowExists &&
        flowBlock &&
        flowBlock.choices &&
        flowBlock.choices.length &&
        flowBlock.choices.find(
          choice => choice.end && choice.end === SURVEY_END_TYPES.SUCCESSFUL
        )
      ) {
        endSurveyFlowExists = true;
      }
    }

    // Last question with logic jumps
    if (
      !endSurveyFlowExists &&
      surveyLists[surveyLists.length - 1] &&
      (surveyLists[surveyLists.length - 1].nextFlow ||
        surveyLists[surveyLists.length - 1].end)
    ) {
      hideEndSurveyBlock = true;
    }

    // Last child group with logic jumps
    if (
      !endSurveyFlowExists &&
      surveyLists[surveyLists.length - 1] &&
      surveyLists[surveyLists.length - 1].group &&
      !surveyLists[surveyLists.length - 1].group.jumpFromDistributor &&
      (surveyLists[surveyLists.length - 1].group.nextFlow ||
        surveyLists[surveyLists.length - 1].group.end)
    ) {
      hideEndSurveyBlock = true;
    }

    // Last parent group with logic jumps
    if (
      !endSurveyFlowExists &&
      surveyLists[surveyLists.length - 1] &&
      surveyLists[surveyLists.length - 1].group &&
      surveyLists[surveyLists.length - 1].group.parentGroup &&
      (surveyLists[surveyLists.length - 1].group.parentGroup.nextFlow ||
        surveyLists[surveyLists.length - 1].group.parentGroup.end)
    ) {
      hideEndSurveyBlock = true;
    }

    if (!hideEndSurveyBlock) {
      if (surveyLists[surveyLists.length - 1]) {
        addEdge(surveyLists[surveyLists.length - 1].id, endSurveyBlock.id);
      }
    } else {
      outputData.states = outputData.states.filter(
        s => s.type !== SURVEY_END_TYPES.SUCCESSFUL
      );
    }

    // If there is a failed block show it
    if (failedBlockAdded) {
      outputData.states = [...outputData.states, endFailedSurveyBlock];
    }

    return outputData;
  };

  const drawChart = (svgRef, gRef, chartData, horizontalCenter) => {
    try {
      if (svgRef && gRef) {
        if (chartData) {
          // Create a new directed graph
          const gFlow = new dagreD3.graphlib.Graph({ compound: true })
            .setGraph({})
            .setDefaultEdgeLabel(() => ({}));

          // Automatically label each of the nodes
          chartData.states.forEach(state => {
            if (state.type === SURVEY_END_TYPES.SUCCESSFUL) {
              gFlow.setNode(state.id, {
                label: ngramInsert(state.label),
                shape: 'ellipse',
                style: 'fill: #ffffff; stroke: #9B9B9B; stroke-width: 0.25px;'
              });
              return;
            }
            if (state.type === SURVEY_END_TYPES.NOT_ELIGIBLE) {
              gFlow.setNode(state.id, {
                label: ngramInsert(state.label),
                shape: 'ellipse',
                style: 'fill: #ffffff; stroke: #9B9B9B; stroke-width: 0.25px;'
              });
              return;
            }
            if (state.type === 'distributor') {
              gFlow.setNode(state.id, {
                label: ngramInsert(state.label),
                shape: 'diamond',
                style:
                  activeFlowId === state.id
                    ? 'fill: #ffffff; stroke: #5300f2; stroke-width: 2px;'
                    : 'fill: #ffffff; stroke: #9B9B9B; stroke-width: 0.25px;',
                class: `${state.id} flow-chart-question-type ${
                  activeFlowId === state.id ? 'active-node' : ''
                }`
              });
              return;
            }
            gFlow.setNode(state.id, {
              label: ngramInsert(state.label),
              style:
                activeFlowId === state.id
                  ? 'stroke: #5300f2; stroke-width: 2px;'
                  : 'stroke: #9B9B9B; stroke-width: 0.25px;',
              class: `${state.id} flow-chart-question-type ${
                activeFlowId === state.id ? 'active-node' : ''
              }`
            });
          });

          // Groups
          chartData.groups.forEach(group =>
            gFlow.setNode(group.id, {
              style:
                activeFlowId === group.id
                  ? 'fill: #d3d7e8; stroke: #5300f2; stroke-width: 2px;'
                  : 'fill: #d3d7e8',
              label: truncateString(group.label, 25),
              clusterLabelPos: 'top'
            })
          );

          chartData.parents.forEach(parent =>
            gFlow.setParent(parent.question, parent.group)
          );

          // Style parent groups
          gFlow.nodes().forEach(v => {
            if (chartData.parentGroups.indexOf(v) > -1) {
              const node = gFlow.node(v);
              node.style = 'fill: #e4e6f0';
              node.clusterLabelPos = 'top';
            }
          });

          // Set up the edges
          chartData.edges.forEach(edge => {
            if (edge) {
              gFlow.setEdge(edge.start, edge.end, {
                label: edge.label,
                style: 'stroke: #4a34e9; stroke-width: 1.5px;',
                arrowhead: 'undirected'
              });
            }
          });

          // Set some general styles
          gFlow.nodes().forEach(v => {
            const node = gFlow.node(v);
            node.rx = 5;
            node.ry = 5;
          });

          const gd3Ref = d3.select(gRef);
          const svgd3Ref = d3.select(svgRef);

          gd3Ref.selectAll('*').remove();

          // Set up zoom support
          const zoom = d3
            .zoom()
            .scaleExtent([MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL])
            .on('zoom', () => {
              setChartTransform(d3.event.transform);
              gd3Ref.attr('transform', d3.event.transform);
            });
          svgd3Ref.call(zoom);

          // Run the renderer. This is what draws the final graph.
          const render = new dagreD3.render();
          render(gd3Ref, gFlow);

          // Set height
          svgd3Ref.attr('height', gFlow.graph().height + 40);

          // Move group label
          svgd3Ref
            .selectAll('g.clusters g.cluster g.label')
            .attr('transform', 'translate(0, -20)');

          // Center the graph
          if (horizontalCenter) {
            const scale =
              chartTransform.k || chartTransform.k === 0 ? chartTransform.k : 1;
            svgd3Ref.call(
              zoom.transform,
              d3.zoomIdentity
                .translate(
                  (width - gFlow.graph().width * scale) / 2,
                  chartTransform.y || 20
                )
                .scale(scale)
            );
            svgd3Ref.attr('height', gFlow.graph().height * scale + 40);
          }

          // Question click
          svgd3Ref.selectAll('.flow-chart-question-type').on('click', d => {
            const question = questions[d];

            if (question) {
              onQuestionClick(question);
            }
          });

          // Randomised group icon
          const allGroups = svgd3Ref.selectAll('.cluster');

          // Function required because "this"
          /* eslint-disable */
          allGroups.each(function(d) {
            const group = content.groups.find(cG => cG.id === d);

            if (group && group.randomized) {
              const groupCluster = this.getBBox(); // TODO: Not working
              const iconXPosition = groupCluster.width / 2 - 5;
              const iconYPosition = groupCluster.height / 2 - 15;
              d3.select(this)
                .append('image')
                .attr('width', 20)
                .attr('height', 20)
                .attr('xlink:href', randomiseIcon)
                .attr(
                  'transform',
                  `translate(-${iconXPosition}, -${iconYPosition})`
                );
            }
          });
          /* eslint-enable */

          // Zoom controls
          d3.select('.flow-chart-zoomin').on('click', () =>
            zoom.scaleBy(svgd3Ref.transition().duration(750), 1.2)
          );
          d3.select('.flow-chart-zoomout').on('click', () =>
            zoom.scaleBy(svgd3Ref.transition().duration(750), 0.8)
          );
        } else {
          d3.select('svg > g > *').remove();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!surveyLists || !surveyLists.length) {
    return (
      <div className={styles.emptyStateDescriptionContainer}>
        <div>
          No logic map available yet. <br />
          Get started by adding a question.
        </div>
      </div>
    );
  }

  return (
    <div>
      <svg
        style={{ width: `${width}px`, height: `${height}px` }}
        ref={elem => {
          if (elem) {
            setSvg(elem);
          }
        }}
      >
        <g
          ref={elem => {
            if (elem) {
              setG(elem);
            }
          }}
        />
      </svg>
      <div className="flow-chart-zoom-control-container">
        <div className="flow-chart-zoom-control flow-chart-zoomin">+</div>
        <div className="flow-chart-zoom-control flow-chart-zoomout">-</div>
      </div>
    </div>
  );
};
