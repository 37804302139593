import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import qs from 'query-string';
import styles from './CampaignQuestionsPage.module.css';

import Answers, { isOtherFieldVisible } from './Blocks/Answers/Answers';
import IFrame from './Blocks/IFrame';
import Input from './Blocks/Input';
import VideoPlayer from './Blocks/VideoPlayer/VideoPlayer';
import Slider from './Blocks/Slider/Slider';
import Message from './Blocks/Message/Message';
import Matrix from './Blocks/Matrix/Matrix';
import Draft from './Blocks/Draft/Draft';
import Loader from '../../../../base/components/Loader/Loader';
import Footer from '../Footer/Footer';
import ImagePopup from './Blocks/Answers/components/ImagePopup/ImagePopup';

import imageNotFoundIcon from '../../../../assets/img/image-not-found.svg';
import zoomInIcon from '../../../../assets/img/zoom-in.svg';

import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../clients/surveys/helpers/constants';

import { DECISION_FRAMEWORK_TYPES } from '../../../../helpers/constants';

import {
  GET_RESULT,
  SUBMIT_IFRAME_BLOCK,
  SUBMIT_OPEN_QUESTION_BLOCK,
  SUBMIT_SLIDER_BLOCK,
  SUBMIT_VIDEO_BLOCK,
  SUBMIT_MESSAGE_BLOCK,
  SUBMIT_MC_QUESTION,
  SUBMIT_MATRIX_BLOCK
} from '../../../../graphql/Campaigns';
import useUserback from '../../../../hooks/useUserback';

export default props => {
  const {
    setCampaignComponents,
    campaign,
    block,
    result,
    location: { search },
    strings,
    match,
    history,
    setHideBufflLogo,
    setCustomThemeBaseColor,
    setCustomThemeImage,
    userEmailSuggestion,
    setUserEmailSuggestion
  } = props;

  useUserback({
    ...match.params
  });

  let enableNextAfterSeconds = null;

  const [valid, setValid] = useState(false);
  const [values, setQuestionValues] = useState(null);
  const [randomAnswerValues, setRandomAnswerValues] = useState(null);
  const [startedAt, setStartedAt] = useState(null);
  const [valuesChanged, setValuesChanged] = useState(false);
  const [skipQuestion, setSkipQuestion] = useState(false);
  const [loadingQuestionImageFailed, setLoadingQuestionImageFailed] = useState(
    false
  );
  const [naOptionSelected, setNaOptionSelected] = useState(false);
  const [displayImagePopup, setDisplayImagePopup] = useState({
    visible: false,
    src: '',
    alt: ''
  });
  const [matrixValues, setMatrixValues] = useState([]);
  const [matrixLastPage, setMatrixLastPage] = useState(false);

  const [getResult, { loading: loadingResult }] = useLazyQuery(GET_RESULT, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      if (response && response.result) {
        let showBlock = null;
        if (response && response.result && response.result.result) {
          let targetResult = null;
          if (response.result.result.blockResults) {
            targetResult = response.result.result.blockResults.filter(
              blockResult => blockResult.block.id === props.match.params.blockId
            );

            setExistingValues(
              response.result.result.blockResults,
              props.match.params.blockId
            );
          }
          if (targetResult && targetResult.length) {
            showBlock = targetResult[0].block;
          } else {
            showBlock = response.result.nextBlock;
          }
          setCampaignComponents(
            response.result.result.survey,
            showBlock,
            response.result.result
          );
        } else {
          setCampaignComponents(null, null, null);
          goToUrl(
            `/campaign/survey/user/${props.match.params.userId}/campaign-failed`
          );
        }
      } else {
        goToCampaignBeginning();
      }
    },
    onError: () => goToCampaignBeginning()
  });

  const [submitMCQuestion, { loading: submitMCQuestionLoading }] = useMutation(
    SUBMIT_MC_QUESTION,
    {
      onCompleted: response => {
        if (response && response.submitMultipleChoiceQuestion) {
          handleSubmitAnswerResponse(response.submitMultipleChoiceQuestion);
        }
      },
      onError: () => goToCampaignBeginning()
    }
  );

  const [
    submitIframeBlock,
    { loading: submitIframeBlockLoading }
  ] = useMutation(SUBMIT_IFRAME_BLOCK, {
    onCompleted: response => {
      if (response && response.submitIFrameBlock) {
        handleSubmitAnswerResponse(response.submitIFrameBlock);
      }
    },
    onError: () => goToCampaignBeginning()
  });

  const [
    submitOpenQuestionBlock,
    { loading: submitOpenQuestionBlockLoading }
  ] = useMutation(SUBMIT_OPEN_QUESTION_BLOCK, {
    onCompleted: response => {
      if (response && response.submitOpenQuestionBlock) {
        handleSubmitAnswerResponse(response.submitOpenQuestionBlock);
      }
    },
    onError: () => goToCampaignBeginning()
  });

  const [
    submitSliderBlock,
    { loading: submitSliderBlockLoading }
  ] = useMutation(SUBMIT_SLIDER_BLOCK, {
    onCompleted: response => {
      if (response && response.submitSliderBlock) {
        handleSubmitAnswerResponse(response.submitSliderBlock);
      }
    },
    onError: () => goToCampaignBeginning()
  });

  const [submitVideoBlock, { loading: submitVideoBlockLoading }] = useMutation(
    SUBMIT_VIDEO_BLOCK,
    {
      onCompleted: response => {
        if (response && response.submitVideoBlock) {
          handleSubmitAnswerResponse(response.submitVideoBlock);
        }
      },
      onError: () => goToCampaignBeginning()
    }
  );

  const [submitMessageBlock, { loading: submitMessageLoading }] = useMutation(
    SUBMIT_MESSAGE_BLOCK,
    {
      onCompleted: response => {
        if (response && response.submitMessageBlock) {
          handleSubmitAnswerResponse(response.submitMessageBlock);
        }
      },
      onError: () => goToCampaignBeginning()
    }
  );

  const [
    submitMatrixBlock,
    { loading: submitMatrixBlockLoading }
  ] = useMutation(SUBMIT_MATRIX_BLOCK, {
    onCompleted: response => {
      if (response && response.submitMatrixBlock) {
        handleSubmitAnswerResponse(response.submitMatrixBlock);
      }
    },
    onError: () => goToCampaignBeginning()
  });

  const setValues = setValue => {
    setValuesChanged(true);
    setQuestionValues(setValue);
  };

  const isSubmitLoading = () =>
    submitIframeBlockLoading ||
    submitOpenQuestionBlockLoading ||
    submitSliderBlockLoading ||
    submitVideoBlockLoading ||
    submitMessageLoading ||
    submitMCQuestionLoading ||
    submitMatrixBlockLoading;

  const conversionEnabled =
    campaign &&
    campaign.communitySettings &&
    campaign.communitySettings.communityType &&
    typeof campaign.communitySettings.seeGiveawayMessage === 'boolean';

  useEffect(() => {
    if (props.match.params.resultId && (!result || !block)) {
      getResult({ variables: { id: props.match.params.resultId } });
      setStartedAt(new Date());
    }
    const query = qs.parse(search);
    if (
      query &&
      query.userEmailSuggestion &&
      query.userEmailSuggestion.length
    ) {
      setUserEmailSuggestion(query.userEmailSuggestion);
    }
  }, []);

  useEffect(
    () => {
      setStartedAt(new Date());

      if (block && block.random) {
        if (block.allowOther === true || block.allowNoneOfTheAbove === true) {
          const blockAnswers = block.answers.map((a, i) => ({
            ...a,
            originalIndex: i
          }));
          let noneOfTheAboveAnswer = null;
          let otherAnswer = null;
          if (block.allowNoneOfTheAbove) {
            noneOfTheAboveAnswer = blockAnswers.pop();
          }
          if (block.allowOther) {
            otherAnswer = blockAnswers.pop();
          }

          const randomAnswers = shuffleArray(
            JSON.parse(JSON.stringify(blockAnswers))
          );
          if (otherAnswer) {
            randomAnswers.push(otherAnswer);
          }
          if (noneOfTheAboveAnswer) {
            randomAnswers.push(noneOfTheAboveAnswer);
          }

          setRandomAnswerValues(randomAnswers);
        } else {
          setRandomAnswerValues(
            shuffleArray(
              JSON.parse(
                JSON.stringify(
                  block.answers.map((a, i) => ({
                    ...a,
                    originalIndex: i
                  }))
                )
              )
            )
          );
        }
      }

      if (
        campaign &&
        ((campaign.hideLogoInWebSurvey && campaign.customBranding) ||
          campaign.adaptConsentForExternalInWebSurvey)
      )
        setHideBufflLogo(true);
      if (customThemeBaseColor) setCustomThemeBaseColor(customThemeBaseColor);
      if (customThemeImage) {
        setCustomThemeImage({
          NAME: customThemeLogoName,
          IMAGE: customThemeLogoImage
        });
      }
    },
    [block]
  );

  useEffect(
    () => {
      document.documentElement.scrollTop = 0;
    },
    [result]
  );

  useEffect(
    () => {
      if (history && history.action === 'POP') {
        onClickBack();
      }
    },
    [history.action]
  );

  const shuffleArray = array => {
    const unlockedIndices = array.reduce(
      (acc, a, i) => (a.lockedPosition ? acc : [...acc, i]),
      []
    );

    return array.reduce((acc, a, i, arr) => {
      if (a.lockedPosition) {
        return [...acc, a];
      }

      const randomIndex =
        unlockedIndices[Math.floor(Math.random() * unlockedIndices.length)];

      unlockedIndices.splice(unlockedIndices.indexOf(randomIndex), 1);

      return [...acc, arr[randomIndex]];
    }, []);
  };

  const goToCampaignBeginning = () => {
    setCampaignComponents(null, null, null);
    if (campaign) {
      goToUrl(`/campaign/${campaign.id}/user/${props.match.params.userId}`);
    }
  };

  const handleSubmitAnswerResponse = response => {
    const { customClientName, customClientIdentifier, userId } = match.params;

    const goToUrl = url => {
      let finalUrl = url;
      if (customClientIdentifier) {
        finalUrl = `${url}/${customClientName}/${customClientIdentifier}`;
      }
      if (isTesting()) {
        finalUrl = `${url}?test=true`;
      }
      if (userEmailSuggestion) {
        finalUrl = `${finalUrl}${
          isTesting() ? '&' : '?'
        }userEmailSuggestion=${userEmailSuggestion}`;
      }

      history.push(finalUrl);
    };

    const resultToStore = response.result;
    setCampaignComponents(campaign, response.nextBlock, resultToStore);

    // Go to campaign failed end page
    if (response && response.failure === true) {
      let campaignEndFailedRedirection =
        campaign &&
        campaign.redirectionsWebSurvey &&
        campaign.redirectionsWebSurvey.campaignEndFailed;

      if (campaignEndFailedRedirection) {
        if (customClientIdentifier) {
          const urlCustomIdentifierSign = campaignEndFailedRedirection.includes(
            '?'
          )
            ? '&'
            : '?';
          campaignEndFailedRedirection = `${campaignEndFailedRedirection}${urlCustomIdentifierSign}m=${customClientIdentifier}`;
        }

        // Custom redirect
        window.location.href = encodeURI(campaignEndFailedRedirection);
        return;
      }

      goToUrl(`/campaign/${campaign.id}/user/${userId}/campaign-failed`);
      return;
    }

    // Go to Next block
    if (response && response.nextBlock && response.nextBlock.id) {
      goToUrl(
        `/result/${result.id}/block/${response.nextBlock.id}/user/${userId}`
      );
      return;
    }

    if (
      response &&
      (response.nextBlock === null || response.nextBlock.id === null) &&
      response.proflingTagsToConfirm &&
      conversionEnabled
    ) {
      goToUrl(`/confirm-profiling-tags/result/${result.id}/user/${userId}`);
    } else if (
      response &&
      (response.nextBlock === null || response.nextBlock.id === null)
    ) {
      if (
        resultToStore.assignUserToCommunity &&
        (!result.user || !result.user.language) &&
        conversionEnabled &&
        !(
          campaign.redirectionsWebSurvey &&
          (campaign.redirectionsWebSurvey.campaignEndFailed ||
            campaign.redirectionsWebSurvey.campaignEndSuccess)
        )
      ) {
        goToUrl(
          `/campaign-email-conversion/result/${result.id}/user/${userId}`
        );
      } else {
        goToUrl(`/result/${result.id}/finish/user/${userId}`);
      }
    }
  };

  const translations =
    localStorage.getItem('campaignLanguage') &&
    strings[localStorage.getItem('campaignLanguage')]
      ? strings[localStorage.getItem('campaignLanguage')]
      : strings.Dutch;

  const isTesting = () => search === '?test=true';

  const goToUrl = url => {
    let finalUrl = url;
    if (isTesting()) {
      finalUrl = `${url}?test=true`;
    }

    props.history.push(finalUrl);
  };

  const answers = block && !block.random ? block.answers : randomAnswerValues;
  const allowOtherIndex =
    block && block.allowOther && answers && answers.length
      ? answers.length - (block.allowNoneOfTheAbove ? 2 : 1)
      : null;

  const customThemeBaseColor =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeColors &&
    campaign.customThemeColors.length &&
    campaign.customThemeColors.find(
      cT => cT.name === CUSTOM_THEME_COLOR_NAMES.BASE
    ).colorCode;

  const customThemeImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length;

  const customThemeLogoName =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).name;

  const customThemeLogoImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).imageFilename;

  let matrixQuestion = null;
  if (
    block &&
    block.type === 'matrix' &&
    block.matrix &&
    block.matrix.xQuestion
  ) {
    if (block.matrix.yQuestion && matrixLastPage) {
      matrixQuestion = block.matrix.yQuestion;
    } else {
      matrixQuestion = block.matrix.xQuestion;
    }
  }

  const onClickNext = autoSubmit => {
    const cleanCampaignState = () => {
      setValid(false);
      setValues([]);
      setValuesChanged(false);
      setSkipQuestion(false);
      setNaOptionSelected(false);
      setMatrixValues([]);
      window.scrollTop = 0;
    };

    const defaultVariables = {
      result: props.match.params.resultId,
      id: autoSubmit && block ? block.id : props.match.params.blockId,
      startedAt: startedAt || new Date(),
      finishedAt: new Date(),
      referrer: props.match.params.userId,
      testing: isTesting()
    };

    if (
      props &&
      props.match &&
      props.match.params &&
      props.match.params.customClientIdentifier
    ) {
      defaultVariables.customClientIdentifier =
        props.match.params.customClientIdentifier;
    }

    const blockExistinigResults = existingValueResult(
      result.blockResults,
      props.match.params.blockId
    );

    const otherAnswer = isOtherFieldVisible(block, values, allowOtherIndex)
      ? values[1]
      : null;

    if ((autoSubmit || valid) && props.match.params.resultId) {
      if (
        ['message', 'iframe', 'video', 'slider'].indexOf(block.type) > -1 &&
        ((!valuesChanged && !blockExistinigResults) ||
          (blockExistinigResults && blockExistinigResults.lastBlockResults) ||
          autoSubmit)
      ) {
        if (block.type === 'message') {
          submitMessageBlock({
            variables: {
              ...defaultVariables
            }
          });
        }
        if (block.type === 'iframe') {
          submitIframeBlock({
            variables: {
              ...defaultVariables
            }
          });
        }
        if (block.type === 'video') {
          submitVideoBlock({
            variables: {
              ...defaultVariables
            }
          });
        }

        if (block.type === 'slider') {
          submitSliderBlock({
            variables: {
              ...defaultVariables,
              answer: skipQuestion ? null : values
            }
          });
        }

        cleanCampaignState();
        return;
      }

      if (
        (valuesChanged &&
          ['message', 'iframe', 'video'].indexOf(block.type) === -1) || // Changed values do not apply for message, iframe and video type questions
        (blockExistinigResults && blockExistinigResults.lastBlockResults)
      ) {
        if (block.type === 'open_question') {
          submitOpenQuestionBlock({
            variables: {
              ...defaultVariables,
              answerString: values
            }
          });
        }
        if (block.type === 'mc_question') {
          const mcQuestionVariables = {
            ...defaultVariables,
            answers: values[0],
            otherAnswer
          };

          submitMCQuestion({
            variables: mcQuestionVariables
          });
        }
        if (block.type === 'slider') {
          submitSliderBlock({
            variables: {
              ...defaultVariables,
              answer: skipQuestion ? null : values
            }
          });
        }

        if (block.type === 'matrix' && block.matrix) {
          if (
            matrixLastPage ||
            block.matrix.decisionFrameworkType ===
              DECISION_FRAMEWORK_TYPES.SINGLE
          ) {
            // Going to next question after changing matrix values
            submitMatrixBlock({
              variables: {
                ...defaultVariables,
                matrixAnswers: matrixValues.map(matrixAnswer => ({
                  questionId: matrixAnswer.questionId,
                  choices: matrixAnswer.choices.map(matrixAnswerChoice => ({
                    choiceId: matrixAnswerChoice.choiceId,
                    answers: matrixAnswerChoice.answers
                  }))
                }))
              }
            });
            setMatrixLastPage(false);
            cleanCampaignState();
          } else {
            // Going to last page after changing matrix values
            setMatrixLastPage(true);
            document.documentElement.scrollTop = 0;
          }
          return;
        }

        cleanCampaignState();
        return;
      }

      if (
        !valuesChanged &&
        block.type === 'matrix' &&
        block.matrix &&
        !matrixLastPage &&
        block.matrix.decisionFrameworkType !== DECISION_FRAMEWORK_TYPES.SINGLE
      ) {
        // Going to last page of Matrix question without changing anything
        setMatrixLastPage(true);
        document.documentElement.scrollTop = 0;
        return;
      }
      if (!valuesChanged && block.type === 'matrix' && matrixLastPage) {
        // Going to next question without changing anything
        setMatrixLastPage(false);
        cleanCampaignState();
      }

      const nextBlockResult =
        result.blockResults[
          blockExistinigResults
            ? blockExistinigResults.existingBlockResultIndex + 1
            : null
        ];

      goToUrl(
        `/result/${result.id}/block/${nextBlockResult.block.id}/user/${
          props.match.params.userId
        }`
      );
      if (result && result.blockResults && result.blockResults.length) {
        setExistingValues(result.blockResults, nextBlockResult.block.id);

        if (nextBlockResult && nextBlockResult.block) {
          setCampaignComponents(campaign, nextBlockResult.block, result);
        }
      }
      window.scrollTop = 0;
    }
  };

  const onClickBack = () => {
    if (block && block.type === 'matrix' && matrixLastPage) {
      // Going back to first page of Matrix question
      setMatrixLastPage(false);
      return;
    }
    if (props.match.params.resultId) {
      if (result && result.blockResults && result.blockResults.length) {
        const blockIds = result.blockResults.map(
          blockResult => blockResult.block.id
        );
        let backBlockId = block && block.id;
        if (!backBlockId) {
          backBlockId = props.match.params.blockId;
        }

        const currentBlockIndex = blockIds.indexOf(backBlockId);
        let showBlockId = null;
        if (currentBlockIndex === -1) {
          showBlockId = blockIds[blockIds.length - 1];
          goToUrl(
            `/result/${props.match.params.resultId}/block/${showBlockId}/user/${
              props.match.params.userId
            }`
          );
        } else if (currentBlockIndex === 0) {
          goToCampaignBeginning();
        } else {
          const targetBlockId = currentBlockIndex - 1;
          showBlockId = blockIds[targetBlockId];
          if (targetBlockId >= 0) {
            goToUrl(
              `/result/${
                props.match.params.resultId
              }/block/${showBlockId}/user/${props.match.params.userId}`
            );
          }
        }

        // showBlockId
        if (result && result.blockResults && result.blockResults.length) {
          const targetResult = result.blockResults.filter(
            blockResult => blockResult.block.id === showBlockId
          );

          setExistingValues(result.blockResults, showBlockId);

          if (targetResult.length) {
            setCampaignComponents(campaign, targetResult[0].block, result);
            // Going back to last page of Matrix question
            if (
              targetResult[0].block &&
              targetResult[0].block.type === 'matrix'
            ) {
              setValuesChanged(false);
              setMatrixLastPage(
                targetResult[0].block.matrix &&
                  targetResult[0].block.matrix.decisionFrameworkType !==
                    DECISION_FRAMEWORK_TYPES.SINGLE
              );
            }
          }
        }
      } else {
        goToCampaignBeginning();
      }
    }
  };

  const showQuestionBlock = type => {
    if (type === 'open_question') {
      return (
        <Input
          campaign={campaign}
          block={block}
          valid={valid}
          setValid={setValid}
          values={values}
          setValues={setValues}
          remainingCharacters={translations.OPEN_QUESTION_REMAINING_CHARACTERS}
          minCharacters={translations.OPEN_QUESTION_MIN_CHARACTERS}
        />
      );
    }

    if (type === 'mc_question') {
      return (
        <Answers
          campaign={campaign}
          block={block}
          setValid={setValid}
          values={values}
          setValues={setValues}
          answers={answers}
          translations={translations}
          customThemeBaseColor={customThemeBaseColor}
          displayRichText={displayBlockQuestion}
          getMediaAltTag={getMediaAltTag}
        />
      );
    }

    if (type === 'slider') {
      return (
        <Slider
          campaign={campaign}
          block={block}
          valid={valid}
          setValid={setValid}
          values={values}
          setValues={setValues}
          skipQuestion={skipQuestion}
          setSkipQuestion={setSkipQuestion}
          naOptionSelected={naOptionSelected}
          setNaOptionSelected={setNaOptionSelected}
          customThemeBaseColor={customThemeBaseColor}
        />
      );
    }

    if (type === 'video') {
      return <VideoPlayer setValid={setValid} block={block} />;
    }

    if (type === 'iframe') {
      enableNextAfterSeconds = block && block.enableNextAfterSeconds;

      // If user already answered, no need to wait
      if (result && result.blockResults && result.blockResults.length) {
        const existingFlowResult = result.blockResults.find(
          fR => block && fR.block && fR.block.id === block.id
        );
        if (existingFlowResult && existingFlowResult.finishedAt) {
          enableNextAfterSeconds = null;
        }
      }

      return (
        <IFrame
          setValid={setValid}
          block={block}
          blockId={block && block.id}
          result={result}
          appendAdditionalDataToIframe={
            block && block.appendAdditionalDataToIframe
          }
        />
      );
    }

    if (type === 'message') {
      return <Message setValid={setValid} block={block} />;
    }

    if (type === 'matrix') {
      return (
        <Matrix
          block={block}
          valid={valid}
          setValid={setValid}
          answers={
            answers &&
            answers.length &&
            block &&
            block.matrix &&
            block.matrix.decisionFrameworkType
              ? answers.filter(
                  answer =>
                    answer.decisionFrameworkType ===
                    block.matrix.decisionFrameworkType
                )
              : []
          }
          translations={translations}
          matrixValues={matrixValues}
          setMatrixValues={setMatrixValues}
          matrixLastPage={matrixLastPage}
          valuesChanged={valuesChanged}
          setValuesChanged={setValuesChanged}
          displayRichText={displayBlockQuestion}
          getMediaAltTag={getMediaAltTag}
          customThemeBaseColor={customThemeBaseColor}
        />
      );
    }

    return null;
  };

  const displayBlockQuestion = (blockQuestion, blockType) => {
    let question;
    try {
      JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      question = blockQuestion;
      return question;
    }
    question = <Draft blockQuestion={blockQuestion} blockType={blockType} />;
    return question;
  };

  const getMediaAltTag = blockQuestion => {
    let question;
    try {
      question = [].concat(
        JSON.parse(blockQuestion)
          .blocks.map(draftBlock => draftBlock.text)
          .join(' ')
      );
    } catch (error) {
      question = blockQuestion;
    }
    return question;
  };

  const existingValueResult = (blockResults, goToBlockId) => {
    if (blockResults && blockResults.length) {
      const existingBlockResult = blockResults.find(
        r => r.block.id === goToBlockId
      );
      const existingBlockResultIndex = blockResults.indexOf(
        existingBlockResult
      );

      if (existingBlockResult && existingBlockResult.block) {
        return {
          result: existingBlockResult,
          existingBlockResultIndex,
          lastBlockResults: blockResults.length - 1 === existingBlockResultIndex
        };
      }
    }
    return null;
  };

  const setExistingValues = (blockResults, goToBlockId) => {
    const existingBlockResult = existingValueResult(blockResults, goToBlockId);
    const blockType =
      existingBlockResult &&
      existingBlockResult.result &&
      existingBlockResult.result.block &&
      existingBlockResult.result.block.type;

    if (blockType) {
      if (
        ['mc_question'].indexOf(blockType) > -1 &&
        existingBlockResult &&
        existingBlockResult.result &&
        existingBlockResult.result.block &&
        existingBlockResult.result.block.selectAtMost === 1
      ) {
        const selectedValue = existingBlockResult.result.answers || [];
        if (
          existingBlockResult.result.block.allowOther &&
          existingBlockResult.result.answerString
        ) {
          selectedValue.push(existingBlockResult.result.answerString);
        }

        setQuestionValues(selectedValue);
      }

      if (
        ['mc_question'].indexOf(blockType) > -1 &&
        existingBlockResult &&
        existingBlockResult.result &&
        existingBlockResult.result.block &&
        existingBlockResult.result.block.selectAtMost > 1
      ) {
        const selectedValue = [existingBlockResult.result.answers];

        if (
          existingBlockResult.result.block.allowOther &&
          existingBlockResult.result.answerString
        ) {
          selectedValue.push(existingBlockResult.result.answerString);
        }

        setQuestionValues(selectedValue);
      }

      if (['open_question'].indexOf(blockType) > -1) {
        const selectedValue = existingBlockResult.result.answerString;
        setQuestionValues(selectedValue);
      }

      if (
        ['slider'].indexOf(blockType) > -1 &&
        existingBlockResult &&
        existingBlockResult.result &&
        existingBlockResult.result.block
      ) {
        const selectedValue = existingBlockResult.result.answer;
        if (
          existingBlockResult.result.block.allowSkip &&
          selectedValue === null
        ) {
          setNaOptionSelected(true);
        }
        setSkipQuestion(
          selectedValue !== null
            ? !Number.isInteger(selectedValue)
            : existingBlockResult.result.block.allowSkip
        );
        setQuestionValues(selectedValue);
      }

      if (['matrix'].indexOf(blockType) > -1) {
        if (
          existingBlockResult &&
          existingBlockResult.result &&
          existingBlockResult.result.block &&
          existingBlockResult.result.matrixAnswers &&
          existingBlockResult.result.matrixAnswers.length
        ) {
          // Set existing matrix values
          setMatrixValues(existingBlockResult.result.matrixAnswers);
        }
      }
    }
  };

  const page = [];

  if (!campaign || !block || loadingResult || isSubmitLoading())
    page.push(<Loader key="loading-popup" />);

  if (campaign && block) {
    page.push(
      <div className={styles.parentContainer} key="page-content">
        <div className={styles.mainContainer}>
          {block.mediaUrl && block.type !== 'video' && (
            <div className={styles.questionImage}>
              {loadingQuestionImageFailed ? (
                <div
                  className={styles.loadingQuestionImageFailed}
                  style={{
                    backgroundColor: customThemeBaseColor
                      ? `${customThemeBaseColor}10`
                      : '#e9f7fc'
                  }}
                >
                  <img
                    src={imageNotFoundIcon}
                    alt="Not found icon"
                    className={styles.imageNotFoundIcon}
                  />
                  <div>{translations.LOADING_IMAGE_FAILED}</div>
                </div>
              ) : (
                <div>
                  <img
                    src={block.mediaUrl}
                    alt={getMediaAltTag(block.question)}
                    onError={() => setLoadingQuestionImageFailed(true)}
                  />
                  <div
                    className={`${styles.zoomInIconContainer} ${
                      styles.visible
                    }`}
                    role="presentation"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDisplayImagePopup({
                        visible: true,
                        src: block.mediaUrl,
                        alt: getMediaAltTag(block.question)
                      });
                    }}
                  >
                    <img
                      src={zoomInIcon}
                      alt="Zoom in"
                      className={styles.zoomInIcon}
                    />
                  </div>
                </div>
              )}
              {displayImagePopup.visible ? (
                <ImagePopup
                  src={displayImagePopup.src}
                  alt={displayImagePopup.alt}
                  onClose={() =>
                    setDisplayImagePopup({
                      visible: false,
                      src: '',
                      alt: ''
                    })
                  }
                />
              ) : null}
            </div>
          )}
          {block.type !== 'message' &&
          block.type !== 'video' &&
          block.type !== 'iframe' ? (
            <div
              className={`${
                styles.campaignQuestion
              } campaign-questions-question`}
            >
              {displayBlockQuestion(
                matrixQuestion || block.question,
                block.type
              )}
            </div>
          ) : null}

          {block && block.type && showQuestionBlock(block.type)}
        </div>
        <div>
          <Footer
            blockId={block && block.id}
            resultId={result && result.id}
            blockType={block && block.type}
            confirmResultWithApi={block && block.confirmResultWithApi}
            onClickNext={onClickNext}
            onClickBack={onClickBack}
            disabledNext={!valid}
            nextLabel={translations.NEXT}
            backLabel={translations.BACK}
            secLabel={translations.SEC}
            customThemeBaseColor={customThemeBaseColor}
            enableNextAfterSeconds={enableNextAfterSeconds}
          />
        </div>
      </div>
    );
  }

  return page;
};
