import React, { useState } from 'react';
import styles from './CommunityAutomationPropertesColumn.module.css';
import DebounceInput from '../../../../../../../../campaigns/components/DebounceInput/DebounceInput';
import {
  COMMUNITY_ACTION_STEP_NAMES,
  COMMUNITY_ACTION_STEP_NAMES_LABELS,
  COMMUNITY_AUTOMATION_TYPES,
  COMMUNITY_EVENT_STEP_NAMES,
  COMMUNITY_EVENT_STEP_NAMES_LABELS
} from '../../../../../../helpers/constants';
import CommunityAutomationPropertesSingleStep from '../CommunityAutomationPropertesSingleStep/CommunityAutomationPropertesSingleStep';

const EVENT_OPTIONS = [
  {
    value: COMMUNITY_EVENT_STEP_NAMES.NOT_OPEN_APP_IN_X_DAYS,
    label: COMMUNITY_EVENT_STEP_NAMES_LABELS.NOT_OPEN_APP_IN_X_DAYS
  },
  {
    value: COMMUNITY_EVENT_STEP_NAMES.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS,
    label: COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_COMPLETED_X_SURVEYS_IN_Y_WEEKS
  },
  {
    value: COMMUNITY_EVENT_STEP_NAMES.USER_CREATED,
    label: COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_CREATED
  },
  {
    value: COMMUNITY_EVENT_STEP_NAMES.USER_RESULT_REWARDED,
    label: COMMUNITY_EVENT_STEP_NAMES_LABELS.USER_RESULT_REWARDED
  }
];

const ACTION_OPTIONS = [
  {
    value: COMMUNITY_ACTION_STEP_NAMES.SEND_PUSH_NOTIFICATION,
    label: COMMUNITY_ACTION_STEP_NAMES_LABELS.SEND_PUSH_NOTIFICATION
  },
  {
    value: COMMUNITY_ACTION_STEP_NAMES.SEND_EMAIL,
    label: COMMUNITY_ACTION_STEP_NAMES_LABELS.SEND_EMAIL
  },
  {
    value: COMMUNITY_ACTION_STEP_NAMES.POPUP_MESSAGE,
    label: COMMUNITY_ACTION_STEP_NAMES_LABELS.POPUP_MESSAGE
  },
  {
    value: COMMUNITY_ACTION_STEP_NAMES.GIVE_TOKENS,
    label: COMMUNITY_ACTION_STEP_NAMES_LABELS.GIVE_TOKENS
  }
];

export default ({
  communityAutomation,
  setCommunityAutomation,
  selectedCommunityAutomationStepIndex
}) => {
  const [search, setSearch] = useState(null);

  const getVisibleOptions = () => {
    let options = [];

    if (selectedStep.type === COMMUNITY_AUTOMATION_TYPES.EVENT) {
      options = EVENT_OPTIONS;
    }
    if (selectedStep.type === COMMUNITY_AUTOMATION_TYPES.ACTION) {
      options = ACTION_OPTIONS;
    }

    if (search && search !== '') {
      options = options.filter(
        o =>
          o && o.label && o.label.toLowerCase().includes(search.toLowerCase())
      );
    }

    return options;
  };

  const onSelectStepName = stepName => {
    const newSteps = communityAutomation.steps;
    newSteps[selectedCommunityAutomationStepIndex].stepName = stepName;

    setCommunityAutomation({
      ...communityAutomation,
      steps: newSteps
    });
  };

  const selectedStep =
    communityAutomation &&
    communityAutomation.steps &&
    communityAutomation.steps[selectedCommunityAutomationStepIndex];

  return (
    <div className={styles.container}>
      {!selectedStep || !selectedStep.stepName
        ? [
            <div
              key="community-automation-column-header"
              className={styles.header}
            >
              <div className={styles.title}>Add event</div>
              <div className={styles.description}>
                Add an event that causes an action
              </div>
              <div className={styles.searchContainer}>
                <DebounceInput
                  className={styles.searchInput}
                  initialValue={search}
                  onChangeValue={searchText => setSearch(searchText)}
                  placeholder="Search event"
                />
              </div>
            </div>,
            <div
              key="community-automation-column-main"
              className={styles.listContainer}
            >
              {selectedStep
                ? getVisibleOptions().map((o, i) => (
                    <div
                      key={`option-${i.toString()}`}
                      className={styles.option}
                      role="presentation"
                      onClick={() => onSelectStepName(o.value)}
                    >
                      {o.label}
                    </div>
                  ))
                : null}
            </div>
          ]
        : null}
      {selectedStep && selectedStep.stepName ? (
        <CommunityAutomationPropertesSingleStep
          selectedStep={selectedStep}
          selectedCommunityAutomationStepIndex={
            selectedCommunityAutomationStepIndex
          }
          communityAutomation={communityAutomation}
          setCommunityAutomation={setCommunityAutomation}
        />
      ) : null}
    </div>
  );
};
