import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import Category from './Category/Category';
import ResultActionPopup from '../ResultActionPopup/ResultActionPopup';
import CategoryPopup from '../../../Question/WordCloudsContainer/WordCloud/CategoryPopup/CategoryPopup';

import addIcon from '../../../../../../../assets/img/add.svg';
import tickIcon from '../../../../../../../assets/img/tick_white.svg';
import flagIcon from '../../../../../../../assets/img/flag.svg';
import trashIcon from '../../../../../../../assets/img/trash.svg';
import heartIcon from '../../../../../../../assets/img/heart-purple.svg';

import styles from './CategoryList.module.css';
import {
  REMOVE_RESULTS,
  REWARD_USER_RESULTS
} from '../../../../../../../graphql/Survey';

const CategoryList = props => {
  const {
    categories,
    onSelectCategoriesCompleted,
    selectedOpenQuestionAnswers,
    onCategoryRemoveFromAnswers,
    onCategoryRenameFromAnswers,
    showCategoriesDropdown,
    setShowCategoriesDropdown,
    block,
    activeFilters,
    onToggleFilter,
    categoryResultsCount,
    answerSearch,
    setAnswerSearch,
    selectAllOpenQuestionCategories,
    setSelectAll,
    campaignId,
    isAdmin
  } = props;

  const [displayFlagPopup, setDisplayFlagPopup] = useState(false);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [displayRewardUserPopup, setDisplayRewardUserPopup] = useState(false);
  const inputRef = useRef();

  const [removeResults, { loading: removeResultsLoading }] = useMutation(
    REMOVE_RESULTS,
    {
      onCompleted: () => window.location.reload()
    }
  );

  const [
    rewardUserResults,
    { loading: rewardUserResultsLoading }
  ] = useMutation(REWARD_USER_RESULTS, {
    onCompleted: () => window.location.reload()
  });

  const removeCategoryFromAnswers = category => {
    /* eslint-disable */
    const confirmDeleteCategory = window.confirm(
      `Delete ${category} category. Are you sure?`
    );
    /* eslint-enable */

    if (confirmDeleteCategory) {
      if (
        activeFilters.find(
          f => f.filterName === `CAT-${block.id}` && f.value === category
        )
      ) {
        onToggleFilter(`CAT-${block.id}`, null, category, null);
      }

      onCategoryRemoveFromAnswers(category);
    }
  };

  const renameCategoryFromAnswers = (category, newLabel) => {
    if (
      activeFilters.find(
        f => f.filterName === `CAT-${block.id}` && f.value === category
      )
    ) {
      onToggleFilter(`CAT-${block.id}`, null, category, null);
    }

    onCategoryRenameFromAnswers(category, newLabel);
  };

  const onRemoveResultsConfirmClick = (
    blockId,
    selectedAnswersResultsIdentifiers,
    flagUser
  ) => {
    removeResults({
      variables: {
        survey: campaignId,
        results: selectedAnswersResultsIdentifiers,
        flagUser: typeof flagUser === 'boolean' ? flagUser : false,
        ...(blockId ? { questionId: blockId } : {})
      }
    });
  };

  const onRewardUserConfirmClick = (
    blockId,
    selectedAnswersResultsIdentifiers
  ) => {
    rewardUserResults({
      variables: {
        survey: campaignId,
        results: selectedAnswersResultsIdentifiers
      }
    });
  };

  const isAllowedToClickOnRewardUserButton = () => {
    if (!selectedOpenQuestionAnswers || !selectedOpenQuestionAnswers.length) {
      return false;
    }

    const alreadyRewardedResults = selectedOpenQuestionAnswers.some(
      a => a.rewardDetails && a.rewardDetails.rewardType
    );
    if (alreadyRewardedResults) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <div className={styles.searchCategoryContainer}>
        <div className={styles.searchAnswerInputContainer}>
          <input
            className={styles.searchAnswerInput}
            type="text"
            placeholder="Search answers"
            value={answerSearch}
            onChange={e => setAnswerSearch(e.target.value)}
            ref={inputRef}
          />
          {answerSearch && answerSearch.length && (
            <span
              className={styles.closeIcon}
              role="presentation"
              onClick={() => {
                setAnswerSearch('');
                if (inputRef && inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            >
              t
            </span>
          )}
        </div>
        <div
          className={`${styles.actionButton} ${
            selectedOpenQuestionAnswers &&
            selectedOpenQuestionAnswers.length > 0
              ? ''
              : styles.disabledButton
          }
          `}
          onClick={() => {
            if (
              selectedOpenQuestionAnswers &&
              selectedOpenQuestionAnswers.length > 0
            ) {
              setShowCategoriesDropdown(!showCategoriesDropdown);
            }
          }}
          role="presentation"
          title={
            selectedOpenQuestionAnswers &&
            selectedOpenQuestionAnswers.length > 0
              ? ''
              : 'Select some answers first'
          }
        >
          <img src={addIcon} className={styles.addIcon} alt="Category" />
          <span className={styles.buttonText}>Add to category</span>
        </div>
        {isAdmin && (
          <div
            className={`${styles.actionButton} ${
              isAllowedToClickOnRewardUserButton() ? '' : styles.disabledButton
            }`}
            role="presentation"
            onClick={() => {
              if (isAllowedToClickOnRewardUserButton()) {
                setDisplayRewardUserPopup(true);
              }
            }}
          >
            <img src={heartIcon} className={styles.flagIcon} alt="Heart" />
            <span className={styles.buttonText}>Reward user</span>
          </div>
        )}
        {isAdmin && (
          <div
            className={`${styles.actionButton} ${
              selectedOpenQuestionAnswers &&
              selectedOpenQuestionAnswers.length > 0
                ? ''
                : styles.disabledButton
            }`}
            role="presentation"
            onClick={() => {
              if (
                selectedOpenQuestionAnswers &&
                selectedOpenQuestionAnswers.length
              ) {
                setDisplayDeletePopup(true);
              }
            }}
          >
            <img src={trashIcon} className={styles.trashIcon} alt="Delete" />
            <span className={styles.buttonText}>Delete answer</span>
          </div>
        )}
        {isAdmin && (
          <div
            className={`${styles.actionButton} ${
              selectedOpenQuestionAnswers &&
              selectedOpenQuestionAnswers.length > 0
                ? ''
                : styles.disabledButton
            }`}
            role="presentation"
            onClick={() => {
              if (
                selectedOpenQuestionAnswers &&
                selectedOpenQuestionAnswers.length
              ) {
                setDisplayFlagPopup(true);
              }
            }}
          >
            <img src={flagIcon} className={styles.flagIcon} alt="Flag" />
            <span className={styles.buttonText}>Flag answer</span>
          </div>
        )}
        {categories && categories.length
          ? categories.map((category, index) => [
              <Category
                block={block}
                category={category}
                index={index}
                removeCategoryFromAnswers={removeCategoryFromAnswers}
                renameCategoryFromAnswers={renameCategoryFromAnswers}
                categoryResultsCount={categoryResultsCount}
                onToggleFilter={onToggleFilter}
                key={`${category}-${index.toString()}`}
              />
            ])
          : null}
      </div>
      <div className={styles.addCategoryRowContainer}>
        <div className={styles.addCategoryRow}>
          <div className={styles.selectAllContainer} role="presentation">
            <div
              role="presentation"
              className={`${styles.visibleBox} ${
                selectAllOpenQuestionCategories ? styles.active : null
              }`}
              onClick={() => {
                setSelectAll(!selectAllOpenQuestionCategories);
              }}
            >
              <img src={tickIcon} className={styles.visibleTick} alt="Tick" />
            </div>
            <span className={styles.selectAllText}>Select all</span>
          </div>
          {showCategoriesDropdown ? (
            <CategoryPopup
              onClose={() => setShowCategoriesDropdown(false)}
              openQuestionCategories={categories}
              onSelectCategoriesCompleted={onSelectCategoriesCompleted}
              onComplete={selectedCategories => {
                onSelectCategoriesCompleted(selectedCategories);
              }}
            />
          ) : null}
        </div>
      </div>
      {isAdmin && displayFlagPopup && (
        <ResultActionPopup
          answers={selectedOpenQuestionAnswers}
          onClose={() => setDisplayFlagPopup(false)}
          campaignId={campaignId}
          title="Flag"
          description="Flagged answers will disappear from the overview. Respondents will not
          get notified."
          blockId={block && block.id ? block.id : null}
          loading={removeResultsLoading}
          onClickConfirm={(blockId, selectedAnswersResultsIdentifiers) =>
            onRemoveResultsConfirmClick(
              blockId,
              selectedAnswersResultsIdentifiers,
              true
            )
          }
          isAllowedToConfirmClick={selectedAnswers =>
            selectedAnswers && selectedAnswers.length
          }
        />
      )}
      {isAdmin && displayDeletePopup && (
        <ResultActionPopup
          answers={selectedOpenQuestionAnswers}
          onClose={() => setDisplayDeletePopup(false)}
          campaignId={campaignId}
          title="Delete"
          description="Deleted answers will disappear from the overview. Respondents will not be punished."
          flagUser={false}
          blockId={block && block.id ? block.id : null}
          loading={removeResultsLoading}
          onClickConfirm={(blockId, selectedAnswersResultsIdentifiers) =>
            onRemoveResultsConfirmClick(
              blockId,
              selectedAnswersResultsIdentifiers
            )
          }
          isAllowedToConfirmClick={selectedAnswers =>
            selectedAnswers && selectedAnswers.length
          }
        />
      )}
      {isAdmin && displayRewardUserPopup && (
        <ResultActionPopup
          answers={selectedOpenQuestionAnswers}
          onClose={() => setDisplayRewardUserPopup(false)}
          campaignId={campaignId}
          title="Reward user"
          description="The user will receive an in-app pop-up message acknowledging their valuable contribution.

          Selected open answers:"
          blockId={block && block.id ? block.id : null}
          loading={rewardUserResultsLoading}
          onClickConfirm={(blockId, selectedAnswersResultsIdentifiers) =>
            onRewardUserConfirmClick(blockId, selectedAnswersResultsIdentifiers)
          }
          isAllowedToConfirmClick={selectedAnswers =>
            selectedAnswers && selectedAnswers.length
          }
        />
      )}
    </div>
  );
};

export default CategoryList;
